import React from 'react'
import { useActiveWeb3React } from '../../hooks'

import Modal from '../Modal'
// import { ConfirmationPendingContent, TransactionSubmittedContent } from '../TransactionConfirmationModal'

type MobilePoolDetailsModalProps = {
  // hash: string | undefined
  isOpen: boolean
  // pendingText: string
  // attemptingTxn: boolean
  overlayColor?: string
  content: () => React.ReactNode
  onDismiss: () => void
}

const MobilePoolDetailsModal = ({
  // hash,
  isOpen,
  // pendingText,
  // attemptingTxn,
  overlayColor,
  content,
  onDismiss
}: MobilePoolDetailsModalProps) => {
  const { chainId } = useActiveWeb3React()

  return (
    <>
      {chainId ? (
        <Modal isOpen={isOpen} overlayColor={overlayColor} onDismiss={onDismiss} maxHeight={90}>
          {content()}
        </Modal>
      ) : null}
    </>
  )
}

export default MobilePoolDetailsModal
