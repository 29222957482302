// we use sushi's token list as the basis for the list config below. It can be seen at
// https://github.com/automata-network/sushiswap-interface/blob/feature/conveyor_v2/src/config/token-lists.ts

const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'

// used to mark unsupported tokens, these are hosted lists of unsupported tokens
export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

const YEARN_LIST = 'https://yearn.science/static/tokenlist.json'
const NFTX_LIST = 'https://nftx.ethereumdb.com/v2/tokenlist/'
const SYNTHETIX_LIST = 'synths.snx.eth'
const OPYN_LIST = 'https://raw.githubusercontent.com/opynfinance/opyn-tokenlist/master/opyn-v1.tokenlist.json'
const AAVE_LIST = 'tokenlist.aave.eth'
const CMC_ALL_LIST = 'defi.cmc.eth'
const CMC_STABLECOIN = 'stablecoin.cmc.eth'
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
const KLEROS_LIST = 't2crtokens.eth'
const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
const WRAPPED_LIST = 'wrapped.tokensoft.eth'
const DHEDGE_LIST = 'https://list.dhedge.eth.link/'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  COMPOUND_LIST,
  AAVE_LIST,
  CMC_ALL_LIST,
  CMC_STABLECOIN,
  YEARN_LIST,
  SYNTHETIX_LIST,
  WRAPPED_LIST,
  COINGECKO_LIST,
  KLEROS_LIST,
  OPYN_LIST,
  NFTX_LIST,
  OPTIMISM_LIST,
  GEMINI_LIST,
  DHEDGE_LIST,
  ...UNSUPPORTED_LIST_URLS // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [COMPOUND_LIST, GEMINI_LIST, DHEDGE_LIST]
