export { default as Search } from './Search'
export { default as PositionCard } from './PositionCard'
export { default as DropdownSelect } from './DropdownSelect'
export { default as StakeTransactionModal } from './StakeTransactionModal'

export enum RewardType {
  SINGLE,
  MULTI
}

export enum FarmStatus {
  ACTIVE,
  INACTIVE
}

export enum SortBy {
  TOTAL,
  TIME,
  APR,
  EARNED
}

export enum TxAction {
  STAKE,
  UNSTAKE,
  HARVEST,
  EXIT
}

export const REWARD_TYPE_ITEMS_LABELS = {
  [RewardType.SINGLE]: 'Single Reward',
  [RewardType.MULTI]: 'Multi Rewards'
}

export const FARM_STATUS_ITEMS_LABELS = {
  [FarmStatus.ACTIVE]: 'Active',
  [FarmStatus.INACTIVE]: 'Inactive'
}

export const SORT_BY_ITEMS_LABELS = {
  [SortBy.TOTAL]: 'Total in Farm',
  [SortBy.TIME]: 'Time Remaining',
  [SortBy.APR]: 'APR',
  [SortBy.EARNED]: 'Earned'
}
