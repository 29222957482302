import { createReducer } from '@reduxjs/toolkit'
import { updateTypedAmount } from './actions'

type StakeState = {
  typedAmount: string
}

const initialState: StakeState = {
  typedAmount: ''
}

export default createReducer(initialState, builder => {
  builder.addCase(updateTypedAmount, (state, { payload }) => {
    state.typedAmount = payload
  })
})
