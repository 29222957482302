import { Token } from '@xatadev/sdk'
import React from 'react'
import styled from 'styled-components'

import { useCurrency } from '../../hooks/Tokens'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { RowFixed } from '../Row'

type Props = {
  tokens: Token[]
  size?: number
}

const DoubleLogo = ({ tokens, size = 32 }: Props) => {
  const [tokenA, tokenB] = tokens
  const [currencyA, currencyB] = [
    useCurrency(tokenA?.address) as WrappedTokenInfo,
    useCurrency(tokenB?.address) as WrappedTokenInfo
  ]

  return (
    <StyledDoubleLogo $size={size} $tokensLength={tokens.length}>
      <img
        className="tokenLogo"
        src={currencyA?.logoURI}
        alt={`${tokenA?.name} (${tokenA?.symbol}) logo`}
      />
      {currencyB && (
        <img
          className="tokenLogo"
          src={currencyB?.logoURI}
          alt={`${tokenB?.name} (${tokenB?.symbol}) logo`}
        />
      )}
    </StyledDoubleLogo>
  )
}

const StyledDoubleLogo = styled(RowFixed)<{
  $size: number
  $tokensLength: number
}>`
  margin-right: 1rem;
  flex: 0 0 auto;
  padding-left: ${({ $tokensLength, $size }) => ($tokensLength > 1 ? 0 : `${$size}px`)};

  .tokenLogo {
    width: ${({ $size }) => `${$size}px`};
    height: ${({ $size }) => `${$size}px`};
    border-radius: 16px;
    border: solid 1px ${({ theme }) => theme.farms.border2};
    overflow: hidden;
    background-color: ${({ theme }) => theme.farms.primer};

    &:last-child {
      margin-left: -6px;
    }
  }
`

export default DoubleLogo
