import { useCallback } from 'react'
import { updateTypedAmount } from './actions'
import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'

export const useStakeTypedValue = (): [string, (typedAmount: string) => void] => {
  const dispatch = useDispatch<AppDispatch>()

  const typedAmount = useSelector<AppState, AppState['stake']['typedAmount']>(state => state.stake.typedAmount)

  const onFieldInput = useCallback(
    (typedAmount: string) => {
      dispatch(updateTypedAmount(typedAmount))
    },
    [dispatch]
  )

  return [typedAmount, onFieldInput]
}
