import { createReducer } from '@reduxjs/toolkit'
import { updateSearchQuery, updateFarmStatusOption, updateSortByOption } from './actions'
import { FARMS, FarmsDataMap, SerializedFarm } from '../../constants/farms'
import { ChainId } from '@xatadev/sdk'
import { FarmStatus, SortBy } from 'components/farms'

type FarmsState = {
  pools: FarmsDataMap
  searchQuery: string
  farmStatusOption: FarmStatus
  sortByOption: SortBy
}

const getInitialPools = () => {
  const chainIds = Object.keys(ChainId)
    .map(chain => parseInt(chain))
    .filter(chain => !isNaN(chain))

  let pools: FarmsDataMap = {}
  for (const chainId of chainIds) {
    const farms: SerializedFarm[] | undefined = (FARMS as any)[chainId]
    if (!farms) continue

    pools = {
      ...pools,
      [chainId]: farms.map((farm: SerializedFarm) => ({ ...farm }))
    }
  }

  return pools
}

const initialState: FarmsState = {
  pools: getInitialPools(),
  searchQuery: '',
  farmStatusOption: FarmStatus.ACTIVE,
  sortByOption: SortBy.TOTAL
}

export default createReducer(initialState, builder => {
  builder
    .addCase(updateSearchQuery, (state, { payload }) => {
      state.searchQuery = payload
    })
    .addCase(updateFarmStatusOption, (state, { payload }) => {
      state.farmStatusOption = payload
    })
    .addCase(updateSortByOption, (state, { payload }) => {
      state.sortByOption = payload
    })
})
