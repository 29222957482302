import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const NETWORK_PROVIDER = process.env.REACT_APP_NETWORK_PROVIDER
const PROVIDER_PROJECT_ID = process.env.REACT_APP_PROVIDER_PROJECT_ID

if (!PROVIDER_PROJECT_ID || typeof PROVIDER_PROJECT_ID !== 'string' || PROVIDER_PROJECT_ID === '') {
  throw new Error(
    'REACT_APP_PROVIDER_PROJECT_ID must be a defined environment variable and cannot be empty'
  )
}

const PROVIDER_PROJECT_SECRET = process.env.REACT_APP_PROVIDER_PROJECT_SECRET || ''
const PROVIDER_NETWORK_ID = process.env.REACT_APP_PROVIDER_NETWORK_ID || 'mainnet'

let networkUrl: string

switch (NETWORK_PROVIDER) {
  case 'infura':
  default:
    networkUrl = `https://${PROVIDER_PROJECT_SECRET &&
      `:${PROVIDER_PROJECT_SECRET}@`}${PROVIDER_NETWORK_ID}.infura.io/v3/${PROVIDER_PROJECT_ID}`
    break
}

export const NETWORK_CHAIN_ID: number = parseInt(CHAIN_ID ?? '1')

if (typeof networkUrl === 'undefined') {
  throw new Error('REACT_APP_NETWORK_URL must be a defined environment variable')
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: networkUrl }
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

// Support BSC Testnet only in staging
export const injected = new InjectedConnector({
  supportedChainIds:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? [56, 137, 42161, 1285]
      : [1, 56, 97, 137, 1285]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  bridge: 'https://bridge.walletconnect.org',
  // pollingInterval: 15000
  qrcode: true,
  rpc: { 1: networkUrl }
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  appLogoUrl: 'https://i.postimg.cc/RVRPM9sG/XATA.png',
  appName: 'XATA',
  url: networkUrl
})
