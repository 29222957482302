import React from 'react'
import styled from 'styled-components'

import { AlertCircle, X } from 'react-feather'
import { useURLWarningToggle, useURLWarningVisible } from '../../state/user/hooks'
// import { isMobile } from 'react-device-detect'
// import { useActiveWeb3React } from '../../hooks'
// import { /*ChainId,*/ Environment } from '@xatadev/sdk'
// import useEnvironment from 'hooks/useEnvironment'

const PhishAlert = styled.div<{ isActive: any }>`
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
  color: #e6a23c;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  position: relative;
  z-index: 3;
  padding-top: 9px;
  padding-bottom: 10px;
  padding-right: 36px;
  padding-left: 1rem;
  box-shadow: 0px 2px 4px rgba(83, 83, 83, 0.2);
`

export const StyledClose = styled(X)`
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -6px;

  :hover {
    cursor: pointer;
  }
`

export default function URLWarning() {
  const toggleURLWarning = useURLWarningToggle()
  const urlWarningVisible = useURLWarningVisible()
  // const showURLWarning = useURLWarningVisible()
  // const { chainId } = useActiveWeb3React()
  // const environment = useEnvironment()
  const warningMessage = 'Welcome to XATA (Alpha Release). Please use it at your own risk.'

  return (
    <>
      {/* {environment !== Environment.PRODUCTION ? (
        isMobile ? (
          <PhishAlert isActive={true}>
            <AlertCircle style={{ marginRight: 6 }} size={12} />
            {warningMessage}
            <StyledClose size={12} onClick={toggleURLWarning} />
          </PhishAlert>
        ) : (
          <PhishAlert isActive={true}>
            <AlertCircle style={{ marginRight: 6 }} size={12} />
            {warningMessage}
            <StyledClose size={12} onClick={toggleURLWarning} />
          </PhishAlert>
        )
      ) : null} */}

      {urlWarningVisible ? (
        <PhishAlert isActive={true}>
          <AlertCircle style={{ marginRight: 6 }} size={12} />
          {warningMessage}
          <StyledClose size={12} onClick={toggleURLWarning} />
        </PhishAlert>
      ) : null}
    </>
  )
}
