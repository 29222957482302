// import { BigNumber } from '@ethersproject/bignumber'
// import { Contract } from '@ethersproject/contracts'
// import { getContract } from '../utils'
// import { calculateFeeOnToken, GAS_LIMIT } from '../constants/price/price'
// import { findOriginalTokenAddress } from '../state/user/hooks'
import { Interface } from '@ethersproject/abi'
// import { useIsExpertMode } from 'state/user/hooks'
// import { Log } from '@ethersproject/abstract-provider/src.ts/index'
// import { GTOKEN_MAP } from '../constants/gtokens/gtokens'
import { TransactionResponse } from '@ethersproject/providers'
import {
  Currency,
  // Fraction,
  // JSBI,
  Percent,
  // ROUTER_ADDRESS,
  Token,
  // JSBI,
  // Percent,
  // Router,
  // SwapParameters,
  Trade,
  TradeType,
  Xata
  // CurrencyAmount
  /*, Pair, ChainId*/
} from '@xatadev/sdk'
import { BigNumber as JSBigNumber } from 'bignumber.js'
// import { Version } from './useToggledVersion'
// import { isAddress, splitSignature } from 'ethers/lib/utils'
// import { splitSignature } from 'ethers/lib/utils'
// import { RELAYER_ENDPOINT_MAP } from '../constants/relayer'
// import CONTROLLER_ABI from '../constants/abis/Controller.json'
import { BigNumber, utils } from 'ethers'
import { useMemo } from 'react'
import { WrappedTokenInfo } from 'state/lists/hooks'
import {
  useIsExpertMode,
  useUserFeeTokens,
  useUserSwapGasLimit,
  useUserUseCustomFeeToken
} from 'state/user/hooks'
import { toRawAmount } from 'utils/toRawAmount'

import { /*BIPS_BASE, GTOKEN_CONTROLLER_MAP,*/ INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { Field } from '../state/swap/actions'
// import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { /*useSimpleTransactionAdder,*/ useTransactionAdder } from '../state/transactions/hooks'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
// import { calculateGasMargin, getRouterContract, isAddress, shortenAddress } from '../utils'
// import { getRouterContract, shortenAddress } from '../utils'
// import { getRouterContract } from '../utils'
// import isZero from '../utils/isZero'
// import v1SwapArguments from '../utils/v1SwapArguments'
import { useActiveWeb3React } from './index'
import { useCurrency } from './Tokens'
import { useConveyorV2RouterContract /*, useV1ExchangeContract*/ } from './useContract'
import useENS from './useENS'
// import {
//   EIP712_DOMAIN_TYPE,
//   FORWARDER_TYPE
//   /*CONVEYOR_V2_ROUTER_ABI, CONVEYOR_V2_ROUTER_ADDRESS*/
// } from 'constants/abis/conveyor-v2'
import useEnvironment from './useEnvironment'
import useTransactionDeadline from './useTransactionDeadline'

// const { /*keccak256, defaultAbiCoder, toUtf8Bytes, solidityPack, Interface: _Interface, */ formatUnits } = utils
const { formatUnits } = utils

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}

// interface SwapCall {
//   contract: Contract
//   parameters: SwapParameters
// }

// interface SuccessfulCall {
//   call: SwapCall
//   gasEstimate: BigNumber
// }

// interface FailedCall {
//   call: SwapCall
//   error: Error
// }

// type EstimatedSwapCall = SuccessfulCall | FailedCall

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddressOrName
 */
/*
function useSwapCallArguments(
  trade: Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): SwapCall[] {
  const { account, chainId, library } = useActiveWeb3React()

  // const environment = useEnvironment()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress
  const deadline = useTransactionDeadline()

  const v1Exchange = useV1ExchangeContract(useV1TradeExchangeAddress(trade), true)
  const routerContract = useConveyorV2RouterContract()

  return useMemo(() => {
    const tradeVersion = getTradeVersion(trade)
    if (!trade || !recipient || !library || !account || !tradeVersion || !chainId || !deadline) return []

    // const contract: Contract | null =
    //   tradeVersion === Version.v2 ? getRouterContract(chainId, environment, library, account) : v1Exchange
    const contract: Contract | null = tradeVersion === Version.v2 ? routerContract : v1Exchange
    if (!contract) {
      return []
    }

    const swapMethods = []

    switch (tradeVersion) {
      case Version.v2:
        swapMethods.push(
          Router.swapCallParameters(trade, {
            feeOnTransfer: false,
            allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline: deadline.toNumber()
          })
        )

        if (trade.tradeType === TradeType.EXACT_INPUT) {
          swapMethods.push(
            Router.swapCallParameters(trade, {
              feeOnTransfer: true,
              allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
              recipient,
              deadline: deadline.toNumber()
            })
          )
        }
        break
      case Version.v1:
        swapMethods.push(
          v1SwapArguments(trade, {
            allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline: deadline.toNumber()
          })
        )
        break
    }
    return swapMethods.map(parameters => ({ parameters, contract }))
  }, [account, allowedSlippage, chainId, deadline, library, recipient, trade, v1Exchange, routerContract])
}
*/

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
/*
export function useSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): {
  state: SwapCallbackState
  callback: null | (() => Promise<{ txHash: string; preventedLoss: string | undefined }>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()

  const environment = useEnvironment()

  const swapCalls = useSwapCallArguments(trade, allowedSlippage, recipientAddressOrName)

  const addTransaction = useSimpleTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }

    // const tradeVersion = getTradeVersion(trade)

    return {
      state: SwapCallbackState.VALID,
      // return a transaction hash or return the error
      callback: async (): Promise<{ txHash: string; preventedLoss: string | undefined }> => {
        console.table(swapCalls)

        if (!swapCalls || swapCalls.length === 0) {
          throw new Error('Cannot create swap call')
        }

        const {
          parameters: { methodName, args }
        } = swapCalls[0]

        console.log(`Swapping: methodName is ${methodName}`)
        const sender = await library.getSigner().getAddress()

        // https: github.com/Uniswap/uniswap-sdk/blob/a88048e9c4198a5bdaea00883ca00c8c8e582605/src/router.ts#L114
        const [amount0, amount1, path, user, deadline] = args
        if (user !== sender) {
          throw new Error('Wrong sender')
        }

        const controller = getContract(ROUTER_ADDRESS[environment][chainId!], CONVEYOR_V2_ROUTER_ABI, library, account)
        // for (var i = 0; i < path.length - 1; i++) {
        //   const tokenA = new Token(chainId, path[i], trade.route.path[i].decimals)
        //   const tokenB = new Token(chainId, path[i + 1], trade.route.path[i + 1].decimals)
        //   const pairAddress = Pair.getAddress(tokenA, tokenB)
        //   var isTrustedPair: boolean = false
        //   if (chainId === ChainId.BSCMAIN || chainId === ChainId.BSCTEST) {
        //     isTrustedPair = await controller.isTrustedPancakeV2Pair(pairAddress)
        //   } else {
        //     isTrustedPair = await controller.isTrustedPair(pairAddress)
        //   }
        //   if (!isTrustedPair) {
        //     throw new Error("Not trusted pair!")
        //   }
        // }

        const nonce: BigNumber = await controller.nonces(sender)
        // (`deadline ${deadline}`)

        // if (path.length !== 2) {
        //   throw new Error('Does not support multi-hop swap yet')
        // }

        const gasPrice = await library?.getGasPrice()
        const gasLimit = SWAP_GAS_LIMIT + (path.length - 2) * HOP_ADDITIONAL_GAS
        // const feeOnTokenA = await calculateFeeOnToken(chainId, findOriginalTokenAddress(chainId!,path[0]), trade.inputAmount.currency.decimals, gasPrice === undefined ? undefined: gasPrice.mul(gasLimit))
        const feeOnTokenA = await calculateFeeOnToken(
          chainId,
          path[0],
          trade.inputAmount.currency.decimals,
          gasPrice === undefined ? undefined : gasPrice.mul(gasLimit)
        )

        if (methodName !== 'swapExactTokensForTokens') {
          if (methodName === 'swapTokensForExactTokens') {
            throw new Error('Does not support setting output amount')
          } else {
            throw new Error('Can only between ERC-20 tokens')
          }
        }

        const EIP712Domain = [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' }
        ]

        const Swap = [
          { name: 'amount0', type: 'uint256' },
          { name: 'amount1', type: 'uint256' },
          { name: 'path', type: 'address[]' },
          { name: 'user', type: 'address' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
          { name: 'feeAmount', type: 'uint256' },
          { name: 'feeToken', type: 'address' }
        ]

        const domain = {
          name: 'ConveyorV2',
          version: '1',
          chainId: BigNumber.from(chainId).toHexString(),
          // verifyingContract: GTOKEN_CONTROLLER_MAP[chainId]
          verifyingContract: ROUTER_ADDRESS[environment][chainId!]
        }
        console.log(BigNumber.from(chainId).toHexString())

        const message = {
          amount0,
          amount1,
          path: path,
          user: sender,
          nonce: nonce.toString(),
          deadline,
          feeAmount: BigNumber.from(feeOnTokenA.toFixed(0)).toHexString(),
          feeToken: path[0]
        }
        console.log('trade: ', trade)
        console.log(message)

        const EIP712Msg = {
          types: {
            EIP712Domain,
            Swap
          },
          domain,
          primaryType: 'Swap',
          message
        }

        const data = JSON.stringify(EIP712Msg)

        const signature = await library.send('eth_signTypedData_v4', [sender, data])
        const { v, r, s } = splitSignature(signature)

        const params = [chainId, EIP712Msg, v.toString(), r, s]

        console.log(params)

        const jsonrpcRequest = {
          jsonrpc: '2.0',
          method: '/v2/' + methodName,
          id: 1,
          params
        }

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonrpcRequest)
        }
        console.log(jsonrpcRequest)

        const response = await fetch(RELAYER_ENDPOINT_MAP[environment][chainId]!, requestOptions)

        // const inputSymbol = trade.inputAmount.currency.symbol
        // const outputSymbol = trade.outputAmount.currency.symbol
        // const inputAmount = trade.inputAmount.toSignificant(3)
        // const outputAmount = trade.outputAmount.toSignificant(3)

        // const base = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
        // const withRecipient =
        //   recipient === account
        //     ? base
        //     : `${base} to ${
        //         recipientAddressOrName && isAddress(recipientAddressOrName)
        //           ? shortenAddress(recipientAddressOrName)
        //           : recipientAddressOrName
        //       }`

        // const withVersion =
        //   tradeVersion === Version.v2 ? withRecipient : `${withRecipient} on ${(tradeVersion as any).toUpperCase()}`
        const { result } = await response.json()

        // We don't use gToken anymore
        // const originalInputTokenSymbol = trade.inputAmount.currency.symbol?.startsWith('g')
        //   ? trade.inputAmount.currency.symbol.substring(1)
        //   : trade.inputAmount.currency.symbol
        // const originalOutputTokenSymbol = trade.outputAmount.currency.symbol?.startsWith('g')
        //   ? trade.outputAmount.currency.symbol.substring(1)
        //   : trade.outputAmount.currency.symbol
        const originalInputTokenSymbol = trade.inputAmount.currency.symbol
        const originalOutputTokenSymbol = trade.outputAmount.currency.symbol

        if (result.success === true) {
          addTransaction(result.txnHash as string, {
            summary:
              'Swap ' +
              trade.inputAmount.toSignificant(3) +
              ' ' +
              originalInputTokenSymbol +
              ' to ' +
              originalOutputTokenSymbol
          })
          let receipt = null
          while (receipt === null) {
            receipt = await library.getTransactionReceipt(result.txnHash)
          }
          const transactionLogs = receipt.logs
          let savedLoss: JSBigNumber | undefined = undefined
          let lastUsedLogIndex = -1
          for (const log of transactionLogs) {
            //if this trade is a multihop trade, we should use the last SWAP event data
            if (
              log.topics[0] === '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822' &&
              log.logIndex > lastUsedLogIndex
            ) {
              const iface = new Interface([
                'event Swap(address indexed sender,uint amount0In,uint amount1In,uint amount0Out,uint amount1Out,address indexed to)'
              ])
              const logDescription = iface.parseLog(log)
              const amount1Out: JSBigNumber = new JSBigNumber(logDescription.args.amount1Out.toString())
              const amount0Out: JSBigNumber = new JSBigNumber(logDescription.args.amount0Out.toString())
              const amountOut = amount1Out.eq(0) ? amount0Out : amount1Out
              const minAmountOut: JSBigNumber = new JSBigNumber(amount1 as string, 16)
              savedLoss = amountOut.minus(minAmountOut)
              lastUsedLogIndex = log.logIndex
            }
          }
          let preventedLoss: string | undefined = undefined
          if (savedLoss !== undefined) {
            preventedLoss =
              savedLoss.div(new JSBigNumber(10).pow(trade.outputAmount.currency.decimals)).toPrecision(6) +
              ' ' +
              originalOutputTokenSymbol
          }
          return { txHash: result.txnHash, preventedLoss: preventedLoss }
        } else {
          throw new Error(result.errorMessage)
        }
      },
      error: null
    }
  }, [trade, library, account, chainId, recipient, recipientAddressOrName, swapCalls, addTransaction, environment])
  // }, [trade, library, account, chainId, recipient, recipientAddressOrName, swapCalls, addTransaction])
}
*/
export function useSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  // signatureData: SignatureData | undefined | null,
  // archerRelayDeadline?: number // deadline to use for archer relay -- set to undefined for no relay
): {
  state: SwapCallbackState
  callback: null | (() => Promise<{ txnHash: string; preventedLoss: string | undefined }>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()

  // const blockNumber = useBlockNumber()

  // const eip1559 = EIP_1559_ACTIVATION_BLOCK[chainId] == undefined ? false : blockNumber >= EIP_1559_ACTIVATION_BLOCK[chainId]

  // const useArcher = archerRelayDeadline !== undefined

  // const swapCalls = useSwapCallArguments(trade, allowedSlippage, recipientAddressOrName, signatureData, useArcher)

  // console.log({ swapCalls, trade })

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  // const [archerETHTip] = useUserArcherETHTip()

  // const [userConveyorUseRelay] = useUserConveyorUseRelay()

  const conveyorRouter = useConveyorV2RouterContract()

  const transactionDeadline = useTransactionDeadline()

  // const [userMaxTokenAmount] = useUserMaxTokenAmount()

  const isExpertMode = useIsExpertMode()
  const [userSwapGasLimit] = useUserSwapGasLimit()

  const environment = useEnvironment()
  // console.log('environment', environment)

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  )

  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [userFeeTokens] = useUserFeeTokens()
  const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return {
        state: SwapCallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies'
      }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return {
          state: SwapCallbackState.INVALID,
          callback: null,
          error: 'Invalid recipient'
        }
      } else {
        return {
          state: SwapCallbackState.LOADING,
          callback: null,
          error: null
        }
      }
    }

    const callback = async (): Promise<{ txnHash: string; preventedLoss: string | undefined }> => {
      const user = await conveyorRouter?.signer.getAddress()
      if (user !== account) {
        throw new Error('Wrong sender')
      }

      // const nonce: BigNumber = await conveyorRouter?.nonces(user)

      // TODO Make Conveyor to support swapTokensForExactTokens in next major release
      // const methodName = 'swapExactTokensForTokens'
      // if (trade.tradeType === TradeType.EXACT_OUTPUT) {
      //   throw new Error('Does not support setting output amount')
      // } else if (trade.tradeType !== TradeType.EXACT_INPUT && trade.tradeType !== TradeType.EXACT_INPUT) {
      //   throw new Error('Can only between ERC-20 tokens')
      // }

      const isExactInputTrade = trade.tradeType === TradeType.EXACT_INPUT

      const path = trade.route.path.map((r: Token | WrappedTokenInfo) =>
        r instanceof Token ? r.address : (r as WrappedTokenInfo).tokenInfo.address
      )

      const [amount0, amount1] = isExactInputTrade
        ? [toRawAmount(trade.inputAmount), toRawAmount(slippageAdjustedAmounts[Field.OUTPUT]!)]
        : [toRawAmount(trade.outputAmount), toRawAmount(slippageAdjustedAmounts[Field.INPUT]!)]

      // const gasPrice = await library?.getGasPrice()
      // const userGasLimit = BigNumber.from(isExpertMode ? userSwapGasLimit : GAS_LIMIT[environment].swap)
      // const gasLimit = userGasLimit.add(BigNumber.from((path.length - 2) * GAS_LIMIT[environment].hop))
      // const feeOnTokenA = await calculateFeeOnToken(
      //   chainId,
      //   path[0],
      //   trade.inputAmount.currency.decimals,
      //   gasPrice === undefined ? undefined : gasPrice.mul(gasLimit)
      // )
      // const maxTokenAmount = feeOnTokenA.toFixed(0)
      // const maxTokenAmount = toRawAmount(
      //   CurrencyAmount.fromFractionalAmount(
      //     trade.inputAmount.currency,
      //     feeOnTokenA.toFraction()[0].toString(10),
      //     feeOnTokenA.toFraction()[1].toString(10)
      //   )
      // )
      // const roundedFeeOnTokenA = feeOnTokenA.toFixed(0, 2)
      // const maxTokenAmount = Number.parseInt(roundedFeeOnTokenA) < 1 ? '1' : roundedFeeOnTokenA

      // console.table({
      //   inputAmountA: amount0,
      //   baseGasPrice: gasPrice.toString(),
      //   gasLimit: gasLimit.toString(),
      //   maxTokenAmount
      // })

      // const domain = {
      //   name: 'ConveyorV2',
      //   version: '1',
      //   chainId: BigNumber.from(chainId).toHexString(),
      //   verifyingContract: conveyorRouter?.address
      // }

      // const payload = {
      //   amount0: BigNumber.from(amount0).toHexString(),
      //   amount1: BigNumber.from(amount1).toHexString(),
      //   path,
      //   user,
      //   deadline: transactionDeadline?.toHexString()
      // }

      const payload = {
        amount0: BigNumber.from(amount0),
        amount1: BigNumber.from(amount1),
        path,
        user,
        deadline: transactionDeadline!
      }

      const gasLimit = isExpertMode ? BigNumber.from(userSwapGasLimit) : undefined

      console.log('gasLimit: ', gasLimit)

      // const fnParam = 'tuple(uint256 amount0,uint256 amount1,address[] path,address user,uint256 deadline)'
      // const fnData = [`function swapExactTokensForTokens(${fnParam})`]
      // const fnDataIface = new _Interface(fnData)

      // const message = {
      //   from: user,
      //   feeToken: path[0],
      //   maxTokenAmount: BigNumber.from(maxTokenAmount).toHexString(),
      //   deadline: transactionDeadline?.toHexString(),
      //   nonce: nonce.toHexString(),
      //   // data: fnDataIface.functions.swapExactTokensForTokens.encode([payload]),
      //   data: fnDataIface.encodeFunctionData('swapExactTokensForTokens', [payload]),
      //   hashedPayload: keccak256(
      //     defaultAbiCoder.encode(
      //       ['bytes', 'uint256', 'uint256', 'bytes32', 'address', 'uint256'],
      //       [
      //         keccak256(
      //           toUtf8Bytes(
      //             'swapExactTokensForTokens(uint256 amount0,uint256 amount1,address[] path,address user,uint256 deadline)'
      //           )
      //         ),
      //         payload.amount0,
      //         payload.amount1,
      //         keccak256(solidityPack(['address[]'], [payload.path])),
      //         payload.user,
      //         payload.deadline
      //       ]
      //     )
      //   )
      // }

      // const EIP712Msg = {
      //   types: {
      //     EIP712Domain: EIP712_DOMAIN_TYPE,
      //     Forwarder: FORWARDER_TYPE
      //     // Swap,
      //   },
      //   domain,
      //   primaryType: 'Forwarder',
      //   message
      // }

      // const sigParams = [user, JSON.stringify(EIP712Msg)]
      // const signature = await library.send('eth_signTypedData_v4', sigParams)
      // const { v, r, s } = splitSignature(signature)

      // const params = [chainId, EIP712Msg, v.toString(), r, s]
      // const jsonrpcRequest = {
      //   jsonrpc: '2.0',
      //   method: `/v2/metaTx/${methodName}`,
      //   id: 1,
      //   params
      // }
      // const requestOptions = {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(jsonrpcRequest)
      // }

      // const response = await fetch(RELAYER_ENDPOINT_MAP[environment][chainId], requestOptions)

      const xataApi = new Xata()
      await xataApi.init(
        library,
        userUseCustomFeeToken && userFeeCurrency ? userFeeCurrency.wrapped.address : path[0],
        environment
      )

      const response = isExactInputTrade
        ? await xataApi.swapExactTokensForTokens(
            payload.amount0,
            payload.amount1,
            payload.path,
            payload.user,
            payload.deadline,
            gasLimit
          )
        : await xataApi.swapTokensForExactTokens(
            payload.amount0,
            payload.amount1,
            payload.path,
            payload.user,
            payload.deadline,
            gasLimit
          )

      const { result } = response

      if (result.success === true) {
        const [inputTokenSymbol, outputTokenSymbol] = [
          trade.inputAmount.currency.symbol,
          trade.outputAmount.currency.symbol
        ]

        addTransaction({ hash: result.txnHash as string } as TransactionResponse, {
          summary: `Swap ${trade.inputAmount.toSignificant(
            3
          )} ${inputTokenSymbol} to ${outputTokenSymbol}`
        })

        let receipt = null
        while (receipt === null) {
          receipt = await library.getTransactionReceipt(result.txnHash)
        }

        const transactionLogs = receipt.logs
        let savedLoss: BigNumber | undefined = undefined
        // let lastUsedLogIndex: number = -1

        for (const log of transactionLogs) {
          //if this trade is a multihop trade, we should use the last SWAP event data
          if (
            log.topics[0] === '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822'
            // && log.logIndex > lastUsedLogIndex
          ) {
            const iface = new Interface([
              'event Swap(address indexed sender,uint amount0In,uint amount1In,uint amount0Out,uint amount1Out,address indexed to)'
            ])
            const logDescription = iface.parseLog(log)

            const {
              amount1Out,
              amount0Out,
              amount0In,
              amount1In
            }: { [key: string]: BigNumber } = logDescription.args
            const amountOut = amount1Out.eq(0) ? amount0Out : amount1Out
            const amountIn = amount0In.eq(0) ? amount1In : amount0In
            // const minAmountOut = BigNumber.from(trade.minimumAmountOut(allowedSlippage).quotient.toString())
            // const allowedSlippageFraction = new Fraction(JSBI.BigInt(allowedSlippage))
            // console.log(`TLog: amounts ${i}`, {
            //   amount1Out: amount1Out.toString(),
            //   amount0Out: amount0Out.toString(),
            //   amountOut: amountOut.toString(),
            //   minAmountOut: minAmountOut.toString(),
            // })
            // lastUsedLogIndex = log.logIndex
            if (isExactInputTrade) {
              const minAmountOut = BigNumber.from(
                trade.minimumAmountOut(new Percent(allowedSlippage, 10000)).quotient.toString()
              )
              savedLoss = amountOut.sub(minAmountOut)
            } else {
              const maxAmountIn = BigNumber.from(
                trade.maximumAmountIn(new Percent(allowedSlippage, 10000)).quotient.toString()
              )
              savedLoss = maxAmountIn.sub(amountIn)
            }
          }
        }

        let preventedLoss: string | undefined = undefined
        if (savedLoss !== undefined) {
          const decimals = isExactInputTrade
            ? trade.outputAmount.currency.decimals
            : trade.inputAmount.currency.decimals
          const formattedLoss = formatUnits(BigNumber.from(savedLoss), decimals)
          preventedLoss = `${new JSBigNumber(formattedLoss).toPrecision(5)} ${
            isExactInputTrade ? outputTokenSymbol : inputTokenSymbol
          }`
        }

        return { txnHash: result.txnHash, preventedLoss: preventedLoss }
      } else {
        throw new Error(result.errorMessage)
      }
    }

    return {
      state: SwapCallbackState.VALID,
      callback,
      error: null
    }
  }, [
    trade,
    library,
    account,
    chainId,
    recipient,
    recipientAddressOrName,
    // swapCalls,
    // useArcher,
    // userConveyorUseRelay,
    addTransaction,
    allowedSlippage,
    conveyorRouter,
    environment,
    transactionDeadline,
    isExpertMode,
    userFeeCurrency,
    userUseCustomFeeToken,
    userSwapGasLimit,
    slippageAdjustedAmounts
  ])
}
