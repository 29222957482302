import { Currency, CurrencyAmount } from '@xatadev/sdk'
import BigNumber from 'bignumber.js'

/**
 * Parse currency amount object into the raw amount in string
 * @param amount Currency amount
 * @returns Raw amount
 */
export const toRawAmount = (amount: CurrencyAmount<Currency> | BigNumber | string): string => {
  let rawAmount: string

  if (BigNumber.isBigNumber(amount)) {
    // TODO Optimize raw amount operation when amount is BigNumber
    rawAmount = amount.toString(10).replace('.', '')
  } else if (amount instanceof CurrencyAmount) {
    const { decimals } = amount.currency
    const format = {
      decimalSeparator: '',
      groupSeparator: ''
    }
    rawAmount = amount.toFixed(decimals, format)
  } else if (typeof amount === 'string') {
    // TODO Optimize raw amount operation when amount is string
    rawAmount = amount.replace('.', '')
  } else {
    throw new Error(
      `Parameter 'amount' with type ${typeof amount} is not supported. It can only accept CurrencyAmount, BigNumber, or numerical string.`
    )
  }

  return rawAmount
}
