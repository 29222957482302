import { NativeCurrency, Token } from '@xatadev/sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { darken } from 'polished'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useActiveWeb3React } from '../../hooks'
// import { AppState } from '../../state'
import { useUserFeeTokens, useUserUseCustomFeeToken } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import CurrencyLogo from '../CurrencyLogo'
import Loader from '../Loader'
// import Loader from '../Loader'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'

type Props = {
  currency: NativeCurrency | Token | undefined
  gasFee: BigNumberJS | undefined
  isFetchingFee: boolean
  inputIsValid?: boolean
  onFeeSelect?: () => void
}

// const shortenTokenSymbol = () => {}

const TransactionFeeCard = ({
  currency,
  gasFee,
  isFetchingFee,
  inputIsValid,
  onFeeSelect
}: Props) => {
  const { t } = useTranslation()

  const { chainId } = useActiveWeb3React()

  const theme = useContext(ThemeContext)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [, setUserFeeTokens] = useUserFeeTokens()

  // const isFetchingFee = useSelector<AppState, AppState['fee']['isFetching']>(
  //   state => state.fee.isFetching
  // )

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const handleCurrencySelect = useCallback(
    inputCurrency => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      // onCurrencySelection(Field.INPUT, inputCurrency)
      console.log('inputCurrency', inputCurrency)

      if (!chainId) return

      setUserFeeTokens(chainId, inputCurrency.address)

      if (onFeeSelect) {
        onFeeSelect()
      }
    },
    [chainId, onFeeSelect, setUserFeeTokens]
  )

  return (
    <StyledTransactionFeeCard $currencySelected={!!currency}>
      <div className="labelColumn">
        <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
          Max Gas Fee
        </TYPE.body>
      </div>

      <div className="inputColumn">
        {/*<TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>*/}
        {/*  {gasFee && currency ? `${gasFee.toString()} ${currency.symbol}` : '-'}*/}
        {/*</TYPE.body>*/}
        {isFetchingFee ? (
          <Loader stroke={theme.primary1} />
        ) : (
          <>
            {gasFee && currency && inputIsValid ? (
              <button
                className="currencySelect open-currency-select-button"
                // selected={!!currency}
                onClick={() => {
                  if (userUseCustomFeeToken) {
                    setModalOpen(true)
                  }
                }}
                disabled={!userUseCustomFeeToken}
              >
                <span className="aligner">
                  <CurrencyLogo currency={currency} size={'20px'} />
                  <TYPE.body className="gasFee" color={theme.text2} fontWeight={500} fontSize={14}>
                    {gasFee.toString()}
                  </TYPE.body>
                  <StyledTokenName
                    className="token-symbol-container"
                    $active={Boolean(currency && currency.symbol)}
                  >
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol) || t('selectToken')}
                  </StyledTokenName>
                  {/*{!disableCurrencySelect && <StyledDropDown $selected={!!currency} />}*/}
                  {userUseCustomFeeToken && <StyledDropDown $selected={!!currency} />}
                </span>
              </button>
            ) : (
              '-'
            )}
          </>
        )}
      </div>

      <CurrencySearchModal
        isOpen={modalOpen}
        onDismiss={handleDismissSearch}
        onCurrencySelect={handleCurrencySelect}
        selectedCurrency={currency}
        showCommonBases={false}
      />
    </StyledTransactionFeeCard>
  )
}

const StyledDropDown = styled(DropDown)<{ $selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ $selected, theme }) => ($selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

// ${({ $active }) =>
//   $active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;'}
const StyledTokenName = styled.span<{ $active?: boolean }>`
  //font-size:  ${({ $active }) => ($active ? '20px' : '16px')};
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
`

const StyledTransactionFeeCard = styled.div<{ $currencySelected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.bg3};
  background-color: ${({ theme }) => theme.bg1};
  z-index: 1;
  justify-content: space-between;
  align-items: center;

  .labelColumn,
  .inputColumn {
    padding: 0.75rem 1rem;
    display: flex;
    align-self: center;
  }

  .inputColumn {
  }

  .gasFee {
    margin: 0 0.5rem;
  }

  .currencySelect {
    align-items: center;
    //height: 2.2rem;
    font-size: 14px;
    font-weight: 500;
    background-color: ${({ $currencySelected, theme }) =>
      $currencySelected ? theme.bg1 : theme.primary1};
    color: ${({ $currencySelected, theme }) => ($currencySelected ? theme.text1 : theme.white)};
    border-radius: 5px;
    box-shadow: ${({ $currencySelected }) =>
      $currencySelected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)'};
    outline: none;
    cursor: pointer;
    user-select: none;
    border: none;
    //padding: 0 0.5rem;

    :focus,
    :hover {
      background-color: ${({ $currencySelected, theme }) =>
        $currencySelected ? theme.bg2 : darken(0.05, theme.primary1)};
    }
  }

  .aligner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export default TransactionFeeCard
