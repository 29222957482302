import React from 'react'
import { useActiveWeb3React } from '../../hooks'

import Modal from '../Modal'
import { ConfirmationPendingContent, TransactionSubmittedContent } from '../TransactionConfirmationModal'

type StakeTransactionModalProps = {
  hash: string | undefined
  isOpen: boolean
  pendingText: string
  attemptingTxn: boolean
  overlayColor?: string
  content: () => React.ReactNode
  onDismiss: () => void
}

const StakeTransactionModal = ({
  hash,
  isOpen,
  pendingText,
  attemptingTxn,
  overlayColor,
  content,
  onDismiss
}: StakeTransactionModalProps) => {
  const { chainId } = useActiveWeb3React()
  if (!chainId) return null

  return (
    <Modal isOpen={isOpen} overlayColor={overlayColor} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent chainId={chainId} hash={hash} preventedLoss={undefined} onDismiss={onDismiss} />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default StakeTransactionModal
