import { Currency, Trade, TradeType, WNATIVE } from '@xatadev/sdk'
import { BigNumber as JSBigNumber } from 'bignumber.js'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { ThemeContext } from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { Field } from '../../state/swap/actions'
import {
  useUserFeeTokens,
  useUserSlippageTolerance,
  useUserUseCustomFeeToken
} from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'

// const InfoLink = styled(ExternalLink)`
//   width: 100%;
//   border: 1px solid ${({ theme }) => theme.bg3};
//   padding: 6px 6px;
//   border-radius: 8px;
//   text-align: center;
//   font-size: 14px;
//   color: ${({ theme }) => theme.text1};
// `

function TradeSummary({
  trade,
  allowedSlippage
}: {
  trade: Trade<Currency, Currency, TradeType>
  allowedSlippage: number
}) {
  const { chainId } = useActiveWeb3React()

  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee, realizedLPFee, liquidityProviderFee } = computeTradePriceBreakdown(
    trade.route.chainId,
    trade
  )
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  // We don't need gToken anymore
  // const originalInputTokenSymbol =
  //   trade.inputAmount.currency.symbol !== undefined && trade.inputAmount.currency.symbol.startsWith('g')
  //     ? trade.inputAmount.currency.symbol.substring(1)
  //     : trade.inputAmount.currency.symbol
  // const originalOutputTokenSymbol =
  //   trade.outputAmount.currency.symbol !== undefined && trade.outputAmount.currency.symbol.startsWith('g')
  //     ? trade.outputAmount.currency.symbol.substring(1)
  //     : trade.outputAmount.currency.symbol
  const originalInputTokenSymbol = trade.inputAmount.currency.symbol
  const originalOutputTokenSymbol = trade.outputAmount.currency.symbol

  const swapFee = useSelector<AppState, AppState['fee']['swapFeeInTokens']>(
    state => state.fee.swapFeeInTokens
  )
  const roundedSwapFee = swapFee === undefined ? undefined : new JSBigNumber(swapFee).toFixed(0, 2)
  const swapFeeFraction =
    roundedSwapFee === undefined
      ? undefined
      : trade.inputAmount.currency === undefined
      ? undefined
      : new JSBigNumber(roundedSwapFee).div(
          new JSBigNumber(10)
            .pow(trade.inputAmount.currency!.decimals)
            .toFixed(trade.inputAmount.currency!.decimals)
        )
  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [userFeeTokens] = useUserFeeTokens()
  const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  const potentialMEV = isExactIn
    ? trade.outputAmount.subtract(slippageAdjustedAmounts[Field.OUTPUT]!)
    : slippageAdjustedAmounts[Field.INPUT]?.subtract(trade.inputAmount)

  const liquidityFeeHelper =
    'A portion of each trade (' +
    liquidityProviderFee +
    '%) goes to liquidity providers as a protocol incentive.'

  return (
    <>
      <AutoColumn style={{ padding: '0 16px' }}>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              {isExactIn ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={theme.text1} fontSize={14}>
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(
                    4
                  )} ${originalOutputTokenSymbol}` ?? '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(
                    4
                  )} ${originalInputTokenSymbol}` ?? '-'}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper text={liquidityFeeHelper} />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${originalInputTokenSymbol}` : '-'}
          </TYPE.black>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Max Gas Fee
            </TYPE.black>
            {/* eslint-disable max-len */}
            <QuestionHelper
              text={`The maximum possible transaction gas fee to be deducted. You won't pay ${WNATIVE[
                chainId!
              ].symbol?.substring(1)} for this transaction.`}
            />
            {/* eslint-enable */}
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {/*{swapFeeFraction && originalOutputTokenSymbol !== undefined*/}
            {/*  ? // `${swapFeeFraction.toPrecision(6)} ${originalInputTokenSymbol}`*/}
            {/*    `${swapFeeFraction.toString()} ${originalInputTokenSymbol}`*/}
            {/*  : '-'}*/}
            {swapFeeFraction ? (
              <>
                {swapFeeFraction.toString()}&nbsp;
                {userUseCustomFeeToken && userFeeCurrency
                  ? `${userFeeCurrency.symbol}`
                  : !userUseCustomFeeToken && originalOutputTokenSymbol !== undefined
                  ? `${originalInputTokenSymbol}`
                  : ''}
              </>
            ) : (
              '-'
            )}
          </TYPE.black>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Potential MEV protection
            </TYPE.black>
            <QuestionHelper text="Based on the minimum received amount and estimated received amount" />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {potentialMEV
              ? isExactIn
                ? `${potentialMEV.toSignificant(4)} ${originalInputTokenSymbol}`
                : `${potentialMEV.toSignificant(6)} ${originalOutputTokenSymbol}`
              : '-'}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade<Currency, Currency, TradeType>
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)

  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="0px">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <RowBetween style={{ padding: '0 16px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                    Route
                  </TYPE.black>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </span>
                <SwapRoute trade={trade} />
              </RowBetween>
            </>
          )}
          {/* {!showRoute && (
            <AutoColumn style={{ padding: '12px 16px 0 16px' }}>
              <InfoLink
                href={'https://uniswap.info/pair/' + trade.route.pairs[0].liquidityToken.address}
                target="_blank"
              >
                View pair analytics ↗
              </InfoLink>
            </AutoColumn>
          )} */}
        </>
      )}
    </AutoColumn>
  )
}
