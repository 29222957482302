import { createReducer } from '@reduxjs/toolkit'
import { ChainId } from '@xatadev/sdk'

import { addTokenPrices, PriceOfToken } from './actions'

type TokenPricesState = {
  [chainId: number]: {
    [currencyCode: string]: PriceOfToken
  }
}

const initialState: TokenPricesState = {
  [ChainId.MAINNET]: {
    usd: {}
  },
  [ChainId.BSC]: {
    usd: {
      '0xa2120b9e674d3fc3875f415a7df52e382f141225': 0.636688, // ata
      '0x55d398326f99059ff775485246999027b3197955': 1 // usdt / bsc-usd
    }
  },
  [ChainId.MATIC]: {
    usd: {
      '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': 2.07, // wmatic
      '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063': 1, // dai
      '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a': 7.45, // sushi
      '0x0df0f72ee0e5c9b7ca761ecec42754992b2da5bf': 0.636688, // ata
      '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619': 4483.36, // weth
      '0xc2132d05d31c914a87c6611c10748aeb04b58e8f': 1 // usdt
    }
  },
  [ChainId.MOONRIVER]: {
    usd: {}
  },
  [ChainId.ARBITRUM]: {
    usd: {}
  }
}

export default createReducer(initialState, builder => {
  builder.addCase(addTokenPrices, (state, { payload: { chainId, currencyCode, pricesToAdd } }) => {
    if (!state[chainId]) {
      state[chainId] = {}
    }
    if (state[chainId] && !state[chainId][currencyCode]) {
      state[chainId][currencyCode] = {}
    }

    state[chainId][currencyCode] = {
      ...state[chainId][currencyCode],
      ...pricesToAdd
    }
  })
})
