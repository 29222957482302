import { Interface } from '@ethersproject/abi'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber, BigNumberish } from '@ethersproject/bignumber'
import { Web3Provider } from '@ethersproject/providers'
import { abi as PAIR_ABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { ChainId, Environment, fetchPrice, Pair, Token } from '@xatadev/sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import ERC20_INTERFACE from 'constants/abis/erc20'
import { FARMS_MULTI_REWARD_POOL_ABI } from 'constants/abis/farms-reward-pool'
import isEmpty from 'lodash/isEmpty'
import { PriceOfToken } from 'state/tokenPrices/actions'
import { getContract } from 'utils'
import { SerializedToken } from 'utils/serializeToken'

// import { PRICE_API_PREFIX } from '../constants/price/price'
import { AbstractRewardPool } from './AbstractRewardPool'

type SerializeRewardToken = {
  address: string
  decimals: number
  symbol: string
}

type RewardData<S> = {
  [rewardTokenAddress: string]: S
}

export class RewardPool extends AbstractRewardPool {
  public readonly chainId: number

  public readonly address: string

  public readonly tokenA: Token

  public readonly tokenB: Token

  public readonly stakingToken: Token

  public tags: string[] = []

  public lpPrice: BigNumberJS = new BigNumberJS(0)

  public totalSupply: BigNumberJS = new BigNumberJS(0)

  public totalInFarm: BigNumberJS = new BigNumberJS(0)

  public rewardTokens: SerializeRewardToken[] = []

  public rewardPerTokenStored?: RewardData<BigNumberJS> = {}

  public rewardRate?: RewardData<BigNumberJS> = {}

  public rewardsDistributor?: RewardData<string> = {}

  public rewardsDuration?: RewardData<number> = {}

  public rewardsApr?: RewardData<BigNumberJS> = {}

  public lastUpdateTime?: RewardData<number> = {}

  public periodFinish?: RewardData<number> = {}

  public isActive?: RewardData<boolean> = {}

  public userStakedAmount: BigNumberJS = new BigNumberJS(0)

  public userEarnings: BigNumberJS[] = []

  public tokenPrices: PriceOfToken = {}

  private _e?: Environment

  /**
   * Multi rewards constructor cannot be called directly. Use the initializeData method
   * to create a pool instance.
   * @param chainId
   * @param address
   * @param tokenA
   * @param tokenB
   * @protected
   */
  protected constructor(
    chainId: number,
    address: string,
    tokenA: SerializedToken,
    tokenB: SerializedToken
  ) {
    super(
      chainId,
      address,
      new Token(chainId, tokenA.address, tokenA.decimals, tokenA.symbol, tokenA.name),
      new Token(chainId, tokenB.address, tokenB.decimals, tokenB.symbol, tokenB.name)
    )

    this.chainId = chainId
    this.address = address
    this.tokenA = new Token(chainId, tokenA.address, tokenA.decimals, tokenA.symbol, tokenA.name)
    this.tokenB = new Token(chainId, tokenB.address, tokenB.decimals, tokenB.symbol, tokenB.name)

    const stakingTokenAddress = Pair.getAddress(this.tokenA, this.tokenB)
    this.stakingToken = new Token(chainId, stakingTokenAddress, 18, 'CON-V2', 'Conveyor V2')

    this.tags.push(tokenA.symbol, tokenB.symbol)
  }

  public static async initializeData(
    data: any,
    library: Web3Provider,
    account?: string,
    env: Environment = Environment.PRODUCTION
  ) {
    const { chainId, address, tokenA, tokenB, tokenPrices: fallbackTokenPrices } = data
    const mrp = new RewardPool(chainId, address, tokenA, tokenB)

    mrp._e = env
    mrp.contract = getContract(mrp.address, FARMS_MULTI_REWARD_POOL_ABI, library, account)

    try {
      mrp.rewardTokens = [...(await mrp.fetchAllRewardTokens(library))]
      mrp.tags.push(
        ...mrp.rewardTokens.map(({ symbol }) => symbol).filter(symbol => !mrp.tags.includes(symbol))
      )

      const tokenPrices = await mrp.fetchTokenPrices(fallbackTokenPrices)
      mrp.tokenPrices = tokenPrices
      console.log('prices', tokenPrices)

      mrp.lpPrice = await mrp.fetchLpPrice(tokenPrices, library)
      mrp.totalSupply = await mrp.fetchTotalSupply()
      mrp.totalInFarm = mrp.fetchTotalInFarm()

      const rewardData = await mrp.fetchAllRewardData()
      mrp.lastUpdateTime = { ...rewardData.lastUpdateTime }
      mrp.periodFinish = { ...rewardData.periodFinish }
      mrp.isActive = { ...rewardData.isActive }
      mrp.rewardPerTokenStored = { ...rewardData.rewardPerTokenStored }
      mrp.rewardRate = { ...rewardData.rewardRate }
      mrp.rewardsDistributor = { ...rewardData.rewardsDistributor }
      mrp.rewardsDuration = { ...rewardData.rewardsDuration }

      mrp.rewardsApr = mrp.rewardTokens.reduce<{ [address: string]: BigNumberJS }>(
        (memo, rewardToken) => {
          const rewardTokenPrice = tokenPrices[rewardToken.address.toLowerCase()]
          return {
            ...memo,
            [rewardToken.address]: mrp.calculateRewardApr(rewardToken.address, rewardTokenPrice)
          }
        },
        {}
      )

      if (account) {
        const [stakedAmount, earnings] = await Promise.all([
          mrp.fetchUserStakedAmount(account),
          mrp.fetchUserEarnings(account)
        ])
        mrp.userStakedAmount = stakedAmount
        mrp.userEarnings = earnings
        // const userEarnings = await mrp.fetchUserEarnings(account)
        // mrp.userEarnings = userEarnings.reduce<BigNumberJS>(
        //   (sum, earningPerToken) => sum.plus(earningPerToken),
        //   new BigNumberJS(0)
        // )
      }
    } catch (e) {
      throw new Error(e?.message)
    } finally {
      // resolve(mrp)
    }

    return mrp
    // })
  }

  public async stake(amount: string /*, account: string*/) {
    return new Promise<any>((resolve, reject) => {
      this.contract
        ?.stake(amount, { gasLimit: 350000 })
        .then(async (response: TransactionResponse) => {
          try {
            // const _amount = new BigNumberJS(amount).div(10 ** 18)
            this.userStakedAmount = this.userStakedAmount.plus(amount)
            this.fetchTotalSupply().then(result => {
              this.totalSupply = result
              this.totalInFarm = this.fetchTotalInFarm()
            })
            // this.contract?.totalSupply().then((totalSupply: BigNumber) => {
            //   this.totalSupply = new BigNumberJS(Number(totalSupply))
            // })
          } catch (e) {
            console.error('Failed to retrieve total supply update.', e)
          }

          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  public async withdraw(amount: string /*, account: string*/) {
    return new Promise<any>((resolve, reject) => {
      this.contract
        ?.withdraw(amount, { gasLimit: 350000 })
        .then(async (response: TransactionResponse) => {
          try {
            // const _amount = new BigNumberJS(amount).div(10 ** 18)
            this.userStakedAmount = this.userStakedAmount.minus(amount)
            this.fetchTotalSupply().then(result => {
              this.totalSupply = result
              this.totalInFarm = this.fetchTotalInFarm()
            })
            // this.contract?.totalSupply().then((totalSupply: BigNumber) => {
            //   this.totalSupply = new BigNumberJS(Number(totalSupply))
            // })
          } catch (e) {
            console.error('Failed to retrieve total supply update.', e)
          }

          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  public async harvestReward() {
    return new Promise<any>((resolve, reject) => {
      this.contract
        ?.getReward({ gasLimit: 350000 })
        .then((response: TransactionResponse) => {
          // this.contract?.totalSupply().then((totalSupply: BigNumber) => {
          //   this.totalSupply = new BigNumberJS(Number(totalSupply))
          // })
          this.userEarnings = this.rewardTokens.map(() => new BigNumberJS(0))

          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  public async exit() {
    return new Promise<any>((resolve, reject) => {
      this.contract
        ?.exit({ gasLimit: 350000 })
        .then(async (response: TransactionResponse) => {
          // this.contract?.totalSupply().then((totalSupply: BigNumber) => {
          //   this.totalSupply = new BigNumberJS(Number(totalSupply))
          // })
          // this.userEarnings = this.rewardTokens.map(() => new BigNumberJS(0))
          // this.userStakedAmount = new BigNumberJS(0)
          try {
            this.userStakedAmount = new BigNumberJS(0)
            this.userEarnings = this.userEarnings.map(() => new BigNumberJS(0))
            this.totalSupply = await this.fetchTotalSupply()
            this.totalInFarm = this.fetchTotalInFarm()
          } catch (e) {
            console.error('Failed to retrieve latest pool data update.', e)
          }

          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  // public async getTotalInFarm(lpPrice: BigNumberJS | undefined) {
  //   const callResult = await this.fetchTotalSupply()
  //   const totalSupply = callResult.dividedBy(10 ** 18)
  //   this.totalInFarm = lpPrice ? totalSupply.multipliedBy(lpPrice) : new BigNumberJS(0)
  //
  //   return this.totalInFarm
  // }

  /**
   * Get the latest period by comparing each reward tokens periodFinish
   */
  public getTimeRemaining(): number {
    if (!this.periodFinish) return -1

    const keys = Object.keys(this.periodFinish)
    const periods = keys.map(key =>
      this.periodFinish && !isEmpty(this.periodFinish) ? this.periodFinish[key] : -1
    )

    return Math.max(...periods)
  }

  public getAprAccumulation(): BigNumberJS {
    if (!this.rewardsApr || (this.rewardsApr && isEmpty(this.rewardsApr))) {
      return new BigNumberJS(0)
    }
    return Object.values(this.rewardsApr).reduce<BigNumberJS>(
      (accum, apr) => accum.plus(apr),
      new BigNumberJS(0)
    )
  }

  private calculateRewardApr(rewardTokenAddress: string, rewardTokenPrice: number): BigNumberJS {
    if (!this.rewardRate) return new BigNumberJS(0)

    const now = Math.floor(new Date().getTime() / 1000)
    if (this.periodFinish && this.periodFinish[rewardTokenAddress] <= now) {
      return new BigNumberJS(0)
    }

    // console.log('getTimeRemaining', this.getTimeRemaining())

    const secondsInYear = new BigNumberJS(60 * 60 * 24).multipliedBy(365.25)
    const rewardPerYear = this.rewardRate[rewardTokenAddress].multipliedBy(secondsInYear)

    const calcSegment1 = rewardPerYear.multipliedBy(rewardTokenPrice)
    const calcSegment2 = this.totalSupply.multipliedBy(this.lpPrice)

    // apr
    return new BigNumberJS(calcSegment1.div(calcSegment2)).multipliedBy(100)
  }

  public async fetchTokenPrices(fallbackTokenPrices: any) {
    const chain =
      this.chainId === 56 ? ChainId.BSC : this.chainId === 137 ? ChainId.MATIC : undefined
    // console.log('chain', this.chainId)

    if (!chain) return fallbackTokenPrices

    let pricesResult: any = {}
    try {
      // const tokenAddressQuery = encodeURIComponent(
      //   this.rewardTokens.map(({ address }) => address.toLowerCase()).join(',')
      // )
      // const priceApiUrl = `${
      //   PRICE_API_PREFIX[Environment.STAGING][chain]
      // }tokens=${tokenAddressQuery}&base=usd`
      // const response = await fetch(priceApiUrl)

      const rewardTokenAddresses = this.rewardTokens.map(({ address }) => address.toLowerCase())
      const response = await fetchPrice(chain, rewardTokenAddresses, 'usd', this._e)

      pricesResult = await response.json()

      for (const key of Object.keys(pricesResult)) {
        pricesResult[key] = pricesResult[key].usd
      }
    } catch (e) {
      console.error(
        'Failed to get token prices from CoinGecko. Fallback token prices will be used instead.',
        e
      )
    }

    // console.log([fallbackTokenPrices, Object.assign({}, fallbackTokenPrices, pricesResult)])

    return Object.assign({}, fallbackTokenPrices, pricesResult)
  }

  public async fetchUserStakedAmount(account: string) {
    const callResult = await this.contract?.balanceOf(account)

    // user staked amount
    return new BigNumberJS(callResult.toString())
  }

  public async fetchUserEarnings(account: string) {
    // if (isEmpty(this.rewardTokens)) return [new BigNumberJS(0)]

    const multiCalls = this.rewardTokens.map(({ address }) =>
      this.contract?.earned(account, address)
    )
    const callResult = await Promise.all(multiCalls)

    return callResult.map(result => new BigNumberJS(Number(result)))
  }

  public async fetchTotalSupply() {
    let totalSupply: BigNumberish
    try {
      totalSupply = await this.contract?.totalSupply()
    } catch (e) {
      console.error('Failed to retrieve total supply amount from contract.', e)
      totalSupply = BigNumber.from(0)
    }

    return new BigNumberJS(Number(totalSupply))
  }

  /**
   *
   */
  private async fetchLpPrice(tokenPrices: PriceOfToken, library: Web3Provider) {
    const pairContract = getContract(this.stakingToken.address, new Interface(PAIR_ABI), library)

    let result: [{ reserve0: BigNumber; reserve1: BigNumber }, BigNumber, number, string, string]
    let reserve0: BigNumber
    let reserve1: BigNumber
    let lpTotalSupply: BigNumber
    let lpDecimals: number = 1
    let decimal0: number = 1
    let decimal1: number = 1
    let token0Address: string = ''
    let token1Address: string = ''
    try {
      result = await Promise.all<
        { reserve0: BigNumber; reserve1: BigNumber },
        BigNumber,
        number,
        string,
        string
      >([
        pairContract.getReserves(),
        pairContract.totalSupply(),
        pairContract.decimals(),
        pairContract.token0(),
        pairContract.token1()
      ])

      decimal0 = await getContract(this.tokenA.address, ERC20_INTERFACE, library).decimals()
      decimal1 = await getContract(this.tokenB.address, ERC20_INTERFACE, library).decimals()

      reserve0 = result[0].reserve0
      reserve1 = result[0].reserve1
      lpTotalSupply = result[1]
      lpDecimals = result[2]
      token0Address = result[3]
      token1Address = result[4]
    } catch (e) {
      console.error("Failed to get pair data from contract. The pair probably doesn't exist.", e)
      reserve0 = reserve1 = BigNumber.from(1)
      lpTotalSupply = BigNumber.from(0)
    }
    const [priceA, priceB] = [
      tokenPrices[token0Address.toLowerCase()] ?? 1,
      tokenPrices[token1Address.toLowerCase()] ?? 1
    ]

    const [valueA, valueB] = [
      new BigNumberJS(Number(reserve0)).multipliedBy(priceA).dividedBy(10 ** decimal0),
      new BigNumberJS(Number(reserve1)).multipliedBy(priceB).dividedBy(10 ** decimal1)
    ]
    const totalValueOfTokensAB = valueA.plus(valueB)

    return totalValueOfTokensAB.multipliedBy(10 ** lpDecimals).dividedBy(Number(lpTotalSupply))
  }

  /**
   *
   */
  public fetchTotalInFarm() {
    const { decimals } = this.stakingToken
    return this.totalSupply.multipliedBy(this.lpPrice).div(10 ** decimals)
  }

  public updateTotalInFarm() {
    this.totalInFarm = this.fetchTotalInFarm()
  }

  private async fetchAllRewardTokens(library: Web3Provider) {
    let callResult: string[] = []
    try {
      callResult = await this.contract?.getAllRewardTokens()
    } catch (e) {
      console.error(`Failed to retrieve all reward tokens for pool with address ${this.address}`, e)
    }

    if (isEmpty(callResult)) return []

    const multiCalls = {
      symbol: callResult.map(tokenAddress =>
        getContract(tokenAddress, ERC20_INTERFACE, library).symbol()
      ),
      decimals: callResult.map(tokenAddress =>
        getContract(tokenAddress, ERC20_INTERFACE, library).decimals()
      )
    }
    const symbols = await Promise.all(multiCalls.symbol)
    const decimals = await Promise.all(multiCalls.decimals)

    // all reward tokens on pool
    return callResult.reduce<SerializeRewardToken[]>((memo, tokenAddress, i) => {
      memo.push({
        address: tokenAddress,
        decimals: decimals[i],
        symbol: symbols[i]
      })
      return memo
    }, [])
  }

  private async fetchAllRewardData() {
    const now = Math.floor(new Date().getTime() / 1000)

    let lastUpdateTime: RewardData<number> = {}
    let periodFinish: RewardData<number> = {}
    let rewardPerTokenStored: RewardData<BigNumberJS> = {}
    let rewardRate: RewardData<BigNumberJS> = {}
    let rewardsDistributor: RewardData<string> = {}
    let rewardsDuration: RewardData<number> = {}
    let isActive: RewardData<boolean> = {}

    if (this.rewardTokens.length) {
      const multiCalls: Promise<any>[] = this.rewardTokens.map(({ address }) =>
        this.contract?.rewardData(address)
      )
      const callResult = await Promise.all(multiCalls)
      // console.log(callResult)

      for (const [_i, _v] of Object.entries(callResult)) {
        const i = Number(_i)
        const key = this.rewardTokens[i].address
        const {
          lastUpdateTime: _lastUpdateTime,
          periodFinish: _periodFinish,
          rewardPerTokenStored: _rewardPerTokenStored,
          rewardRate: _rewardRate,
          rewardsDistributor: _rewardsDistributor,
          rewardsDuration: _rewardsDuration
        } = _v

        lastUpdateTime = { ...lastUpdateTime, [key]: _lastUpdateTime.toNumber() }
        periodFinish = { ...periodFinish, [key]: _periodFinish.toNumber() }
        rewardPerTokenStored = {
          ...rewardPerTokenStored,
          [key]: new BigNumberJS(Number(_rewardPerTokenStored))
        }
        rewardRate = {
          ...rewardRate,
          [key]: new BigNumberJS(Number(_rewardRate))
        }
        rewardsDistributor = { ...rewardsDistributor, [key]: _rewardsDistributor }
        rewardsDuration = { ...rewardsDuration, [key]: Number(_rewardsDuration) }

        const activeState: boolean =
          now < periodFinish[key] && this.totalSupply !== undefined && this.totalSupply.gte(0)
        isActive = { ...isActive, [key]: activeState }
      }
    }

    return {
      lastUpdateTime,
      periodFinish,
      rewardPerTokenStored,
      rewardRate,
      rewardsDistributor,
      rewardsDuration,
      isActive
    }
  }
}
