import React from 'react'
import uniqid from 'uniqid'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import { AutoRow } from '../Row'
import { AutoColumn } from '../Column'

type TooltipContentProps<T> = {
  title?: string
  tokenLogos?: string[]
  labels: string[]
  values: T[]
}

const TooltipWrap = styled(AutoRow)`
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
`

const TooltipText = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
`

const TooltipLabel = styled(TooltipText)`
  color: ${({ theme }) => theme.farms.textSub};
`

const TooltipValue = styled(TooltipText)`
  color: ${({ theme }) => theme.farms.textMain};
  font-weight: 500;
  text-align: right;
  flex: 1 0 auto;
`

const TokenLogo = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 16px;
  border: solid 1px ${({ theme }) => theme.farms.border2};
  overflow: hidden;
  background-color: ${({ theme }) => theme.farms.primer};
  margin-right: 7px;
`

const TooltipContent = ({ title, tokenLogos, labels, values }: TooltipContentProps<string>) => {
  return (
    <AutoColumn style={{ width: '100%' }}>
      {title}
      {values.map((value, i) => (
        <TooltipWrap key={`${labels[i]}_${uniqid()}`}>
          {tokenLogos && !isEmpty(tokenLogos) ? <TokenLogo src={tokenLogos[i]} /> : null}
          <TooltipLabel>{labels[i]}</TooltipLabel>
          <TooltipValue>{value}</TooltipValue>
        </TooltipWrap>
      ))}
    </AutoColumn>
  )
}

export default TooltipContent

export const TooltipCountdownContent = ({
  title,
  tokenLogos,
  labels,
  values: deadlines
}: TooltipContentProps<number>) => {
  const now = Math.floor(new Date().getTime() / 1000)
  const readableDeadlines: string[] = deadlines.map(deadline => {
    if (deadline <= now) return 'Expired'

    const seconds = deadline - now
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    hours = hours - days * 24
    minutes = minutes - days * 24 * 60 - hours * 60

    return (
      `${days > 0 ? `${days}d:` : ''}` +
      `${hours > 0 || (days > 0 && hours === 0) ? `${hours.toString().padStart(2, '0')}h:` : ''}` +
      `${minutes.toString().padStart(2, '0')}m`
    )
  })

  return <TooltipContent title={title} tokenLogos={tokenLogos} labels={labels} values={readableDeadlines} />
}
