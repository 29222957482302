import { Currency, CurrencyAmount, JSBI, Pair, Percent, Token } from '@xatadev/sdk'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Field as AddField } from 'state/AddLiquidity/actions'
import { useAddliquidityActionHandlers } from 'state/AddLiquidity/hooks'

import { usePair } from '../../data/Reserves'
import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { AppDispatch, AppState } from '../index'
import { tryParseAmount } from '../swap/hooks'
import { useTokenBalances } from '../wallet/hooks'
import { Field, typeInput } from './actions'

export function useBurnState(): AppState['burn'] {
  return useSelector<AppState, AppState['burn']>(state => state.burn)
}

export function useDerivedBurnInfo(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
): {
  pair?: Pair | null
  parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
  }
  error?: string
} {
  const { account, chainId } = useActiveWeb3React()

  const { independentField, typedValue } = useBurnState()

  const history = useHistory()
  const location = useLocation()

  const { onCurrencySelection } = useAddliquidityActionHandlers()

  // pair + totalsupply
  const [, pair] = usePair(currencyA, currencyB)
  if (!pair && location.pathname === '/remove') {
    history.push('/pool')
  }

  // balances
  const relevantTokenBalances = useTokenBalances(account ?? undefined, [pair?.liquidityToken])
  const userLiquidity: undefined | CurrencyAmount<Token> =
    relevantTokenBalances?.[pair?.liquidityToken?.address ?? '']
  if (pair && userLiquidity?.equalTo(0) && location.pathname === '/remove') {
    onCurrencySelection(AddField.CURRENCY_A, currencyA)
    onCurrencySelection(AddField.CURRENCY_B, currencyB)
    history.push('/add')
  }

  const [tokenA, tokenB] = [
    wrappedCurrency(currencyA, chainId),
    wrappedCurrency(currencyB, chainId)
  ]
  const tokens = {
    [Field.CURRENCY_A]: tokenA,
    [Field.CURRENCY_B]: tokenB,
    [Field.LIQUIDITY]: pair?.liquidityToken
  }

  // liquidity values
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const liquidityValueA =
    pair &&
    totalSupply &&
    userLiquidity &&
    tokenA &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalSupply.numerator, userLiquidity.numerator)
      ? CurrencyAmount.fromRawAmount(
          tokenA,
          pair.getLiquidityValue(tokenA, totalSupply, userLiquidity, false).numerator
        )
      : undefined
  const liquidityValueB =
    pair &&
    totalSupply &&
    userLiquidity &&
    tokenB &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalSupply.numerator, userLiquidity.numerator)
      ? CurrencyAmount.fromRawAmount(
          tokenB,
          pair.getLiquidityValue(tokenB, totalSupply, userLiquidity, false).numerator
        )
      : undefined
  const liquidityValues: {
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
  } = {
    [Field.CURRENCY_A]: liquidityValueA,
    [Field.CURRENCY_B]: liquidityValueB
  }

  let percentToRemove: Percent = new Percent('0', '100')
  // user specified a %
  if (independentField === Field.LIQUIDITY_PERCENT) {
    percentToRemove = new Percent(typedValue, '100')
  }
  // user specified a specific amount of liquidity tokens
  else if (independentField === Field.LIQUIDITY) {
    if (pair?.liquidityToken) {
      const independentAmount = tryParseAmount(typedValue, pair.liquidityToken)
      if (independentAmount && userLiquidity && !independentAmount.greaterThan(userLiquidity)) {
        percentToRemove = new Percent(independentAmount.numerator, userLiquidity.numerator)
      }
    }
  }
  // user specified a specific amount of token a or b
  else {
    if (tokens[independentField]) {
      const independentAmount = tryParseAmount(typedValue, tokens[independentField])
      const liquidityValue = liquidityValues[independentField]
      if (independentAmount && liquidityValue && !independentAmount.greaterThan(liquidityValue)) {
        percentToRemove = new Percent(independentAmount.numerator, liquidityValue.numerator)
      }
    }
  }

  const parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
  } = {
    [Field.LIQUIDITY_PERCENT]: percentToRemove,
    [Field.LIQUIDITY]:
      userLiquidity && percentToRemove && percentToRemove.greaterThan('0')
        ? CurrencyAmount.fromRawAmount(
            userLiquidity.currency,
            percentToRemove.multiply(userLiquidity.numerator).quotient
          )
        : undefined,
    [Field.CURRENCY_A]:
      tokenA && percentToRemove && percentToRemove.greaterThan('0') && liquidityValueA
        ? CurrencyAmount.fromRawAmount(
            tokenA,
            percentToRemove.multiply(liquidityValueA.numerator).quotient
          )
        : undefined,
    [Field.CURRENCY_B]:
      tokenB && percentToRemove && percentToRemove.greaterThan('0') && liquidityValueB
        ? CurrencyAmount.fromRawAmount(
            tokenB,
            percentToRemove.multiply(liquidityValueB.numerator).quotient
          )
        : undefined
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  if (
    !parsedAmounts[Field.LIQUIDITY] ||
    !parsedAmounts[Field.CURRENCY_A] ||
    !parsedAmounts[Field.CURRENCY_B]
  ) {
    error = error ?? 'Enter an amount'
  }

  // const dispatch = useDispatch<AppDispatch>()
  // const environment = useEnvironment()
  // const isExpertMode = useIsExpertMode()
  // const [userGasLimit] = useUserLiquidityGasLimit()
  // const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  // const [userFeeTokens] = useUserFeeTokens()
  // const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  // library?.getGasPrice().then(async (value: BigNumber) => {
  //   if (chainId && tokenA && tokenB && value) {
  //     const gasLimit = isExpertMode
  //       ? userGasLimit
  //       : getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_REMOVE)
  //
  //     const txnFee = value.mul(gasLimit)
  //
  //     let decimals: BigNumber = BigNumber.from(0)
  //     let feeCurrency: Token | Currency | undefined = undefined
  //     let feeCurrencyId: string = ''
  //     if (!userUseCustomFeeToken && pair && pair.liquidityToken.address) {
  //       // Check if the pair contract addresss is exist on coingecko
  //       try {
  //         const response = await fetchPrice(
  //           chainId!,
  //           pair.liquidityToken.address.toLowerCase(),
  //           'usd',
  //           environment
  //         )
  //         const data = await response.json()
  //
  //         if (data) {
  //           feeCurrency = pair.liquidityToken
  //           feeCurrencyId = pair.liquidityToken.address.toLowerCase()
  //           decimals = BigNumber.from(pair.liquidityToken.decimals)
  //         }
  //       } catch (e) {
  //         feeCurrency = tokenA
  //         feeCurrencyId = tokenA.address
  //         decimals = BigNumber.from(tokenA.decimals)
  //         console.info(
  //           'The corresponding LP token cannot be used as a fee token due to unavailable price data. Fee token will revert to input token.',
  //           e
  //         )
  //       }
  //     } else if (userUseCustomFeeToken && userFeeCurrency) {
  //       feeCurrency = userFeeCurrency
  //       feeCurrencyId = userFeeCurrency.wrapped.address
  //       decimals = BigNumber.from(userFeeCurrency.decimals)
  //     } else {
  //       feeCurrency = tokenA
  //       feeCurrencyId = tokenA.address
  //       decimals = BigNumber.from(tokenA.decimals)
  //     }
  //
  //     let maxTokenFee = BigNumber.from(0)
  //     switch (chainId) {
  //       case ChainId.MATIC:
  //       case ChainId.MOONRIVER:
  //         maxTokenFee = await calculateFeeThenConvert(
  //           chainId,
  //           feeCurrencyId,
  //           decimals,
  //           txnFee,
  //           environment
  //         )
  //         break
  //       case ChainId.BSC:
  //         maxTokenFee = await calculateFee(
  //           chainId,
  //           feeCurrencyId,
  //           decimals,
  //           txnFee,
  //           'bnb',
  //           NATIVE[ChainId.BSC].decimals,
  //           environment
  //         )
  //         break
  //       case ChainId.ARBITRUM:
  //         maxTokenFee = await calculateFee(
  //           chainId,
  //           feeCurrencyId,
  //           decimals,
  //           txnFee,
  //           'eth',
  //           NATIVE[ChainId.ARBITRUM].decimals,
  //           environment
  //         )
  //         break
  //     }
  //
  //     dispatch(removeLiquidityFeeInTokens(maxTokenFee.toString()))
  //     dispatch(feeTokenSymbol(feeCurrency?.symbol))
  //   }
  // })

  return { error, pair, parsedAmounts }
}

export function useBurnActionHandlers(): {
  onUserInput: (field: Field, typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  return {
    onUserInput
  }
}
