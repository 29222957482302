import { createAction } from '@reduxjs/toolkit'

export const isFetching = createAction<boolean>('fee/isFetching')
export const feeTokenSymbol = createAction<string | undefined>('fee/feeTokenSymbol')
export const swapFeeInTokens = createAction<string | undefined>('fee/swapFeeInTokens')
export const addLiquitityFeeInTokens = createAction<string | undefined>(
  'fee/addLiquitityFeeInTokens'
)
export const removeLiquidityFeeInTokens = createAction<string | undefined>(
  'fee/removeLiquidityFeeInTokens'
)
