// import { BigNumber } from '@ethersproject/bignumber'
import { ChainId, Environment } from '@xatadev/sdk'
// import { BigNumber as JSBigNumber } from 'bignumber.js'

// import commonCurrencies from './commonCurrencies'

// const PRICE_API_BASEURL: { [env in Environment]: string } = {
//   [Environment.STAGING]:
//     'https://8e9e-2406-da18-c6c-300-d339-7160-2052-7937.ngrok.io/price?network=',
//   [Environment.PRODUCTION]: 'https://xata-coingecko.automata.team/price?network='
// }

// type PriceAPIPrefixMap = {
//   [env in Environment]: {
//     [chainId in ChainId]?: string
//   }
// }

// export const PRICE_API_PREFIX: PriceAPIPrefixMap = {
//   [Environment.STAGING]: {
//     [ChainId.BSC]: `${PRICE_API_BASEURL[Environment.STAGING]}binance-smart-chain&`,
//     [ChainId.MATIC]: `${PRICE_API_BASEURL[Environment.STAGING]}polygon-pos&`,
//     [ChainId.MAINNET]: `${PRICE_API_BASEURL[Environment.STAGING]}ethereum&`,
//     [ChainId.MOONRIVER]: `${PRICE_API_BASEURL[Environment.STAGING]}moonriver&`,
//     [ChainId.ARBITRUM]: `${PRICE_API_BASEURL[Environment.STAGING]}arbitrum-one&`
//   },
//   [Environment.PRODUCTION]: {
//     [ChainId.BSC]: `${PRICE_API_BASEURL[Environment.PRODUCTION]}binance-smart-chain&`,
//     [ChainId.MATIC]: `${PRICE_API_BASEURL[Environment.PRODUCTION]}polygon-pos&`,
//     [ChainId.MAINNET]: `${PRICE_API_BASEURL[Environment.PRODUCTION]}ethereum&`,
//     [ChainId.MOONRIVER]: `${PRICE_API_BASEURL[Environment.PRODUCTION]}moonriver&`,
//     [ChainId.ARBITRUM]: `${PRICE_API_BASEURL[Environment.PRODUCTION]}arbitrum-one&`
//   }
// }

export const LIQUIDITY_EARN_PERCENT: { [chainId in ChainId]?: string } = {
  [ChainId.BSC_TESTNET]: '0.17',
  [ChainId.BSC]: '0.17',
  [ChainId.MATIC]: '0.25',
  [ChainId.MAINNET]: '0.3'
}

export const LIQUIDITY_PROVIDER_FEE: { [chainId in ChainId]?: string } = {
  [ChainId.BSC_TESTNET]: '0.25',
  [ChainId.BSC]: '0.25',
  [ChainId.MATIC]: '0.3',
  [ChainId.MAINNET]: '0.3'
}

/**
 * @obsolete
 * All gas limit constants have been moved to @xata/sdk
 * @see /src/hooks/useGasLimit.ts
 */
const GAS_LIMIT = {
  [Environment.PRODUCTION]: {
    swap: 512_500,
    hop: 175_000,
    addLiquidity: 662_500,
    createPair: 5_125_000
  },
  [Environment.STAGING]: {
    swap: 512_500,
    hop: 175_000,
    addLiquidity: 662_500,
    createPair: 9_900_000
  }
}
console.debug(GAS_LIMIT)

// export const SWAP_GAS_LIMIT = 410000
// export const ADD_LIQUIDITY_GAS_LIMIT = 530000
// export const CREATE_PAIR_GAS_LIMIT = 4100000
// export const HOP_ADDITIONAL_GAS = 140000

// async function calculateFee(
//   chainId: ChainId,
//   address: string,
//   decimals: number,
//   nativeTokenAmount: BigNumber,
//   baseCurrency: string,
//   baseCurrencyDecimal: number
// ): Promise<JSBigNumber> {
//   const priceApiPrefix = PRICE_API_PREFIX[chainId]
//   if (priceApiPrefix === undefined) {
//     throw Error('Unable to calculate fee')
//   }
//
//   const response = await fetch(
//     priceApiPrefix + 'tokens=' + address + '&base=' + baseCurrency
//   )
//   const responseMap = await response.json()
//   const data = responseMap[address.toLowerCase()]
//   let baseRatio
//   if (baseCurrency === 'bnb') {
//     const { bnb } = data
//     baseRatio = bnb
//   } else if (baseCurrency === 'eth') {
//     const { eth } = data
//     baseRatio = eth
//   }
//   const price = new JSBigNumber(baseRatio)
//     .multipliedBy(new JSBigNumber(10).pow(baseCurrencyDecimal))
//     .div(new JSBigNumber(10).pow(decimals))
//   // const priceBNB = parseFloat(price_BNB) * Math.pow(10, 18) / Math.pow(10, decimals)
//
//   // fee in token
//   return new JSBigNumber(nativeTokenAmount.toString()).div(price)
// }

// async function calculateFeeOnPolygon(
//   chainId: ChainId,
//   address: string,
//   decimals: number,
//   nativeTokenAmount: BigNumber
// ): Promise<JSBigNumber> {
//   const priceApiPrefix = PRICE_API_PREFIX[chainId]
//   if (priceApiPrefix === undefined) {
//     throw Error('Unable to calculate fee')
//   }
//
//   const response = await fetch(priceApiPrefix + 'tokens=' + address + '&base=bnb', {
//     mode: 'cors'
//   })
//   const responseMap = await response.json()
//   const data = responseMap[address.toLowerCase()]
//   const { bnb } = data
//   const priceBNB = new JSBigNumber(bnb)
//     .multipliedBy(new JSBigNumber(10).pow(18))
//     .div(new JSBigNumber(10).pow(decimals))
//
//   const maticBnbRatioApi =
//     'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=bnb'
//   const maticResponse = await fetch(maticBnbRatioApi)
//   const maticResponseMap = await maticResponse.json()
//   const maticData = maticResponseMap['matic-network']
//   const maticBnb = maticData['bnb']
//   const maticBnbPrice = new JSBigNumber(maticBnb)
//
//   // const priceBNB = parseFloat(price_BNB) * Math.pow(10, 18) / Math.pow(10, decimals)
//
//   // fee in token
//   return new JSBigNumber(nativeTokenAmount.toString()).div(priceBNB.div(maticBnbPrice))
// }

// export async function calculateFeeOnToken(
//   chainId: ChainId | undefined,
//   address: string | undefined,
//   decimals: number,
//   nativeTokenAmount: BigNumber | undefined
// ): Promise<JSBigNumber> {
//   if (chainId === undefined) {
//     throw Error('Please connect to networks')
//   }
//   if (nativeTokenAmount === undefined) {
//     throw Error('Fee on native token unknown')
//   }
//   if (address === undefined) {
//     throw Error('Token address unknown')
//   }
//
//   if (chainId === ChainId.BSC_TESTNET) {
//     return new JSBigNumber(0.1).multipliedBy(new JSBigNumber(10).pow(decimals))
//   } else if (chainId === ChainId.BSC) {
//     return await calculateFee(chainId, address, decimals, nativeTokenAmount, 'bnb', 18)
//   } else if (chainId === ChainId.MATIC) {
//     return await calculateFeeOnPolygon(chainId, address, decimals, nativeTokenAmount)
//   } else if (chainId === ChainId.MAINNET) {
//     return await calculateFee(chainId, address, decimals, nativeTokenAmount, 'eth', 18)
//   } else {
//     throw Error('Unsupported Network')
//   }
// }

// export const compareTokenPriceAgainstFiat = async (
//   chainId: ChainId,
//   tokenAddresses: string | string[],
//   fiat: string | undefined = 'usd'
// ): Promise<{
//   [tokenAddress: string]: {
//     rate: number
//     symbol: string
//     code: string
//   }
// }> => {
//   const fiatToCompare = fiat === undefined ? 'usd' : fiat.toLowerCase()
//   if (!(fiatToCompare.toUpperCase() in commonCurrencies)) {
//     throw Error('Unable to compare price due to unsupported fiat currency')
//   }
//
//   const contractAddressesQuery = `tokens=${
//     typeof tokenAddresses === 'string'
//       ? tokenAddresses
//       : tokenAddresses.reduce((accum, address) => {
//           if (accum === '') {
//             return `${address}`
//           }
//
//           return `${accum}${encodeURIComponent(',')}${address}`
//         }, '')
//   }`
//   const fiatQuery = `base=${fiatToCompare}`
//
//   const priceApiPrefix = PRICE_API_PREFIX[chainId]
//   if (priceApiPrefix === undefined) {
//     // bad form of the targeted API
//     throw Error('Unable to compare price due to unsupported network')
//   }
//
//   const response = await fetch(`${priceApiPrefix}${contractAddressesQuery}&${fiatQuery}`)
//   const result = await response.json()
//
//   // result map
//   return Object.keys(await result).reduce((accum, tokenAddress) => {
//     const { symbol, code } = commonCurrencies[fiatToCompare.toUpperCase()]
//     return {
//       ...accum,
//       [tokenAddress]: {
//         rate: result[tokenAddress][fiatToCompare],
//         symbol,
//         code
//       }
//     }
//   }, {})
// }
