import { transparentize } from 'polished'
import styled, { ThemeContext } from 'styled-components'
import React, { useContext, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

import { ReactComponent as ChevronDown } from '../../assets/svg/iconset/chevron-down.svg'

import { TYPE } from 'theme'
import { AutoRow } from 'components/Row'
import { AutoColumn } from 'components/Column'
import { InputWrap, ToolWrap, ToolLabel } from './Common'

type DropdownSelectProps<T> = {
  row?: number
  column?: number
  columnSpan?: number
  label: string
  items: string[]
  activeItem: T
  onSelectedItemChange: (itemIndex: T) => void
}

type SelectMenuFlyoutProps<T> = {
  isMenuShow: boolean
  items: string[]
  activeItem: T
  onItemSelected: (itemIndex: T) => void
}

const SelectWrap = styled(InputWrap)<{
  $isMenuShow: boolean
}>`
  justify-content: space-between;
  cursor: pointer;
  border-radius: ${({ $isMenuShow }) => (!$isMenuShow ? '10px' : '10px 10px 0 0')};
`

const MenuWrap = styled.div<{
  isShow: boolean
}>`
  position: absolute;
  background-color: ${({ theme }) => theme.farms.primer};
  top: 0;
  left: 0;
  transform: translateY(75px);
  min-width: 100%;
  border: 1px solid ${({ theme }) => theme.farms.border1};
  border-top: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 2px 2px 4px ${({ theme }) => transparentize(0.75, theme.farms.shadow)};
  z-index: 10;
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  padding: 8px 0;
`

const ButtonListWrap = styled.div<{
  isActive: boolean
}>`
  width: 100%;
  background-color: ${({ isActive, theme }) => (isActive ? theme.bg2 : 'transparent')};

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  > button {
    color: ${({ theme }) => theme.text1}
    background: none;
    appearance: none;
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
  }
`

const SelectMenuFlyout = <T extends unknown>({
  isMenuShow,
  items,
  activeItem,
  onItemSelected
}: SelectMenuFlyoutProps<T>) => {
  const itemMenuButtons = items.map((item, index) => (
    <AutoRow key={index}>
      <ButtonListWrap isActive={index === activeItem}>
        <button onClick={() => onItemSelected(index as any)}>{item}</button>
      </ButtonListWrap>
    </AutoRow>
  ))

  return (
    <MenuWrap isShow={isMenuShow}>
      <AutoColumn>{itemMenuButtons}</AutoColumn>
    </MenuWrap>
  )
}

const DropDownSelect = <T extends unknown>({
  row,
  column,
  columnSpan,
  label,
  items,
  activeItem,
  onSelectedItemChange
}: DropdownSelectProps<T>) => {
  const theme = useContext(ThemeContext)
  const selectRef = useRef<HTMLDivElement>()

  const [isMenuShow, setIsMenuShow] = useState<boolean>(false)

  useOnClickOutside(selectRef, () => {
    if (isMenuShow === false) return
    setIsMenuShow(false)
  })

  const handleItemSelected = (itemIndex: any) => {
    setIsMenuShow(false)

    if (itemIndex === activeItem) return
    onSelectedItemChange(itemIndex)
  }

  return (
    <ToolWrap row={row} column={column} columnSpan={columnSpan} ref={selectRef as any}>
      <ToolLabel>{label}:</ToolLabel>

      <SelectWrap $isMenuShow={isMenuShow} onClick={() => setIsMenuShow(!isMenuShow)}>
        <TYPE.body color={theme.farms.textSub}>{items[activeItem as number] || null}</TYPE.body>
        <ChevronDown fill={theme.farms.textSub} width={16} height={16} />
      </SelectWrap>

      <SelectMenuFlyout<T>
        isMenuShow={isMenuShow}
        items={items}
        activeItem={activeItem}
        onItemSelected={handleItemSelected}
      />
    </ToolWrap>
  )
}

export default DropDownSelect
