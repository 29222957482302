import { Currency, Percent, Price } from '@xatadev/sdk'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { LightCard } from '../../components/Card'

const CardColumn = styled(AutoColumn)`
  flex: 1 0 auto;
`

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price<Currency, Currency>
}) {
  const theme = useContext(ThemeContext)
  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px" width={'auto'} flex={'1 0 auto'}>
        <CardColumn justify="center">
          <LightCard
            padding="18px 13px"
            borderRadius={'15px'}
            textAlign={'center'}
            border={`1px solid ${theme.bg3} !important`}
          >
            <TYPE.black fontSize={18}>{price?.toSignificant(6) ?? '-'}</TYPE.black>
            <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
              {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
            </Text>
          </LightCard>
        </CardColumn>

        <CardColumn justify="center">
          <LightCard
            padding="18px 13px"
            borderRadius={'15px'}
            textAlign={'center'}
            border={`1px solid ${theme.bg3} !important`}
          >
            <TYPE.black fontSize={18}>{price?.invert()?.toSignificant(6) ?? '-'}</TYPE.black>
            <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
              {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
            </Text>
          </LightCard>
        </CardColumn>

        <CardColumn justify="center">
          <LightCard
            padding="18px 13px"
            borderRadius={'15px'}
            textAlign={'center'}
            border={`1px solid ${theme.bg3} !important`}
          >
            <TYPE.black fontSize={18}>
              {noLiquidity && price
                ? '100'
                : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
              %
            </TYPE.black>
            <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
              Share of Pool
            </Text>
          </LightCard>
        </CardColumn>
      </AutoRow>
    </AutoColumn>
  )
}
