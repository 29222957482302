import { commify } from '@ethersproject/units'
import { Currency, CurrencyAmount, Pair, Token } from '@xatadev/sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { QuestionHelperV2 } from 'components/QuestionHelper'
// import { compareTokenPriceAgainstFiat, PRICE_API_PREFIX } from 'constants/price/price'
import { usePair } from 'data/Reserves'
import { RewardPool } from 'entities/RewardPool'
import { useActiveWeb3React } from 'hooks'
import { useAllTokens, useCurrency } from 'hooks/Tokens'
import { useWindowSize } from 'hooks/useWindowSize'
import isEmpty from 'lodash/isEmpty'
import { transparentize } from 'polished'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
// import { useTokenPrices } from 'state/farms/hooks'
import { WrappedTokenInfo } from 'state/lists/hooks'
// import { PriceOfToken } from 'state/tokenPrices/actions'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from 'theme'

import { ReactComponent as ChevronDown } from '../../assets/svg/iconset/chevron-down.svg'
import { ReactComponent as ClockOutline } from '../../assets/svg/iconset/clock-outline.svg'
import { ReactComponent as InfoOutline } from '../../assets/svg/iconset/info-outline.svg'
import { PoolModalData } from '../../pages/Farms/RewardPools'
import { useBlockNumber, useWalletModalToggle } from '../../state/application/hooks'
import { ButtonPrimary, ButtonSecondary } from '../Button'
import { AutoColumn } from '../Column'
import { CardNoise } from '../earn/styled'
import DoubleLogo from '../FarmsPoolCard/DoubleLogo'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { TxAction } from '.'
import TooltipContent, { TooltipCountdownContent } from './TooltipContent'

const { ROUND_DOWN } = BigNumberJS

const Container = styled.div``

const ClickableCardHeader = styled.div`
  padding: 1rem 6.25rem 1rem 1.8125rem;
  background: ${({ theme }) =>
    !theme.isDarkMode
      ? `linear-gradient(180deg, ${theme.bg1} 0%, ${theme.bg3} 100%)`
      : `linear-gradient(180deg, ${theme.bg3} 0%, ${theme.bg1} 100%)`};
  border: 1px solid ${({ theme }) => theme.farms.border1};
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 4px ${({ theme }) => transparentize(0.75, theme.farms.shadow)};
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 2.725rem 1rem 1rem;
  `}
`

const ExpandableCardDetail = styled.div`
  background-color: ${({ theme }) => theme.farms.primer};
  position: relative;
  z-index: 1;
  padding: 1.9375rem 1.8125rem 1.1875rem;
  margin-top: -12px;
  border-radius: 0 0 12px 12px;
  border: 1px solid ${({ theme }) => theme.farms.border1};
  box-shadow: 2px 2px 4px ${({ theme }) => transparentize(0.75, theme.farms.shadow)};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.625rem 1rem 0.895rem;
  `}
`

const InfoWrap = styled(AutoRow)`
  position: relative;
  z-index: 2;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(7, 1fr);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: repeat(4, 1fr);
  `};

  @media (min-width: 721px) and (max-width: 890px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const PairInfo = styled(RowFixed)<{
  $gridColumn?: number | string
}>`
  width: auto;
  ${({ $gridColumn }) => $gridColumn && `grid-column: ${$gridColumn as string};`}
`

const PoolInfo = styled.div<{
  $gridColumn?: number | string
}>`
  width: auto;
  grid-column: ${({ $gridColumn }) => ($gridColumn as string) ?? 'auto'};
`

const PairSymbol = styled(TYPE.mediumHeader)`
  font-weight: 400;
  color: ${({ theme }) => theme.farms.textMain};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const AccordionState = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 1.8125rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  > div {
    margin-right: 4px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    right: 1.25rem;
  `}
`

const DetailBox = styled(AutoColumn)`
  border: 1px solid ${({ theme }) => theme.farms.border1};
  border-radius: 10px;
  padding: 1rem;
  flex: 1 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    // margin: 12px !important;
    // flex: 1 0 auto;
    width: 100%
  `}
`

// eslint-disable-next-line max-len
// const useFetchTokenPrice = (chainId: ChainId | undefined): ((tokenAddresses: string[]) => void) => {
//   const [tokenPrices, addNewPrices] = useTokenPrices(chainId, 'usd')
//   // console.log('token:prices', tokenPrices)
//
//   const fetchTokenPrices = useCallback(
//     (tokenAddresses: string[]) => {
//       // console.log('token:addresses', tokenAddresses)
//       if (chainId) {
//         const fetchPrices = async (addressesToFetch: string[]) =>
//           await compareTokenPriceAgainstFiat(chainId, addressesToFetch, 'usd')
//
//         if (
//           Array.isArray(tokenAddresses) &&
//           !isEmpty(tokenAddresses) &&
//           tokenAddresses.every(token => typeof token === 'string')
//         ) {
//           const addresses = tokenAddresses
//             .map(address => address.toLowerCase())
//             .filter(address => !tokenPrices[address])
//
//           if (!isEmpty(addresses)) {
//             // console.log('token:filtered', addresses)
//             fetchPrices(addresses)
//               .then(prices => {
//                 if (!prices || isEmpty(prices)) return
//                 const priceRates = addresses.reduce<PriceOfToken>(
//                   (obj, address) => ({
//                     ...obj,
//                     [address]: prices[address].rate
//                   }),
//                   {}
//                 )
//                 addNewPrices(priceRates)
//               })
//               .catch(error => {
//                 console.error(error)
//               })
//           }
//         }
//       }
//     },
//     [addNewPrices, chainId, tokenPrices]
//   )
//
//   return fetchTokenPrices
// }

type Props = {
  singleReward?: boolean
  multiRewards?: boolean
  pool: RewardPool
  onPoolCalculated?: (
    poolAddress: string,
    calculatedResult: { apr: BigNumberJS; totalInFarm: BigNumberJS }
  ) => void
  onActionClicked?: (
    actionToCall: TxAction,
    contractAddress: string,
    pairToTrade: Pair | null,
    reward?: {
      amount: string
      symbol: string
      decimals: number
      logoURI: string
    }[],
    userStakedAmount?: CurrencyAmount<Currency>,
    pool?: any
  ) => void
  onPoolDetailsClicked?: (data: PoolModalData) => void
}

export const PositionCard = ({
  singleReward,
  multiRewards,
  pool,
  onActionClicked,
  onPoolDetailsClicked
}: Props) => {
  // States
  const [expanded, setExpanded] = useState<boolean>()
  // const [pool, setPool] = useState<RewardPool>()

  // Hooks
  const { account /*, chainId , library*/ } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  // const latestBlockNumber = useBlockNumber()

  const theme = useContext(ThemeContext)

  // const fetchTokenPrices = useFetchTokenPrice(chainId)
  // console.debug(fetchTokenPrices)

  // const [tokenPrices] = useTokenPrices(chainId, 'usd')

  const [currencyA, currencyB] = [
    (useCurrency(pool.tokenA.address) as WrappedTokenInfo) ?? undefined,
    (useCurrency(pool.tokenB.address) as WrappedTokenInfo) ?? undefined
  ]

  // initiate pool instance and define some basic dependencies
  const { stakingToken } = useMemo(() => {
    // console.log('tif', pool.totalInFarm.toString())
    // console.log('sup', pool.totalSupply.toString())
    return {
      stakingToken: pool.stakingToken
    }
  }, [pool])

  const {
    isActive,
    periodFinish,
    rewardRate,
    rewardTokens,
    rewardsApr,
    tokenPrices,
    totalSupply,
    userEarnings,
    userStakedAmount
  } = pool ?? {
    isActive: undefined,
    periodFinish: undefined,
    rewardRate: undefined,
    rewardTokens: undefined,
    rewardsApr: undefined,
    tokenPrices: undefined,
    totalSupply: undefined,
    userEarnings: new BigNumberJS(0),
    userStakedAmount: new BigNumberJS(0)
  }

  const blocknumber = useBlockNumber()

  // pair
  const [, pair] = usePair(currencyA, currencyB)

  // pool has active rewards tokens
  const poolHasActiveRewards: boolean = useMemo(() => {
    if (!isActive || isEmpty(isActive)) return false

    return Object.values(isActive).some(status => status)
  }, [isActive])

  // tokens symbols
  const allTokens = useAllTokens()
  const rewardTokensSymbols = useMemo(() => {
    if (!rewardTokens || isEmpty(allTokens)) return []

    return rewardTokens.reduce<string[]>((memo, { address }) => {
      memo.push((allTokens[address] as WrappedTokenInfo)?.logoURI ?? '')
      return memo
    }, [])
  }, [allTokens, rewardTokens])

  // total in farm
  const [totalInFarmLabel, setTotalInFarmLabel] = useState<string>('$0.00')

  // useEffect(() => {
  //   setTotalInFarmLabel('$' + commify(pool.totalInFarm.toFixed(2, ROUND_DOWN)))
  // }, [pool.totalInFarm])

  const generateTotalInFarmLabel = useCallback(
    async (refetch: boolean = false) => {
      if (refetch) {
        pool.updateTotalInFarm()
      }
      setTotalInFarmLabel('$' + commify(pool.totalInFarm.toFixed(2, ROUND_DOWN)))
    },
    [pool]
  )

  // apr
  type AprState = {
    aprPerToken: string[]
    totalAprLabel: string
  }

  const [{ aprPerToken, totalAprLabel }, setApr] = useState<AprState>({
    aprPerToken: [],
    totalAprLabel: '0.00%'
  })

  useEffect(() => {
    if (!rewardTokens || !rewardRate || !totalSupply || !rewardsApr) return

    if (isEmpty(rewardTokens) || isEmpty(rewardRate) || isEmpty(rewardsApr)) return

    const aprAccumulation = Object.keys(rewardsApr).reduce<BigNumberJS>((accum, tokenAddress) => {
      return accum.plus(rewardsApr[tokenAddress])
    }, new BigNumberJS(0))

    let totalAprLabel: string
    if (aprAccumulation.isFinite() && aprAccumulation.lte(9999.99)) {
      totalAprLabel = commify(aprAccumulation.toFixed(2, ROUND_DOWN)) + '%'
    } else if (
      (aprAccumulation.isFinite() && aprAccumulation.gt(9999.99)) ||
      (!aprAccumulation.isFinite() && !aprAccumulation.isNaN())
    ) {
      totalAprLabel = '>' + commify('9999.99') + '%'
    } else {
      totalAprLabel = '0.00%'
    }

    const aprPerToken = Object.values(rewardsApr).map(apr => apr.toFixed(2, ROUND_DOWN))

    setApr({
      aprPerToken,
      totalAprLabel
    })
  }, [rewardRate, rewardTokens, rewardsApr, totalSupply])

  // rewards per day
  const [rewardsPerDayLabel, setRewardsPerDayLabel] = useState<string>('Loading...')

  const generateRewardsPerDayLabel = useCallback(() => {
    if (!rewardTokens || !rewardRate) return
    if (isEmpty(rewardTokens) || isEmpty(rewardRate)) return

    const secondsInDay = new BigNumberJS(60 * 60 * 24)
    let label = ''
    for (let i = 0; i < rewardTokens.length; i += 1) {
      const rewardAddress = rewardTokens[i].address
      const rewardSymbol = rewardTokens[i].symbol
      const rewardIsActive = isActive?.[rewardAddress]
      const rewardPerDay = rewardIsActive
        ? (rewardRate?.[rewardAddress] ?? new BigNumberJS(0))
            .multipliedBy(secondsInDay)
            .div(10 ** 18)
        : new BigNumberJS(0)

      if (label.length) {
        label += ' + '
      }
      label += `${rewardPerDay.toFixed(2, ROUND_DOWN)} ${rewardSymbol}`
    }

    setRewardsPerDayLabel(label)
  }, [isActive, rewardRate, rewardTokens])

  // user staked amount
  type StakedAmountState = {
    stakedIsZero: boolean
    stakedLabel: string
  }

  const [{ stakedIsZero, stakedLabel }, setStakedAmount] = useState<StakedAmountState>({
    stakedIsZero: true,
    stakedLabel: '0.00000'
  })

  useEffect(() => {
    const { decimals } = stakingToken
    const stakedIsZero = userStakedAmount.isEqualTo(0)
    const stakedLabel = userStakedAmount.div(10 ** decimals).toFixed(5, ROUND_DOWN)

    setStakedAmount({
      stakedIsZero,
      stakedLabel
    })
  }, [stakingToken, userStakedAmount])

  // user earnings
  type EarningsState = {
    earningsPerToken: string[]
    earningsIsZero: boolean
    earningsLabel: string
  }

  const [{ earningsPerToken, earningsIsZero, earningsLabel }, setEarnings] = useState<
    EarningsState
  >({
    earningsIsZero: true,
    earningsLabel: '0.00',
    earningsPerToken: []
  })

  useEffect(() => {
    if (!tokenPrices || !rewardTokens || isEmpty(rewardTokens)) return

    const totalEarnings = userEarnings.reduce<BigNumberJS>((total, amount, i) => {
      const { decimals, address } = rewardTokens[i]
      const rewardTokenPrice = tokenPrices[address.toLowerCase()]
      const earning = amount.div(10 ** decimals)

      return total.plus(earning.multipliedBy(rewardTokenPrice))
    }, new BigNumberJS(0))

    const earningsIsZero = totalEarnings.isEqualTo(0)
    const earningsLabel = !totalEarnings.isNaN()
      ? commify(totalEarnings.toFixed(2, ROUND_DOWN))
      : '$0.00'
    const earningsPerToken = userEarnings.map((earning, i) =>
      earning.div(10 ** rewardTokens[i].decimals).toFixed(5, ROUND_DOWN)
    )

    setEarnings({
      earningsIsZero,
      earningsLabel,
      earningsPerToken
    })
  }, [rewardTokens, tokenPrices, userEarnings])

  // deadline periods
  const periodPerToken = useMemo(() => {
    if (!periodFinish || isEmpty(periodFinish)) return []

    return Object.entries(periodFinish).reduce<number[]>((memo, [, period]) => {
      memo.push(period)
      return memo
    }, [])
  }, [periodFinish])

  // Update view for every block number update

  useEffect(() => {
    if (!blocknumber) return

    generateTotalInFarmLabel(true)
    generateRewardsPerDayLabel()
  }, [generateRewardsPerDayLabel, blocknumber, generateTotalInFarmLabel])

  const { width: windowWidth } = useWindowSize()

  return (
    <Container>
      <ClickableCardHeader onClick={() => setExpanded(!expanded)}>
        <CardNoise />

        <AccordionState>
          {windowWidth && windowWidth > 960 && (
            <TYPE.subHeader color={theme.primary1}>{!expanded ? 'Open' : 'Close'}</TYPE.subHeader>
          )}
          <ChevronDown
            fill={theme.primary1}
            width={16}
            height={16}
            style={{
              transform: `rotate(${!expanded ? 0 : 180}deg)`,
              transition: 'transform 0.08s ease-out'
            }}
          />
        </AccordionState>

        <InfoWrap>
          <PairInfo $gridColumn={'1 / span 2'}>
            <DoubleLogo tokens={[pool.tokenA, pool.tokenB]} />

            <PairSymbol
              title={`${pool.tokenA.symbol}/${pool.tokenB.symbol}`}
            >{`${pool.tokenA.symbol}/${pool.tokenB.symbol}`}</PairSymbol>
          </PairInfo>

          <PoolInfo $gridColumn={3}>
            <TYPE.small
              fontSize={12}
              color={theme.farms.textSub}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '4px',
                height: '20px'
              }}
            >
              Total in Farm:
            </TYPE.small>
            <TYPE.body color={theme.farms.textMain} fontWeight={500}>
              {totalInFarmLabel}
            </TYPE.body>
          </PoolInfo>

          <PoolInfo $gridColumn={4}>
            <TYPE.small
              fontSize={12}
              color={theme.farms.textSub}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '4px',
                height: '20px'
              }}
            >
              {singleReward ? 'APR:' : multiRewards ? 'Total APR:' : null}
              {multiRewards && (
                <QuestionHelperV2
                  content={
                    <TooltipContent
                      tokenLogos={rewardTokensSymbols}
                      labels={rewardTokens?.map(token => `${token.symbol} (APR):`) ?? []}
                      values={aprPerToken.map(apr => `${apr}%`)}
                    />
                  }
                  icon={<InfoOutline fill={theme.primary1} width={14} height={14} />}
                />
              )}
            </TYPE.small>
            <TYPE.body color={theme.farms.textMain} fontWeight={500}>
              {totalAprLabel}
            </TYPE.body>
          </PoolInfo>

          {windowWidth && windowWidth > 720 && (
            <PoolInfo
              $gridColumn={
                windowWidth && windowWidth > 720 && windowWidth <= 890 ? '5 / span 2' : '5 / span 3'
              }
            >
              <AutoRow>
                {windowWidth && windowWidth > 890 && (
                  <AutoColumn>
                    <DoubleLogo tokens={rewardTokens as Token[]} />
                  </AutoColumn>
                )}
                <AutoColumn>
                  <TYPE.small
                    fontSize={12}
                    color={theme.farms.textSub}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '4px',
                      height: '20px'
                    }}
                  >
                    Rewards / Day:
                    {multiRewards && (
                      <QuestionHelperV2
                        content={
                          <TooltipCountdownContent
                            tokenLogos={rewardTokensSymbols}
                            labels={rewardTokens?.map(token => `${token.symbol}:`) ?? []}
                            values={periodPerToken}
                          />
                        }
                        icon={<ClockOutline fill={theme.primary1} width={14} height={14} />}
                      />
                    )}
                  </TYPE.small>
                  <TYPE.body color={theme.farms.textMain} fontWeight={500}>
                    {rewardsPerDayLabel}
                  </TYPE.body>
                </AutoColumn>
              </AutoRow>
            </PoolInfo>
          )}
        </InfoWrap>
      </ClickableCardHeader>

      {expanded && (
        <ExpandableCardDetail>
          <AutoRow
            gap={windowWidth && windowWidth <= 720 ? '0' : '12px'}
            justifyContent={'center !important'}
            flexDirection={windowWidth && windowWidth <= 640 ? 'column' : undefined}
            style={{
              rowGap: windowWidth && windowWidth <= 640 ? '1rem' : undefined
            }}
          >
            <DetailBox>
              <AutoRow flexDirection={windowWidth && windowWidth <= 640 ? 'column' : undefined}>
                <AutoColumn>
                  <TYPE.subHeader color={theme.farms.textSub}>
                    {multiRewards ? 'Rewards Earned:' : null}
                  </TYPE.subHeader>
                  <AutoRow style={{ marginTop: '4px' }}>
                    <TYPE.mediumHeader
                      color={theme.farms.textMain}
                      fontWeight={400}
                      marginRight={'2px'}
                    >
                      ${earningsLabel}
                    </TYPE.mediumHeader>

                    {multiRewards && (
                      <QuestionHelperV2
                        content={
                          <TooltipContent
                            tokenLogos={rewardTokensSymbols}
                            labels={rewardTokens?.map(token => `${token.symbol}:`) ?? []}
                            values={earningsPerToken.map((earning, i) => {
                              const usdValue = new BigNumberJS(earning ?? 0).multipliedBy(
                                !rewardTokens
                                  ? 1
                                  : tokenPrices[rewardTokens[i].address.toLowerCase()]
                              )

                              return `${earning} ($${
                                !usdValue.isNaN()
                                  ? commify(usdValue.toFixed(2, ROUND_DOWN))
                                  : '0.00'
                              })`
                            })}
                          />
                        }
                        icon={<InfoOutline fill={theme.primary1} width={14} height={14} />}
                      />
                    )}
                  </AutoRow>
                </AutoColumn>

                <RowBetween
                  marginLeft={windowWidth && windowWidth <= 720 ? 'auto' : 35}
                  marginRight={windowWidth && windowWidth <= 640 ? 'auto' : undefined}
                  flexDirection={windowWidth && windowWidth <= 640 ? 'column' : undefined}
                  flex={'1 0 auto'}
                  style={{
                    columnGap: '1rem',
                    width: windowWidth && windowWidth <= 640 ? '100%' : 'auto'
                  }}
                >
                  <ButtonPrimary
                    padding={'8px 14px'}
                    borderRadius="5px"
                    backgroundColor={theme.farms.buttonSolid}
                    width={windowWidth && windowWidth <= 640 ? '100%' : 'auto'}
                    margin={windowWidth && windowWidth <= 640 ? '12px 0' : 0}
                    flexGrow={1}
                    onClick={() =>
                      onActionClicked &&
                      pool?.address &&
                      onActionClicked(
                        TxAction.HARVEST,
                        pool.address,
                        pair,
                        rewardTokens?.map((token, i) => ({
                          amount: earningsPerToken[i] ?? '0.00000',
                          symbol: token.symbol,
                          decimals: token.decimals,
                          logoURI: rewardTokensSymbols[i]
                        })),
                        undefined,
                        pool
                      )
                    }
                    disabled={!account || earningsIsZero}
                  >
                    <Text color={theme.farms.buttonSolidLabel} fontWeight={400} fontSize={16}>
                      Harvest
                    </Text>
                  </ButtonPrimary>
                  <ButtonSecondary
                    padding={'8px 14px'}
                    borderRadius="5px"
                    width={windowWidth && windowWidth <= 640 ? '100%' : 'auto'}
                    borderColor={theme.farms.buttonOutlineBorder}
                    flexGrow={1}
                    onClick={() =>
                      onActionClicked &&
                      pool?.address &&
                      onActionClicked(
                        TxAction.EXIT,
                        pool.address,
                        pair,
                        rewardTokens?.map((token, i) => ({
                          amount: earningsPerToken[i] ?? '0.00000',
                          symbol: token.symbol,
                          decimals: token.decimals,
                          logoURI: rewardTokensSymbols[i]
                        })),
                        undefined,
                        pool
                      )
                    }
                    disabled={!account || earningsIsZero}
                  >
                    <Text color={theme.farms.buttonOutlineLabel} fontWeight={400} fontSize={16}>
                      Unstake All and Harvest
                    </Text>
                  </ButtonSecondary>
                </RowBetween>
              </AutoRow>
            </DetailBox>

            <DetailBox>
              <AutoRow flexDirection={windowWidth && windowWidth <= 640 ? 'column' : undefined}>
                <AutoColumn>
                  <TYPE.subHeader color={theme.farms.textSub}>
                    {`${pool.tokenA.symbol}/${pool.tokenB.symbol}`} Staked:
                  </TYPE.subHeader>
                  <TYPE.mediumHeader
                    color={theme.farms.textMain}
                    fontWeight={400}
                    style={{ marginTop: '4px' }}
                  >
                    {stakedLabel}
                  </TYPE.mediumHeader>
                </AutoColumn>
                <RowBetween
                  marginLeft={windowWidth && windowWidth <= 720 ? 'auto' : 35}
                  marginRight={windowWidth && windowWidth <= 640 ? 'auto' : undefined}
                  flexDirection={windowWidth && windowWidth <= 640 ? 'column' : undefined}
                  flex={'1 0 auto'}
                  style={{
                    columnGap: '1rem',
                    width: windowWidth && windowWidth <= 640 ? '100%' : 'auto'
                  }}
                >
                  {account ? (
                    <>
                      <ButtonPrimary
                        padding={'8px 14px'}
                        borderRadius="5px"
                        backgroundColor={theme.farms.buttonSolid}
                        width={windowWidth && windowWidth <= 640 ? '100%' : 'auto'}
                        margin={windowWidth && windowWidth <= 640 ? '12px 0' : 0}
                        flexGrow={1}
                        onClick={() =>
                          onActionClicked &&
                          pool?.address &&
                          onActionClicked(
                            TxAction.STAKE,
                            pool.address,
                            pair,
                            undefined,
                            undefined,
                            pool
                          )
                        }
                        disabled={!poolHasActiveRewards}
                      >
                        <Text color={theme.farms.buttonSolidLabel} fontWeight={400} fontSize={16}>
                          Stake Tokens
                        </Text>
                      </ButtonPrimary>
                      <ButtonSecondary
                        padding={'8px 14px'}
                        borderRadius="5px"
                        width={windowWidth && windowWidth <= 640 ? '100%' : 'auto'}
                        borderColor={theme.farms.buttonOutlineBorder}
                        flexGrow={1}
                        onClick={() =>
                          onActionClicked &&
                          pool?.address &&
                          onActionClicked(
                            TxAction.UNSTAKE,
                            pool.address,
                            pair,
                            undefined,
                            CurrencyAmount.fromRawAmount(
                              stakingToken,
                              userStakedAmount.toFixed(0, ROUND_DOWN)
                              // .multipliedBy(10 ** 18)
                              // .toFixed(0, ROUND_DOWN)
                            ),
                            pool
                          )
                        }
                        disabled={stakedIsZero}
                      >
                        <Text color={theme.farms.buttonOutlineLabel} fontWeight={400} fontSize={16}>
                          Unstake Tokens
                        </Text>
                      </ButtonSecondary>
                    </>
                  ) : (
                    <ButtonPrimary
                      padding={'8px 14px'}
                      borderRadius="5px"
                      backgroundColor={theme.farms.buttonSolid}
                      flexGrow={1}
                      margin={windowWidth && windowWidth <= 640 ? '12px 0 0' : 0}
                      onClick={toggleWalletModal}
                    >
                      <Text color={theme.farms.buttonSolidLabel} fontWeight={400} fontSize={16}>
                        Connect Wallet
                      </Text>
                    </ButtonPrimary>
                  )}
                </RowBetween>
              </AutoRow>
            </DetailBox>

            {windowWidth && windowWidth <= 720 && (
              <ButtonSecondary
                onClick={() => {
                  if (typeof onPoolDetailsClicked === 'function') {
                    onPoolDetailsClicked({
                      rewardsPerDay: rewardsPerDayLabel,
                      tokenLogos: rewardTokensSymbols,
                      tokenSymbols: rewardTokens?.map(token => `${token.symbol}:`) ?? [],
                      aprPerToken: aprPerToken.map(apr => `${apr}%`),
                      periodPerToken: periodPerToken,
                      earnedRewards: earningsPerToken.map((earning, i) => {
                        const usdValue = new BigNumberJS(earning)
                          .multipliedBy(
                            !rewardTokens ? 1 : tokenPrices[rewardTokens[i].address.toLowerCase()]
                          )
                          .toFixed(2, ROUND_DOWN)

                        return `$${commify(usdValue)} (${earning})`
                      })
                    })
                  }
                }}
              >
                <Text color={theme.farms.buttonOutlineLabel} fontWeight={400} fontSize={16}>
                  Show Reward Pool Details
                </Text>
              </ButtonSecondary>
            )}
          </AutoRow>
        </ExpandableCardDetail>
      )}
    </Container>
  )
}

export default PositionCard
