import 'react-custom-scroll/dist/customScroll.css'

// import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { darken, transparentize } from 'polished'
import React, { useMemo, useRef, useState } from 'react'
import CustomScroll from 'react-custom-scroll'
import { X } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components'

import { useWindowSize } from '../../hooks/useWindowSize'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen /*, useToggleSettingsMenu*/ } from '../../state/application/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import { RowBetween } from '../Row'
import MenuFlyout from './MenuFlyout'

const Container = styled.div`
  width: 211px;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  position: absolute;
  right: -205px;
  display: flex;
  flex-direction: column;
`

const MenuWrapper = styled(AutoColumn)`
  // height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => (theme.isDarkMode ? theme.bg2 : theme.bg3)};
  border-left: 0;
  border-radius: 0 20px 20px 0;
  box-shadow: 4px 4px 20px
    ${({ theme }) =>
      !theme.isDarkMode ? transparentize(0.25, theme.bg4) : transparentize(0.25, theme.bg2)};
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  position: relative;

  // Custom scroll styles
  // --------------------
  .rcs-custom-scroll {
    .rcs-outer-container {
      .rcs-positioning {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 12px 0;
      }
    }

    .rcs-custom-scrollbar {
      padding: 0;
      width: 4px;
      height: calc(100% - 24px);
      background-color: ${({ theme }) => (theme.isDarkMode ? theme.bg3 : darken(0.04, theme.bg3))};
      border-radius: 2px;
      opacity: 1 !important;
      right: 6px;
    }

    .rcs-inner-handle {
      margin-top: 0;
      height: calc(100% - 24px);
      background-color: ${({ theme }) => (theme.isDarkMode ? theme.bg5 : darken(0.1, theme.bg4))};
    }
  }
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const DesktopSettings = () => {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  // const toggle = useToggleSettingsMenu()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const [, toggleExpertMode] = useExpertModeManager()

  const windowSize = useWindowSize()
  const isDesktop = useMemo(() => windowSize.width && windowSize.width > 960, [windowSize.width])

  // useOnClickOutside(node, isDesktop && open ? toggle : undefined)

  return (
    <Container ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <Text fontWeight={500} fontSize={20}>
                Are you sure?
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={20}>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades
                that often result in bad rates and lost funds.
              </Text>
              <Text fontWeight={600} fontSize={20}>
                ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (
                    window.prompt('Please type the word "confirm" to enable expert mode.') ===
                    'confirm'
                  ) {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  Turn On Expert Mode
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>

      <MenuWrapper>
        <CustomScroll flex={1}>
          {isDesktop && open && (
            <MenuFlyout onShowConfirmation={value => setShowConfirmation(value)} isDesktop={true} />
          )}
        </CustomScroll>
      </MenuWrapper>
    </Container>
  )
}

export default DesktopSettings
