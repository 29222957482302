import { parseUnits } from '@ethersproject/units'
import {
  // calculateFee,
  // calculateFeeThenConvert,
  // ChainId,
  /*ChainId,*/
  /*TokenAmount,*/
  Currency,
  CurrencyAmount,
  Ether,
  // HOP_ADDITIONAL_GAS,
  JSBI,
  // NATIVE,
  // SWAP_GAS_LIMIT,
  Token,
  Trade,
  TradeType
} from '@xatadev/sdk'
import { BigNumber as JSBigNumber } from 'bignumber.js'
// import { GTOKEN_MAP } from '../../constants/gtokens/gtokens'
// import { WrappedTokenInfo } from '../../state/lists/hooks'
// import { calculateFeeOnToken, GAS_LIMIT } from '../../constants/price/price'
// import { BigNumber } from 'ethers'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useV1Trade } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useTradeExactIn, useTradeExactOut } from '../../hooks/Trades'
import useENS from '../../hooks/useENS'
// import useEnvironment from '../../hooks/useEnvironment'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { Version } from '../../hooks/useToggledVersion'
import useToggledVersion from '../../hooks/useToggledVersion'
import { isAddress } from '../../utils'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
// import { feeTokenSymbol, swapFeeInTokens } from '../fee/actions'
// import { useGasFee } from '../fee/hooks'
// import { feeTokenSymbol, isFetching as isFetchingFee, swapFeeInTokens } from '../fee/actions'
// import { useGasFee } from '../fee/hooks'
import { AppDispatch, AppState } from '../index'
import {
  // useIsExpertMode,
  // useUserFeeTokens,
  useUserSlippageTolerance
  // useUserSwapGasLimit
  // useUserUseCustomFeeToken
} from '../user/hooks'
import { useCurrencyBalances } from '../wallet/hooks'
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput
} from './actions'
import { SwapState } from './reducer'

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap)
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency === Ether.onChain(currency.chainId)
              ? 'ETH'
              : ''
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(
  value?: string,
  currency?: Currency
): CurrencyAmount<Currency> | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      // : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
      // ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
      return currency instanceof Token
        ? CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.fromRawAmount(
            Ether.onChain(currency.chainId),
            JSBI.BigInt(typedValueParsed)
          )
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D' // v2 router 02
]

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(
  trade: Trade<Currency, Currency, TradeType>,
  checksummedAddress: string
): boolean {
  return (
    trade.route.path.some(token => token.address === checksummedAddress) ||
    trade.route.pairs.some(pair => pair.liquidityToken.address === checksummedAddress)
  )
}

// function useGToken(chainId: ChainId, currencyId: string | undefined): Currency | null | undefined {
//   const gTokenAddress = currencyId ? GTOKEN_MAP[chainId!]!.get(currencyId) : undefined
//   const originalCurrency = useCurrency(currencyId) as WrappedTokenInfo
//   if (gTokenAddress !== undefined && originalCurrency) {
//     const tokenInfo = {
//       chainId: originalCurrency.tokenInfo.chainId,
//       address: gTokenAddress!,
//       name: 'Geode ' + originalCurrency.tokenInfo.name,
//       decimals: originalCurrency.tokenInfo.decimals,
//       symbol: 'g' + originalCurrency.tokenInfo.symbol!,
//       logoURI: originalCurrency.tokenInfo.logoURI!
//     }
//     return new WrappedTokenInfo(tokenInfo, originalCurrency.tags)
//     // return new Token(originalCurrency?.chainId, gTokenAddress!, originalCurrency?.decimals, 'g' + originalCurrency?.symbol, 'Geode ' + originalCurrency?.name)
//   } else {
//     return undefined
//   }
// }

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount: CurrencyAmount<Currency> | undefined
  v2Trade: Trade<Currency, Currency, TradeType> | undefined
  inputError?: string
  v1Trade: Trade<Currency, Currency, TradeType> | undefined
} {
  const { account /*, library, chainId*/ } = useActiveWeb3React()
  // const dispatch = useDispatch<AppDispatch>()

  const toggledVersion = useToggledVersion()

  // const isExpertMode = useIsExpertMode()

  // const environment = useEnvironment()

  // const [userSwapGasLimit] = useUserSwapGasLimit()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const recipientLookup = useENS(recipient ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined
  ])

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? inputCurrency : outputCurrency) ?? undefined
  )
  const bestTradeExactIn = useTradeExactIn(
    isExactIn ? parsedAmount : undefined,
    outputCurrency ?? undefined
  )
  const bestTradeExactOut = useTradeExactOut(
    inputCurrency ?? undefined,
    !isExactIn ? parsedAmount : undefined
  )
  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1]
  }

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined
  }

  // get link to trade on v1, if a better rate exists
  const v1Trade = useV1Trade(
    isExactIn,
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    parsedAmount
  )

  let inputError: string | undefined
  if (!account) {
    inputError = 'Connect Wallet'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token to continue'
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient'
  } else {
    if (
      BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
      (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
      (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
    ) {
      inputError = inputError ?? 'Invalid recipient'
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance()

  const slippageAdjustedAmounts =
    v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage)

  const slippageAdjustedAmountsV1 =
    v1Trade && allowedSlippage && computeSlippageAdjustedAmounts(v1Trade, allowedSlippage)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    toggledVersion === Version.v1
      ? slippageAdjustedAmountsV1
        ? slippageAdjustedAmountsV1[Field.INPUT]
        : null
      : slippageAdjustedAmounts
      ? slippageAdjustedAmounts[Field.INPUT]
      : null
  ]

  const originalCurrencySymbol = amountIn?.currency.symbol?.startsWith('g')
    ? amountIn.currency.symbol.substring(1)
    : amountIn?.currency.symbol
  const swapFeeCurrencySymbol = useSelector<AppState, AppState['fee']['feeTokenSymbol']>(
    state => state.fee.feeTokenSymbol
  )
  const swapFeeStr = useSelector<AppState, AppState['fee']['swapFeeInTokens']>(
    state => state.fee.swapFeeInTokens
  )
  const swapFee =
    swapFeeStr === undefined
      ? undefined
      : currencies[Field.INPUT] === undefined
      ? undefined
      : new JSBigNumber(swapFeeStr).div(
          new JSBigNumber(10).pow(currencies[Field.INPUT]!.decimals).toString()
        )
  if (balanceIn && amountIn) {
    if (balanceIn.lessThan(amountIn)) {
      inputError = 'Insufficient ' + originalCurrencySymbol + ' balance'
    } else if (
      swapFee !== undefined &&
      originalCurrencySymbol === swapFeeCurrencySymbol &&
      swapFee
        .plus(new JSBigNumber(amountIn.toSignificant(amountIn.currency.decimals)))
        .gt(new JSBigNumber(balanceIn.toSignificant(balanceIn.currency.decimals)))
    ) {
      inputError = 'Insufficient ' + originalCurrencySymbol + ' balance for gas fee'
    }
  }

  return {
    currencies,
    currencyBalances,
    inputError,
    parsedAmount,
    v1Trade,
    v2Trade: v2Trade ?? undefined
  }
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    if (urlParam.toUpperCase() === 'ETH') return 'ETH'
    if (valid === false) return ''
  }
  return ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output'
    ? Field.OUTPUT
    : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!chainId) return
    const parsed = queryParametersToSwapState(parsedQs)

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
        recipient: parsed.recipient
      })
    )

    setResult({
      inputCurrencyId: parsed[Field.INPUT].currencyId,
      outputCurrencyId: parsed[Field.OUTPUT].currencyId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}
