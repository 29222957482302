import { BLOCKED_PRICE_IMPACT_NON_EXPERT } from '../constants'
import {
  ChainId,
  Currency,
  CurrencyAmount,
  Fraction,
  JSBI,
  Percent,
  Trade,
  TradeType
  /*TokenAmount, Ether*/
} from '@xatadev/sdk'
import { ALLOWED_PRICE_IMPACT_HIGH, ALLOWED_PRICE_IMPACT_LOW, ALLOWED_PRICE_IMPACT_MEDIUM } from '../constants'
import { Field } from '../state/swap/actions'
import { basisPointsToPercent } from './index'
import { LIQUIDITY_PROVIDER_FEE } from '../constants/price/price'

// computes price breakdown for the trade
export function computeTradePriceBreakdown(
  chainId: ChainId | undefined,
  trade?: Trade<Currency, Currency, TradeType> | null
): {
  priceImpactWithoutFee: Percent | undefined
  realizedLPFee: CurrencyAmount<Currency> | undefined | null
  liquidityProviderFee: string | undefined
} {
  const liquidityProviderFee = LIQUIDITY_PROVIDER_FEE[chainId!] ?? '0.3'
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const providerFee = Number.parseFloat(liquidityProviderFee) * 100
  const BASE_FEE = new Percent(JSBI.BigInt(providerFee), JSBI.BigInt(10000))
  const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000))
  const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>(
          (currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT
        )
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    // (trade.inputAmount instanceof TokenAmount
    //   ? new TokenAmount(trade.inputAmount.token, realizedLPFee.multiply(trade.inputAmount.raw).quotient)
    //   : CurrencyAmount.ether(realizedLPFee.multiply(trade.inputAmount.raw).quotient))
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.numerator).quotient
    )

  return {
    priceImpactWithoutFee: priceImpactWithoutFeePercent,
    realizedLPFee: realizedLPFeeAmount,
    liquidityProviderFee: liquidityProviderFee
  }
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct)
  }
}

export function warningSeverity(priceImpact: Percent | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}

export function formatExecutionPrice(trade?: Trade<Currency, Currency, TradeType>, inverted?: boolean): string {
  if (!trade) {
    return ''
  }
  // We don't use gToken anymore
  // const originalInputTokenSymbol = trade.inputAmount.currency.symbol?.startsWith('g')
  //   ? trade.inputAmount.currency.symbol.substring(1)
  //   : trade.inputAmount.currency.symbol
  // const originalOutputTokenSymbol = trade.outputAmount.currency.symbol?.startsWith('g')
  //   ? trade.outputAmount.currency.symbol.substring(1)
  //   : trade.outputAmount.currency.symbol
  const originalInputTokenSymbol = trade.inputAmount.currency.symbol
  const originalOutputTokenSymbol = trade.outputAmount.currency.symbol

  return inverted
    ? `${trade.executionPrice.invert().toSignificant(6)} ${originalInputTokenSymbol} / ${originalOutputTokenSymbol}`
    : `${trade.executionPrice.toSignificant(6)} ${originalOutputTokenSymbol} / ${originalInputTokenSymbol}`
}
