import { Text } from 'rebass'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

export const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`

type MaxButtonProps = {
  width?: string
  isActive: boolean
}

export const MaxButton = styled.button.attrs((props: MaxButtonProps) => ({
  isActive: props.isActive,
  width: props.width
}))<MaxButtonProps>`
  padding: 0.5rem 1rem;
  background-color: ${({ isActive, theme }) => (!isActive ? theme.primary1 : theme.bg1)};
  border: 1px solid ${({ isActive, theme }) => (!isActive ? theme.primary1 : theme.bg3)};
  border-radius: 5px;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: ${({ isActive }) => (!isActive ? 'pointer' : 'not-allowed')};
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ isActive, theme }) =>
    !isActive ? theme.white : !theme.isDarkMode ? darken(0.1, theme.text4) : lighten(0.1, theme.text4)};
  :hover {
    background-color: ${({ isActive, theme }) => (!isActive ? lighten(0.05, theme.primary1) : theme.bg1)};
    border: 1px solid ${({ isActive, theme }) => (!isActive ? lighten(0.05, theme.primary1) : theme.bg3)};
  }
  :focus {
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
