import { Contract } from '@ethersproject/contracts'
import { Token } from '@xatadev/sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'

export abstract class AbstractRewardPool {
  public readonly chainId: number

  public readonly address: string

  public readonly tokenA: Token

  public readonly tokenB: Token

  public abstract readonly stakingToken: Token

  public abstract totalSupply?: BigNumberJS

  private _contract?: Contract

  protected constructor(chainId: number, address: string, tokenA: Token, tokenB: Token) {
    this.chainId = chainId
    this.address = address
    this.tokenA = tokenA
    this.tokenB = tokenB
  }

  public get contract(): Contract | undefined {
    return this._contract
  }
  public set contract(contract: Contract | undefined) {
    this._contract = contract
  }
}
