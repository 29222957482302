import { BigNumber } from '@ethersproject/bignumber'
import { ChainId, Currency, CurrencyAmount, JSBI, Token } from '@xatadev/sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { FarmStatus, RewardType, SortBy } from 'components/farms'
import { useActiveWeb3React } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { tryParseAmount } from 'state/swap/hooks'
import { SerializedToken } from 'utils/serializeToken'
import { toRawAmount } from 'utils/toRawAmount'

import { AppDispatch, AppState } from '../index'
import { addTokenPrices, PriceOfToken } from '../tokenPrices/actions'
import { updateFarmStatusOption, updateSearchQuery, updateSortByOption } from './actions'

export type FarmInfo = {
  apr?: BigNumberJS
  isActive: boolean
  isMulti: boolean
  periodFinish: number
  poolTotalSupply: BigNumber
  address?: string
  poolAddress: string
  rewardPerToken: BigNumber
  rewardPerTokenStored: BigNumber
  rewardRate: BigNumber
  rewardToken: string | WrappedTokenInfo
  stakingToken: string
  tokenA: SerializedToken | WrappedTokenInfo
  tokenB: SerializedToken | WrappedTokenInfo
  totalInFarm?: BigNumberJS
  userData?: UserFarmInfo
}

export type MultiFarmInfo = FarmInfo & {
  apr?: BigNumberJS[]
  periodFinish: number[]
  rewardPerTokenStored: BigNumber[]
  rewardRate: BigNumber[]
}

export type UserFarmInfo = {
  _poolAddress: string
  earnings: BigNumber
  stakedBalance: BigNumber
}

export const useTokenPrices = (
  chainId: ChainId | undefined,
  currencyCode = 'usd'
): [PriceOfToken, (pricesToAdd: PriceOfToken) => void] => {
  const dispatch = useDispatch<AppDispatch>()

  const [_chainId] = useState<ChainId | undefined>(chainId)
  const [_currencyCode] = useState<string>(currencyCode)
  const _tokenPrices = useSelector<AppState, PriceOfToken>(state => {
    if (!chainId || !state.tokenPrices[chainId] || !state.tokenPrices[chainId][currencyCode]) {
      return {}
    }

    return state.tokenPrices[chainId][currencyCode]
  })

  const tokenPrices = useMemo(() => _tokenPrices, [_tokenPrices])

  const addNewPrices = useCallback(
    (pricesToAdd: PriceOfToken) => {
      if (!_chainId) return

      dispatch(
        addTokenPrices({
          chainId: _chainId,
          currencyCode: _currencyCode,
          pricesToAdd
        })
      )
    },
    [dispatch, _chainId, _currencyCode]
  )

  return [tokenPrices, addNewPrices]
}

export const useFarms = (rewardType: RewardType) => {
  const { chainId /*, account, library*/ } = useActiveWeb3React()

  const farms = useSelector<AppState, AppState['farms']>(state => state.farms)

  return useMemo(() => {
    const farmsOnChain = chainId && farms ? farms.pools[chainId] : undefined
    if (!farmsOnChain) {
      return {
        farmsInfo: [],
        networkSupported: false,
        userDataLoaded: false
      }
    }

    const farmsFiltered = farmsOnChain.filter(farm =>
      rewardType === RewardType.SINGLE ? farm.isMulti === false : farm.isMulti === true
    )

    return {
      farmsInfo: farmsFiltered,
      networkSupported: true,
      userDataLoaded: false
    }
  }, [chainId, farms, rewardType])
}

export const useFarmsSearchQuery = (): [string, (value: string) => void] => {
  const dispatch = useDispatch<AppDispatch>()

  const searchQuery = useSelector<AppState, AppState['farms']['searchQuery']>(
    state => state.farms.searchQuery
  )

  const onSearchQueryChange = useCallback(
    (value: string) => {
      dispatch(updateSearchQuery(value))
    },
    [dispatch]
  )

  return [searchQuery, onSearchQueryChange]
}

export const useFarmStatusOption = (): [FarmStatus, (optionValue: FarmStatus) => void] => {
  const dispatch = useDispatch<AppDispatch>()

  const selectedFarmStatusOption = useSelector<AppState, AppState['farms']['farmStatusOption']>(
    state => state.farms.farmStatusOption
  )

  const onFarmStatusChange = useCallback(
    (optionValue: FarmStatus) => {
      dispatch(updateFarmStatusOption(optionValue))
    },
    [dispatch]
  )

  return [selectedFarmStatusOption, onFarmStatusChange]
}

export const useSortByOption = (): [SortBy, (optionValue: SortBy) => void] => {
  const dispatch = useDispatch<AppDispatch>()

  const selectedSortByOption = useSelector<AppState, AppState['farms']['sortByOption']>(
    state => state.farms.sortByOption
  )

  const onSortByOptionChange = useCallback(
    (optionValue: SortBy) => {
      dispatch(updateSortByOption(optionValue))
    },
    [dispatch]
  )

  return [selectedSortByOption, onSortByOptionChange]
}

export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: CurrencyAmount<Currency> | undefined
): {
  parsedAmount?: CurrencyAmount<Currency>
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount<Currency> | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput &&
    userLiquidityUnstaked &&
    JSBI.lessThanOrEqual(
      JSBI.BigInt(toRawAmount(parsedInput)),
      JSBI.BigInt(toRawAmount(userLiquidityUnstaked))
    )
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    error,
    parsedAmount
  }
}
