import { Token } from '@xatadev/sdk'

export type SerializedToken = {
  address: string
  chainId: number
  decimals: number
  isNative: boolean
  isToken: boolean
  name: string
  symbol: string
}

const serializeToken = ({ address, chainId, decimals, isNative, isToken, name, symbol }: Token): SerializedToken => ({
  address: address.toLowerCase(),
  chainId,
  decimals,
  isNative,
  isToken,
  name: name || '',
  symbol: symbol || ''
})

export default serializeToken
