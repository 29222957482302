import { BigNumber } from '@ethersproject/bignumber'
import { splitSignature } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
// import { TransactionResponse } from '@ethersproject/providers'
import { Currency, GasLimit, Percent, Xata } from '@xatadev/sdk'
import { BigNumber as JSBigNumber } from 'bignumber.js'
import DesktopSettings from 'components/Settings/DesktopSettings'
import { EIP712_DOMAIN_TYPE, PERMIT_TYPE } from 'constants/abis/conveyor-v2'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowDown, Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
// import ReactGA from 'react-ga'
// import { RouteComponentProps } from 'react-router'
import { Text } from 'rebass'
import { Field as AddField } from 'state/AddLiquidity/actions'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen } from 'state/application/hooks'
// import { GAS_LIMIT } from '../../constants/price/price'
// import { RELAYER_ENDPOINT_MAP } from 'constants/relayer'
import { useSimpleTransactionAdder } from 'state/transactions/hooks'
// import { utils } from 'ethers'
import styled, { ThemeContext } from 'styled-components'
import { toRawAmount } from 'utils/toRawAmount'

import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { LightCard, TipCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencyLogo from '../../components/CurrencyLogo'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import Slider from '../../components/Slider'
import { SwapCallbackError } from '../../components/swap/styleds'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../../components/TransactionConfirmationModal'
import TransactionFeeCard from '../../components/TransactionFeeCard'
// import { PairState } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useConveyorV2RouterContract, usePairContract } from '../../hooks/useContract'
// import { recoverTypedSignature } from 'eth-sig-util'
import useEnvironment from '../../hooks/useEnvironment'
import useGasLimit, { getDefaultGasLimit } from '../../hooks/useGasLimit'
// import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { AppDispatch, AppState } from '../../state'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/burn/actions'
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../state/burn/hooks'
import {
  feeTokenSymbol as feeTokenSymbolAction,
  removeLiquidityFeeInTokens as removeLiquidityFeeInTokensAction
} from '../../state/fee/actions'
import { useGasFee } from '../../state/fee/hooks'
import {
  useRemoveLiquidityActionHandlers,
  useRemoveLiquidityState
} from '../../state/RemoveLiquidity/hooks'
import {
  useIsExpertMode,
  useUserFeeTokens,
  useUserLiquidityGasLimit,
  useUserSlippageTolerance,
  useUserTransactionTTL,
  useUserUseCustomFeeToken
} from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
// import { useTransactionAdder } from '../../state/transactions/hooks'
import { TYPE } from '../../theme'
import { calculateSlippageAmount } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { ClickableText, MaxButton, Wrapper } from '../Pool/styleds'

// const { Interface, keccak256, defaultAbiCoder, toUtf8Bytes } = utils

const AppBodyContainer = styled.div`
  max-width: 420px;
  width: 100%;
  position: relative;
`

// Old params:
// { history, match: { params: { currencyIdA, currencyIdB } } }: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>
export default function RemoveLiquidity() {
  const {
    [Field.CURRENCY_A]: { currencyId: currencyIdA },
    [Field.CURRENCY_B]: { currencyId: currencyIdB }
  } = useRemoveLiquidityState()

  const { account, chainId, library } = useActiveWeb3React()

  const environment = useEnvironment()

  const [isFetchingFee, setIsFetchingFee] = useState<boolean>(false)

  // Dirty way to temporarily get the actual token address from its gToken address.
  // These logic will be removed when EstimatedGasFailure issue in Geode has been fixed
  // because we don't use gtoken anymore in v2.
  const [currencyA, currencyB] = [
    useCurrency(currencyIdA) ?? undefined,
    useCurrency(currencyIdB) ?? undefined
  ]
  const [tokenA, tokenB] = useMemo(
    () => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)],
    [currencyA, currencyB, chainId]
  )
  const originalCurrencyAAddress = currencyIdA
  const originalCurrencyBAddress = currencyIdB
  const originalCurrencyA = useCurrency(originalCurrencyAAddress) ?? undefined
  const originalCurrencyB = useCurrency(originalCurrencyBAddress) ?? undefined

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(
    currencyA ?? undefined,
    currencyB ?? undefined
  )
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const { onRemoveCurrencySelection } = useRemoveLiquidityActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values and user settings
  const [txHash, setTxHash] = useState<string>('')
  const isExpertMode = useIsExpertMode()
  const deadline = useTransactionDeadline()
  const deadlineTimerRef = useRef<ReturnType<typeof setTimeout>>()
  const [userTTL] = useUserTransactionTTL()
  const [currentTTL, setCurrentTTL] = useState<number>(userTTL)
  const [userAllowedSlippage] = useUserSlippageTolerance()
  const [currentSlippage, setCurrentSlippage] = useState<number>(userAllowedSlippage)
  // const [userLiquidityGasLimit, setUserLiquidityGasLimit] = useUserLiquidityGasLimit()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY
        ? typedValue
        : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A
        ? typedValue
        : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B
        ? typedValue
        : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? ''
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // contracts
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)
  const routerContract = useConveyorV2RouterContract()

  // allowance handling
  const [signatureData, setSignatureData] = useState<{
    v: number
    r: string
    s: string
    deadline: number
  } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], ROUTER_ADDRESS[chainId!])
  const [approval /*, approveCallback*/] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    // ROUTER_ADDRESS[environment][chainId!]
    routerContract?.address
  )
  console.log('approval', approval)
  const [removePayload, setRemovePayload] = useState<null | {
    tokenA: string
    tokenB: string
    liquidity: string
    amountAMin: string
    amountBMin: string
    user: string
    deadline: string
  }>(null)

  // const isArgentWallet = useIsArgentWallet()

  // const [EIP712Msg, setEIP712Msg] = useState<any | null>(null)

  const { gasLimit /*, resetUserGasLimit*/ } = useGasLimit()

  const resetApproval = () => {
    setSignatureData(null)
    setRemovePayload(null)
  }

  const handleDeadlineTimer = () =>
    setTimeout(() => {
      console.log('Remove approval timeout')
      resetApproval()
      deadlineTimerRef.current = undefined
    }, currentTTL * 1000)

  useEffect(() => {
    if (deadlineTimerRef.current !== undefined) {
      clearTimeout(deadlineTimerRef.current)
      deadlineTimerRef.current = undefined
    }
    resetApproval()
  }, [account])

  // useEffect(() => {
  //   // setUserLiquidityGasLimit(GAS_LIMIT[environment].addLiquidity)
  //   resetUserGasLimit(GasLimit.LIQUIDITY_ADD)
  // }, [resetUserGasLimit])

  useEffect(
    () => {
      let shouldResetApproval = false

      const previousSlippage = currentSlippage
      if (previousSlippage !== userAllowedSlippage) {
        setCurrentSlippage(userAllowedSlippage)
        shouldResetApproval = true
      }

      const previousTTL = currentTTL
      if (previousTTL !== userTTL) {
        setCurrentTTL(userTTL)
        shouldResetApproval = true
      }

      if (shouldResetApproval) {
        if (deadlineTimerRef.current !== undefined) {
          clearTimeout(deadlineTimerRef.current)
          deadlineTimerRef.current = undefined
        }
        resetApproval()
      }

      return () => {
        if (deadlineTimerRef.current !== undefined) {
          clearTimeout(deadlineTimerRef.current)
          deadlineTimerRef.current = undefined
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, userTTL, userAllowedSlippage]
  )

  const [errorMessage, setErrorMessage] = useState<string>('')

  // async function onAttemptToApprove() {
  //   if (!pairContract || !pair || !library || !deadline) throw new Error('missing dependencies')
  //   const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
  //   if (!liquidityAmount) throw new Error('missing liquidity amount')

  //   if (isArgentWallet) {
  //     return approveCallback()
  //   }

  //   const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
  //   if (!currencyAmountA || !currencyAmountB) {
  //     throw new Error('missing currency amounts')
  //   }
  //   const amountsMin = {
  //     [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
  //     [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0]
  //   }

  //   const gasPrice = await library?.getGasPrice()
  //   const gasLimit = ADD_LIQUIDITY_GAS_LIMIT
  //   const feeOnTokenA = await calculateFeeOnToken(
  //     chainId,
  //     originalCurrencyAAddress,
  //     originalCurrencyA!.decimals,
  //     gasPrice === undefined ? undefined : gasPrice.mul(gasLimit)
  //   )

  //   // try to gather a signature for permission
  //   const nonce = await pairContract.nonces(account)

  //   const EIP712Domain = [
  //     { name: 'name', type: 'string' },
  //     { name: 'version', type: 'string' },
  //     { name: 'chainId', type: 'uint256' },
  //     { name: 'verifyingContract', type: 'address' }
  //   ]

  //   const Permit = [
  //     { name: 'owner', type: 'address' },
  //     { name: 'spender', type: 'address' },
  //     { name: 'tokenA', type: 'address' },
  //     { name: 'tokenB', type: 'address' },
  //     { name: 'amountAMin', type: 'uint256' },
  //     { name: 'amountBMin', type: 'uint256' },
  //     { name: 'value', type: 'uint256' },
  //     { name: 'nonce', type: 'uint256' },
  //     { name: 'deadline', type: 'uint256' },
  //     { name: 'feeAmount', type: 'uint256' },
  //     { name: 'feeToken', type: 'address' }
  //   ]

  //   // let domainName = 'Uniswap V2'
  //   // if (chainId === ChainId.BSCMAIN || chainId === ChainId.BSCTEST) {
  //   //   domainName = 'Pancake LPs'
  //   // }
  //   const domain = {
  //     // name: domainName,
  //     name: 'Conveyor V2',
  //     version: '1',
  //     chainId: BigNumber.from(chainId).toHexString(),
  //     verifyingContract: pair.liquidityToken.address
  //   }
  //   console.log(pair.liquidityToken)

  //   const message = {
  //     owner: account,
  //     // spender: GTOKEN_CONTROLLER_MAP[chainId!],
  //     spender: ROUTER_ADDRESS[environment][chainId!],
  //     tokenA: originalCurrencyAAddress,
  //     tokenB: originalCurrencyBAddress,
  //     amountAMin: BigNumber.from(amountsMin.CURRENCY_A.toString()).toHexString(),
  //     amountBMin: BigNumber.from(amountsMin.CURRENCY_B.toString()).toHexString(),
  //     value: BigNumber.from(liquidityAmount.numerator.toString()).toHexString(),
  //     nonce: nonce.toHexString(),
  //     deadline: deadline.toHexString(),
  //     feeAmount: BigNumber.from(feeOnTokenA.toFixed(0)).toHexString(),
  //     feeToken: originalCurrencyAAddress
  //   }

  //   const _EIP712Msg = {
  //     types: {
  //       EIP712Domain,
  //       Permit
  //     },
  //     domain,
  //     primaryType: 'Permit',
  //     message
  //   }

  //   console.log('account: ', account)
  //   console.log('EIP712Msg account: ', _EIP712Msg)

  //   const data = JSON.stringify(_EIP712Msg)
  //   console.log('data:', data)

  //   // Here we use state so we can retrieve the value later in onRemove callback
  //   setEIP712Msg(_EIP712Msg)

  //   const signature = await library.send('eth_signTypedData_v4', [account, data])
  //   const { r, s, v } = splitSignature(signature)
  //   setSignatureData({ r, s, v, deadline: deadline.toNumber() })
  //   // console.log('signature: ', signature)
  //   console.log('signature: EIPMsg: ', data)
  //   // console.log(
  //   //   'signature Util: ',
  //   //   recoverTypedSignature({
  //   //     data: {
  //   //       types: {
  //   //         EIP712Domain,
  //   //         Permit
  //   //       },
  //   //       domain,
  //   //       primaryType: 'Permit',
  //   //       message
  //   //     },
  //   //     sig: signature
  //   //   })
  //   // )

  //   // library
  //   //   .send('eth_signTypedData_v4', [account, data])
  //   //   .then(splitSignature)
  //   //   .then(signature => {
  //   //     setSignatureData({
  //   //       v: signature.v,
  //   //       r: signature.r,
  //   //       s: signature.s,
  //   //       deadline: deadline.toNumber()
  //   //     })
  //   //     console.log('signatureData: ', signatureData)
  //   //   })
  //   //   .catch(error => {
  //   //     // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
  //   //     if (error?.code !== 4001) {
  //   //       approveCallback()
  //   //     }
  //   //   })
  // }

  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [userFeeTokens] = useUserFeeTokens()
  const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  // const feeTokenSymbol = useSelector<AppState, AppState['fee']['feeTokenSymbol']>(
  //   state => state.fee.feeTokenSymbol
  // )
  const { removeLiquidityFeeInTokens, feeTokenSymbol, fetchGasFeeInToken } = useGasFee()

  const feeTokenAmount = useTokenBalance(
    account ?? undefined,
    userUseCustomFeeToken && userFeeCurrency
      ? userFeeCurrency.wrapped
      : // : feeTokenSymbol === 'CON-V2' && pair
        // ? pair.liquidityToken
        currencyA?.wrapped
  )
  console.log('feeTokenAmount', parsedAmounts[Field.LIQUIDITY]?.toFixed())

  const [feeTokenApproval, approveFeeTokenCallback] = useApproveCallback(
    feeTokenSymbol === 'CON-V2' && pair ? parsedAmounts[Field.LIQUIDITY] : feeTokenAmount,
    routerContract?.address ?? undefined
  )

  const showFeeTokenApproval = feeTokenApproval !== ApprovalState.APPROVED && isValid
  console.log('feeTokenApproval', feeTokenApproval)
  console.log('showFeeTokenApproval', showFeeTokenApproval)
  console.debug(approveFeeTokenCallback)

  const [userLiquidityGasLimit] = useUserLiquidityGasLimit()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!isValid) {
      if (isFetchingFee) {
        setIsFetchingFee(false)
      }

      return
    }

    if (!feeTokenSymbol && !removeLiquidityFeeInTokens) {
      setIsFetchingFee(true)

      const gasLimit = isExpertMode
        ? BigNumber.from(userLiquidityGasLimit)
        : BigNumber.from(getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_REMOVE))

      // dispatch(isFetchingFee(true))
      fetchGasFeeInToken(gasLimit, currencyA)?.then(response => {
        if (response?.maxGasFeeInToken && response?.tokenSymbol) {
          dispatch(removeLiquidityFeeInTokensAction(response.maxGasFeeInToken.toString()))
          dispatch(feeTokenSymbolAction(response.tokenSymbol))
          // console.log('test')
        }
        // dispatch(isFetchingFee(false))
      })
    } else {
      setIsFetchingFee(false)
    }
  }, [
    chainId,
    currencyA,
    dispatch,
    feeTokenSymbol,
    fetchGasFeeInToken,
    isExpertMode,
    isFetchingFee,
    isValid,
    removeLiquidityFeeInTokens,
    userLiquidityGasLimit
  ])

  const resetFeeState = useCallback(() => {
    // setIsFetchingFee(true)
    dispatch(removeLiquidityFeeInTokensAction(undefined))
    dispatch(feeTokenSymbolAction(undefined))
  }, [dispatch])

  const removeLiquidityFee = useSelector<AppState, AppState['fee']['removeLiquidityFeeInTokens']>(
    state => state.fee.removeLiquidityFeeInTokens
  )
  const feeFraction = useMemo(() => {
    const roundedFee =
      removeLiquidityFee === undefined
        ? undefined
        : new JSBigNumber(removeLiquidityFee).toFixed(0, 2)

    if (roundedFee && currencyA) {
      const decimals =
        userUseCustomFeeToken && userFeeCurrency
          ? userFeeCurrency.decimals
          : pair && feeTokenSymbol === 'CON-V2'
          ? pair?.liquidityToken.decimals
          : currencyA.decimals

      return new JSBigNumber(roundedFee).div(new JSBigNumber(10).pow(decimals).toFixed(decimals))
    }

    return undefined
  }, [currencyA, feeTokenSymbol, pair, removeLiquidityFee, userFeeCurrency, userUseCustomFeeToken])

  async function onAttemptToApprove() {
    if (!pairContract || !routerContract || !pair || !library || !deadline)
      throw new Error('missing dependencies')
    if (!currencyA || !currencyB) throw new Error('missing tokens')
    if (!account) throw new Error('no connected account detected')

    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB
    } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) throw new Error('missing currency amounts')

    setErrorMessage('')
    setRemovePayload(null)

    const parsedLiquidityAmount = toRawAmount(liquidityAmount)

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, currentSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, currentSlippage)[0]
    }

    const removePayload = {
      tokenA: currencyA.wrapped.address,
      tokenB: currencyB.wrapped.address,
      liquidity: BigNumber.from(parsedLiquidityAmount).toHexString(),
      amountAMin: BigNumber.from(amountsMin.CURRENCY_A.toString()).toHexString(),
      amountBMin: BigNumber.from(amountsMin.CURRENCY_B.toString()).toHexString(),
      user: account,
      deadline: deadline.toHexString()
    }
    setRemovePayload(removePayload)

    // try to gather a signature for permission
    const nonce = await pairContract.nonces(account)

    const domain = {
      name: 'Conveyor V2',
      version: '1',
      chainId: BigNumber.from(chainId).toHexString(),
      verifyingContract: pair.liquidityToken.address
    }

    const message = {
      owner: account,
      spender: routerContract.address,
      value: BigNumber.from(parsedLiquidityAmount).toHexString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toHexString()
    }

    const EIP712Msg = {
      types: {
        EIP712Domain: EIP712_DOMAIN_TYPE,
        Permit: PERMIT_TYPE
      },
      domain,
      primaryType: 'Permit',
      message
    }

    const data = JSON.stringify(EIP712Msg)

    // Here we use state so we can retrieve the value later in onRemove callback
    const signature = await library.send('eth_signTypedData_v4', [account, data])
    const { r, s, v } = splitSignature(signature)
    setSignatureData({ r, s, v, deadline: deadline.toNumber() })

    deadlineTimerRef.current = handleDeadlineTimer()
  }

  const [activePercentButton, setActivePercentButton] = useState<string | null>(null)

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      if (deadlineTimerRef.current !== undefined) {
        clearTimeout(deadlineTimerRef.current)
        deadlineTimerRef.current = undefined
      }

      resetApproval()
      setActivePercentButton(typedValue)

      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  )
  const onCurrencyAInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
    [onUserInput]
  )
  const onCurrencyBInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
    [onUserInput]
  )

  const originalCurrencyASymbol = currencyA !== undefined ? currencyA.symbol : '-'
  const originalCurrencyBSymbol = currencyB !== undefined ? currencyB.symbol : '-'

  // tx sending
  // const addTransaction = useTransactionAdder()
  const addTransaction = useSimpleTransactionAdder()

  // async function onRemove() {
  //   if (!chainId || !library || !account || !deadline) throw new Error('missing dependencies')
  //   const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
  //   if (!currencyAmountA || !currencyAmountB) {
  //     throw new Error('missing currency amounts')
  //   }
  //   // const router = getRouterContract(chainId, library, account)
  //   // const controller = getContract(GTOKEN_CONTROLLER_MAP[chainId], CONTROLLER_ABI, library, account)
  //   const controller = getContract(ROUTER_ADDRESS[environment][chainId!], CONVEYOR_V2_ROUTER_ABI, library, account)
  //   console.log('controller: ', controller)

  //   // const amountsMin = {
  //   //   [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
  //   //   [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0]
  //   // }

  //   if (!currencyA || !currencyB) throw new Error('missing tokens')
  //   const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
  //   if (!liquidityAmount) throw new Error('missing liquidity amount')

  //   const currencyBIsETH = currencyB === Ether.onChain(chainId)
  //   const oneCurrencyIsETH = currencyA === Ether.onChain(chainId) || currencyBIsETH

  //   if (!tokenA || !tokenB) throw new Error('could not wrap')

  //   // let methodNames: string[]
  //   // let args: Array<string | string[] | number | boolean | Object>
  //   // we have approval, use normal remove liquidity
  //   console.log('approval: ', approval)
  //   console.log('signatureData: ', signatureData)
  //   console.log('oneCurrencyIsETH: ', oneCurrencyIsETH)
  //   // if (approval === ApprovalState.APPROVED) {
  //   //   // removeLiquidityETH
  //   //   if (oneCurrencyIsETH) {
  //   //     methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
  //   //     args = [
  //   //       currencyBIsETH ? tokenA.address : tokenB.address,
  //   //       liquidityAmount.raw.toString(),
  //   //       amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
  //   //       amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
  //   //       account,
  //   //       deadline.toHexString()
  //   //     ]
  //   //   }
  //   //   // removeLiquidity
  //   //   else {
  //   //     methodNames = ['removeLiquidity']
  //   //     args = [
  //   //       tokenA.address,
  //   //       tokenB.address,
  //   //       liquidityAmount.raw.toString(),
  //   //       amountsMin[Field.CURRENCY_A].toString(),
  //   //       amountsMin[Field.CURRENCY_B].toString(),
  //   //       account,
  //   //       deadline.toHexString()
  //   //     ]
  //   //   }
  //   // }
  //   // // we have a signataure, use permit versions of remove liquidity
  //   // else if (signatureData !== null) {
  //   //   // removeLiquidityETHWithPermit
  //   //   if (oneCurrencyIsETH) {
  //   //     methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
  //   //     args = [
  //   //       currencyBIsETH ? tokenA.address : tokenB.address,
  //   //       liquidityAmount.raw.toString(),
  //   //       amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
  //   //       amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
  //   //       account,
  //   //       signatureData.deadline,
  //   //       false,
  //   //       signatureData.v,
  //   //       signatureData.r,
  //   //       signatureData.s
  //   //     ]
  //   //   }
  //   //   // removeLiquidityETHWithPermit
  //   //   else {
  //   //     // if (chainId === ChainId.BSCMAIN || chainId === ChainId.BSCTEST) {
  //   //     //   methodNames = ['pancakeV2removeLiquidityWithPermit']
  //   //     // } else {
  //   //     //   methodNames = ['removeLiquidityWithPermit']
  //   //     // }
  //   //     methodNames = ['removeLiquidityWithPermit']
  //   //     args = [
  //   //       {
  //   //         tokenA: tokenA.address,
  //   //         tokenB: tokenB.address,
  //   //         liquidity: liquidityAmount.raw.toString(),
  //   //         amountAMin: amountsMin[Field.CURRENCY_A].toString(),
  //   //         amountBMin: amountsMin[Field.CURRENCY_B].toString(),
  //   //         user: account,
  //   //         deadline: signatureData.deadline,
  //   //         approveMax: false
  //   //       },
  //   //       {
  //   //         v: signatureData.v,
  //   //         r: signatureData.r,
  //   //         s: signatureData.s
  //   //       }
  //   //     ]
  //   //   }
  //   // } else {
  //   //   throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
  //   // }

  //   // const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
  //   //   methodNames.map(methodName => {
  //   //     console.log('methodName: ', methodName)
  //   //     return controller.estimateGas[methodName](...args)
  //   //       .then(value => {
  //   //         console.log('value: ', value)
  //   //         return calculateGasMargin(value)
  //   //       })
  //   //       .catch(error => {
  //   //         console.error(`estimateGas failed`, methodName, args, error)
  //   //         return undefined
  //   //       })
  //   //   })
  //   // )

  //   // const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(safeGasEstimate =>
  //   //   BigNumber.isBigNumber(safeGasEstimate)
  //   // )

  //   // // all estimations failed...
  //   // if (indexOfSuccessfulEstimation === -1) {
  //   //   console.error('This transaction would fail. Please contact support.')
  //   // } else {
  //   //   const methodName = methodNames[indexOfSuccessfulEstimation]
  //   //   const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

  //   //   setAttemptingTxn(true)
  //   //   await controller[methodName](...args, {
  //   //     gasLimit: safeGasEstimate
  //   //   })
  //   //     .then((response: TransactionResponse) => {
  //   //       setAttemptingTxn(false)

  //   //       const originalCurrencyASymbol = currencyA.symbol?.startsWith('g') ? currencyA.symbol.substring(1) : currencyA.symbol
  //   //       const originalCurrencyBSymbol = currencyB.symbol?.startsWith('g') ? currencyB.symbol.substring(1) : currencyB.symbol
  //   //       addTransaction(response, {
  //   //         summary:
  //   //           'Remove ' +
  //   //           parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
  //   //           ' ' +
  //   //           originalCurrencyASymbol +
  //   //           ' and ' +
  //   //           parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
  //   //           ' ' +
  //   //           originalCurrencyBSymbol
  //   //       })

  //   //       setTxHash(response.hash)

  //   //       ReactGA.event({
  //   //         category: 'Liquidity',
  //   //         action: 'Remove',
  //   //         label: [currencyA?.symbol, currencyB?.symbol].join('/')
  //   //       })
  //   //     })
  //   //     .catch((error: Error) => {
  //   //       setAttemptingTxn(false)
  //   //       // we only care if the error is something _other_ than the user rejected the tx
  //   //       console.error(error)
  //   //     })
  //   // }

  //   // RPC call for Conveyor v2 transaction
  //   console.log('signatureData: ', signatureData)
  //   if (signatureData === null) {
  //     throw new Error('Liquidity approval failed')
  //   }

  //   setAttemptingTxn(true)

  //   const params = [chainId, EIP712Msg, signatureData.v.toString(), signatureData.r, signatureData.s]
  //   console.log('params: ', params)

  //   const jsonRPCRequest = {
  //     jsonrpc: '2.0',
  //     method: '/v2/removeLiquidity',
  //     id: 1,
  //     params
  //   }

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(jsonRPCRequest)
  //   }

  //   const jsonRPCResponse = await fetch(RELAYER_ENDPOINT_MAP[environment][chainId]!, requestOptions)
  //   console.log('jsonRPCResponse: ', jsonRPCResponse)
  //   const { result: response } = await jsonRPCResponse.json()
  //   console.log('jsonRPCResponse result: ', response)

  //   setAttemptingTxn(false)

  //   if (response.success === true) {
  //     const [parsedAmountA, parsedAmountB] = [
  //       parsedAmounts[Field.CURRENCY_A]?.toSignificant(3),
  //       parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)
  //     ]
  //     addTransaction(response.txnHash as string, {
  //       summary: `Remove ${parsedAmountA} ${originalCurrencyASymbol} and ${parsedAmountB} ${originalCurrencyBSymbol}`
  //       // 'Add ' +
  //       // parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
  //       // ' ' +
  //       // currencies[Field.CURRENCY_A]?.symbol +
  //       // ' and ' +
  //       // parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
  //       // ' ' +
  //       // currencies[Field.CURRENCY_B]?.symbol
  //     })

  //     setTxHash(response.txnHash)
  //   } else {
  //     setErrorMessage(response.errorMessage)
  //     console.error(errorMessage)
  //     return
  //   }
  // }

  async function onRemove() {
    setErrorMessage('')

    if (!chainId || !library || !account || !deadline || !routerContract)
      throw new Error('missing dependencies')
    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB
    } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    // const amountsMin = {
    //   [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
    //   [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0]
    // }

    if (!currencyA || !currencyB) throw new Error('missing tokens')

    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    // const parsedLiquidityAmount = toRawAmount(liquidityAmount)

    if (signatureData === null) {
      throw new Error('Liquidity approval failed')
    }

    // const gasPrice = await library?.getGasPrice()
    // const userGasLimit = isExpertMode ? userLiquidityGasLimit : GAS_LIMIT[environment].addLiquidity
    // const gasLimit = BigNumber.from(userGasLimit)
    // const feeOnTokenA = await calculateFeeOnToken(
    //   chainId,
    //   currencyA.wrapped.address,
    //   currencyA.decimals,
    //   gasPrice === undefined ? undefined : gasPrice.mul(gasLimit)
    // )
    // const roundedFeeOnTokenA = feeOnTokenA.toFixed(0, 2)
    // const maxTokenAmount = Number.parseInt(roundedFeeOnTokenA) < 1 ? '1' : roundedFeeOnTokenA

    const xataApi = new Xata()
    await xataApi.init(
      library,
      userUseCustomFeeToken && userFeeCurrency
        ? userFeeCurrency.wrapped.address
        : feeTokenSymbol === 'CON-V2' && pair
        ? pair.liquidityToken.address
        : currencyA.wrapped.address,
      environment
    )

    // const gasLimit = isExpertMode ? BigNumber.from(userLiquidityGasLimit) : undefined
    const _gasLimit = isExpertMode
      ? BigNumber.from(gasLimit[GasLimit.LIQUIDITY_ADD])
      : BigNumber.from(gasLimit[GasLimit.LIQUIDITY_REMOVE])

    const signaturePayload = {
      v: signatureData.v,
      r: signatureData.r,
      s: signatureData.s
    }

    // const fnParams = [
    //   'tuple(address tokenA,address tokenB,uint256 liquidity,uint256 amountAMin,uint256 amountBMin,address user,uint256 deadline)',
    //   'tuple(uint8 v,bytes32 r,bytes32 s)'
    // ]
    // const fnData = [`function removeLiquidityWithPermit(${fnParams[0]}, ${fnParams[1]})`]
    // const fnDataIface = new Interface(fnData)

    // const nonce: BigNumber = await routerContract?.nonces(account)

    // const message = {
    //   from: account,
    //   feeToken: currencyA.wrapped.address,
    //   maxTokenAmount: BigNumber.from(maxTokenAmount).toHexString(),
    //   deadline: deadline.toHexString(),
    //   nonce: nonce.toHexString(),
    //   // data: fnDataIface.functions.removeLiquidityWithPermit.encode([removePayload, signaturePayload]),
    //   data: fnDataIface.encodeFunctionData('removeLiquidityWithPermit', [removePayload, signaturePayload]),
    //   hashedPayload: keccak256(
    //     defaultAbiCoder.encode(
    //       [
    //         'bytes',
    //         'address',
    //         'address',
    //         'uint256',
    //         'uint256',
    //         'uint256',
    //         'address',
    //         'uint256',
    //         'uint8',
    //         'bytes32',
    //         'bytes32'
    //       ],
    //       [
    //         keccak256(
    //           toUtf8Bytes(
    //             'removeLiquidityWithPermit(address tokenA,address tokenB,uint256 liquidity,uint256 amountAMin,uint256 amountBMin,address user,uint256 deadline,uint8 v,bytes32 r,bytes32 s)'
    //           )
    //         ),
    //         ...Object.entries({ ...removePayload, ...signaturePayload }).map(([, value]) => value)
    //       ]
    //     )
    //   )
    // }

    // const EIP712Msg = {
    //   types: {
    //     EIP712Domain: EIP712_DOMAIN_TYPE,
    //     Forwarder: FORWARDER_TYPE
    //     // RemoveLiquidity,
    //   },
    //   domain,
    //   primaryType: 'Forwarder',
    //   message
    // }

    // const data = JSON.stringify(EIP712Msg)

    console.log('gas limit: ', gasLimit)

    setAttemptingTxn(true)

    xataApi
      .removeLiquidity(
        removePayload!.tokenA,
        removePayload!.tokenB,
        BigNumber.from(removePayload!.liquidity),
        BigNumber.from(removePayload!.amountAMin),
        BigNumber.from(removePayload!.amountBMin),
        removePayload!.user,
        BigNumber.from(removePayload!.deadline),
        signaturePayload,
        _gasLimit
      )
      .then(res => {
        setAttemptingTxn(false)
        const { result } = res
        if (result.success) {
          const [parsedAmountA, parsedAmountB] = [
            parsedAmounts[Field.CURRENCY_A]?.toSignificant(3),
            parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)
          ]
          addTransaction(result.txnHash, {
            summary: `Remove ${parsedAmountA} ${currencyA?.symbol} and ${parsedAmountB} ${currencyB?.symbol}`
          })

          setTxHash(result.txnHash)
          setSignatureData(null)
          // if (isExpertMode) {
          //   setShowConfirm(true)
          // }
        } else {
          if (deadlineTimerRef.current !== undefined) {
            clearTimeout(deadlineTimerRef.current)
            deadlineTimerRef.current = undefined
          }
          resetApproval()
          setErrorMessage(result.errorMessage)
          console.error(errorMessage)
          return
        }
      })
      .catch(error => {
        if (deadlineTimerRef.current !== undefined) {
          clearTimeout(deadlineTimerRef.current)
          deadlineTimerRef.current = undefined
        }
        resetApproval()
        setAttemptingTxn(false)
        console.dir(error)
        setErrorMessage(error.message)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={originalCurrencyA} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {originalCurrencyASymbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus size="16" color={theme.text2} />
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={originalCurrencyB} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {originalCurrencyBSymbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <TYPE.italic fontSize={12} color={theme.text2} textAlign="left" padding={'12px 0 0 0'}>
          {`Output is estimated. If the price changes by more than ${currentSlippage /
            100}% your transaction will revert.`}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
        <RowBetween>
          <Text color={theme.text2} fontWeight={500} fontSize={16}>
            {'UNI ' + currencyA?.symbol + '/' + currencyB?.symbol} Burned
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo
              currency0={originalCurrencyA}
              currency1={originalCurrencyB}
              margin={true}
            />
            <Text fontWeight={500} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text color={theme.text2} fontWeight={500} fontSize={16}>
                Price
              </Text>
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {originalCurrencyASymbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                {originalCurrencyBSymbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {originalCurrencyBSymbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                {originalCurrencyASymbol}
              </Text>
            </RowBetween>
          </>
        )}
        <ButtonPrimary
          disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
          onClick={onRemove}
        >
          <Text fontWeight={500} fontSize={20}>
            Confirm
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  // const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER
  // const oneCurrencyIsWETH = Boolean(
  //   chainId &&
  //     ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
  //       (currencyB && currencyEquals(WETH[chainId], currencyB)))
  // )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (deadlineTimerRef.current !== undefined) {
        clearTimeout(deadlineTimerRef.current)
        deadlineTimerRef.current = undefined
      }

      resetApproval()

      if (currencyIdB && currencyId(currency) === currencyIdB) {
        onRemoveCurrencySelection(Field.CURRENCY_A as AddField, currencyB)
        onRemoveCurrencySelection(Field.CURRENCY_B as AddField, currencyA)
      } else {
        onRemoveCurrencySelection(Field.CURRENCY_A as AddField, currency)
      }
    },
    [currencyIdB, currencyA, currencyB, onRemoveCurrencySelection]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (deadlineTimerRef.current !== undefined) {
        clearTimeout(deadlineTimerRef.current)
        deadlineTimerRef.current = undefined
      }

      resetApproval()

      if (currencyIdA && currencyId(currency) === currencyIdA) {
        onRemoveCurrencySelection(Field.CURRENCY_A as AddField, currencyB)
        onRemoveCurrencySelection(Field.CURRENCY_B as AddField, currencyA)
      } else {
        onRemoveCurrencySelection(Field.CURRENCY_B as AddField, currency)
      }
    },
    [currencyIdA, currencyA, currencyB, onRemoveCurrencySelection]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setSignatureData(null) // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  const isSettingsModalOpen = useModalOpen(ApplicationModal.SETTINGS)
  const windowSize = useWindowSize()
  const isDesktop = useMemo(() => windowSize.width && windowSize.width > 960, [windowSize.width])

  const handleConfirmDismiss = useCallback(() => {
    setErrorMessage('')
  }, [])

  const handleFeeSelect = useCallback(() => {
    resetFeeState()
  }, [resetFeeState])

  return (
    <>
      <AppBodyContainer>
        {isDesktop && isSettingsModalOpen ? <DesktopSettings /> : null}

        <AppBody>
          <AddRemoveTabs creating={false} adding={false} />
          <Wrapper>
            <TransactionConfirmationModal
              isOpen={showConfirm}
              onDismiss={handleDismissConfirmation}
              attemptingTxn={attemptingTxn}
              hash={txHash ? txHash : ''}
              preventedLoss={undefined}
              content={() =>
                errorMessage && errorMessage.length > 0 ? (
                  <TransactionErrorContent
                    onDismiss={handleConfirmDismiss}
                    message={errorMessage}
                  />
                ) : (
                  <ConfirmationModalContent
                    title={'You will receive'}
                    onDismiss={handleDismissConfirmation}
                    topContent={modalHeader}
                    bottomContent={modalBottom}
                  />
                )
              }
              pendingText={pendingText}
            />
            <AutoColumn gap="md">
              <TipCard>
                <AutoColumn gap="10px">
                  <TYPE.link fontWeight={400} color={'tipText'}>
                    <b>Tip:</b> Removing pool tokens converts your position back into underlying
                    tokens at the current rate, proportional to your share of the pool. Accrued fees
                    are included in the amounts you receive.
                  </TYPE.link>
                </AutoColumn>
              </TipCard>
              <LightCard>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <Text fontWeight={500}>Amount</Text>
                    <ClickableText
                      fontWeight={500}
                      onClick={() => {
                        setShowDetailed(!showDetailed)
                      }}
                    >
                      {showDetailed ? 'Simple' : 'Detailed'}
                    </ClickableText>
                  </RowBetween>
                  <Row style={{ alignItems: 'flex-end' }}>
                    <Text fontSize={72} fontWeight={500}>
                      {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                    </Text>
                  </Row>
                  {!showDetailed && (
                    <>
                      <Slider
                        value={innerLiquidityPercentage}
                        onChange={setInnerLiquidityPercentage}
                      />
                      <RowBetween>
                        <MaxButton
                          isActive={activePercentButton === '16' || false}
                          onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '16')}
                          width="20%"
                        >
                          16%
                        </MaxButton>
                        <MaxButton
                          isActive={activePercentButton === '25' || false}
                          onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                          width="20%"
                        >
                          25%
                        </MaxButton>
                        <MaxButton
                          isActive={activePercentButton === '50' || false}
                          onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                          width="20%"
                        >
                          50%
                        </MaxButton>
                        <MaxButton
                          isActive={activePercentButton === '75' || false}
                          onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                          width="20%"
                        >
                          75%
                        </MaxButton>
                        <MaxButton
                          isActive={activePercentButton === '100' || false}
                          onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                          width="20%"
                        >
                          Max
                        </MaxButton>
                      </RowBetween>
                    </>
                  )}
                </AutoColumn>
              </LightCard>
              {!showDetailed && (
                <>
                  <ColumnCenter>
                    <ArrowDown size="16" color={theme.text2} />
                  </ColumnCenter>
                  <LightCard>
                    <AutoColumn gap="10px">
                      <RowBetween>
                        <Text fontSize={24} fontWeight={500}>
                          {formattedAmounts[Field.CURRENCY_A] || '-'}
                        </Text>
                        <RowFixed>
                          <CurrencyLogo
                            currency={originalCurrencyA}
                            style={{ marginRight: '12px' }}
                          />
                          <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokena-symbol">
                            {originalCurrencyASymbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <Text fontSize={24} fontWeight={500}>
                          {formattedAmounts[Field.CURRENCY_B] || '-'}
                        </Text>
                        <RowFixed>
                          <CurrencyLogo
                            currency={originalCurrencyB}
                            style={{ marginRight: '12px' }}
                          />
                          <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokenb-symbol">
                            {originalCurrencyBSymbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      {/* {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                        <RowBetween style={{ justifyContent: 'flex-end' }}>
                          {oneCurrencyIsETH ? (
                            <StyledInternalLink
                              to={`/remove/${currencyA === ETHER ? WETH[chainId].address : currencyIdA}/${
                                currencyB === ETHER ? WETH[chainId].address : currencyIdB
                              }`}
                            >
                              Receive WETH
                            </StyledInternalLink>
                          ) : oneCurrencyIsWETH ? (
                            <StyledInternalLink
                              to={`/remove/${
                                currencyA && currencyEquals(currencyA, WETH[chainId]) ? 'ETH' : currencyIdA
                              }/${currencyB && currencyEquals(currencyB, WETH[chainId]) ? 'ETH' : currencyIdB}`}
                            >
                              Receive ETH
                            </StyledInternalLink>
                          ) : null}
                        </RowBetween>
                      ) : null} */}
                    </AutoColumn>
                  </LightCard>
                </>
              )}

              {showDetailed && (
                <>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.LIQUIDITY]}
                    onUserInput={onLiquidityInput}
                    onMax={() => {
                      onUserInput(Field.LIQUIDITY_PERCENT, '100')
                    }}
                    showMaxButton={!atMaxAmount}
                    disableCurrencySelect
                    currency={pair?.liquidityToken}
                    pair={pair}
                    id="liquidity-amount"
                  />
                  <ColumnCenter>
                    <ArrowDown size="16" color={theme.text2} />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    hideBalance={true}
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onCurrencyAInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                    showMaxButton={!atMaxAmount}
                    currency={currencyA}
                    label={'Output'}
                    onCurrencySelect={handleSelectCurrencyA}
                    id="remove-liquidity-tokena"
                  />
                  <ColumnCenter>
                    <Plus size="16" color={theme.text2} />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    hideBalance={true}
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onCurrencyBInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                    showMaxButton={!atMaxAmount}
                    currency={currencyB}
                    label={'Output'}
                    onCurrencySelect={handleSelectCurrencyB}
                    id="remove-liquidity-tokenb"
                  />
                </>
              )}
              {pair && (
                <>
                  {/*<AutoColumn gap={'20px'}>*/}
                  {/*  {!userUseCustomFeeToken ? (*/}
                  {/*    <div style={{ padding: '10px 20px', marginBottom: '-10px' }}>*/}
                  {/*      <AutoRow justify="space-between">*/}
                  {/*        <AutoColumn justify="center">*/}
                  {/*          <TYPE.body>Max Gas Fee</TYPE.body>*/}
                  {/*        </AutoColumn>*/}

                  {/*        <AutoColumn justify="center">*/}
                  {/*          <TYPE.body>*/}
                  {/*            {feeFraction && tokenA ? (*/}
                  {/*              <>*/}
                  {/*                {feeTokenSymbol === 'CON-V2' && pair.liquidityToken*/}
                  {/*                  ? `${feeFraction.toFixed(pair.liquidityToken.decimals)} ${*/}
                  {/*                      pair.liquidityToken.symbol*/}
                  {/*                    }`*/}
                  {/*                  : `${feeFraction.toFixed(tokenA.decimals)} ${feeTokenSymbol}`}*/}
                  {/*              </>*/}
                  {/*            ) : (*/}
                  {/*              '-'*/}
                  {/*            )}*/}
                  {/*          </TYPE.body>*/}
                  {/*        </AutoColumn>*/}
                  {/*      </AutoRow>*/}
                  {/*    </div>*/}
                  {/*  ) : (*/}
                  {/*    <TransactionFeeCard*/}
                  {/*      currency={*/}
                  {/*        userFeeCurrency*/}
                  {/*          ? userFeeCurrency*/}
                  {/*          : feeTokenSymbol === 'CON-V2' && pair*/}
                  {/*          ? pair.liquidityToken*/}
                  {/*          : tokenA*/}
                  {/*      }*/}
                  {/*      gasFee={feeFraction}*/}
                  {/*      isFetchingFee={false}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</AutoColumn>*/}

                  <TransactionFeeCard
                    currency={
                      userFeeCurrency
                        ? userFeeCurrency
                        : feeTokenSymbol === 'CON-V2' && pair
                        ? pair.liquidityToken
                        : tokenA
                    }
                    gasFee={feeFraction}
                    onFeeSelect={handleFeeSelect}
                    isFetchingFee={isFetchingFee}
                    inputIsValid={isValid}
                  />

                  <div style={{ padding: '10px 20px' }}>
                    <RowBetween>
                      Price:
                      <div>
                        1 {originalCurrencyASymbol} ={' '}
                        {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                        {originalCurrencyBSymbol}
                      </div>
                    </RowBetween>
                    <RowBetween>
                      <div />
                      <div>
                        1 {originalCurrencyBSymbol} ={' '}
                        {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                        {originalCurrencyASymbol}
                      </div>
                    </RowBetween>
                  </div>
                </>
              )}
              <div style={{ position: 'relative' }}>
                {!account ? (
                  <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                ) : showFeeTokenApproval ? (
                  <ButtonPrimary
                    borderRadius="12px"
                    padding={'12px'}
                    onClick={approveFeeTokenCallback}
                    disabled={feeTokenApproval === ApprovalState.PENDING}
                    // width={showApprovalA ? '48%' : '100%'}
                  >
                    {feeTokenApproval === ApprovalState.PENDING
                      ? `Approving ${feeTokenSymbol}`
                      : `Approve ${feeTokenSymbol}`}
                  </ButtonPrimary>
                ) : (
                  <RowBetween>
                    <ButtonConfirmed
                      onClick={onAttemptToApprove}
                      // confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                      // disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                      confirmed={signatureData !== null}
                      disabled={signatureData !== null}
                      mr="0.5rem"
                      fontWeight={500}
                      fontSize={16}
                    >
                      {/*{approval === ApprovalState.PENDING ? (*/}
                      {/*  <Dots>Approving</Dots>*/}
                      {/*) : approval === ApprovalState.APPROVED || signatureData !== null ? (*/}
                      {/*  'Approved'*/}
                      {/*) : (*/}
                      {/*  'Approve'*/}
                      {/*)}*/}
                      {signatureData !== null ? 'Approved' : 'Approve Permit'}
                    </ButtonConfirmed>
                    <ButtonError
                      onClick={() => {
                        isExpertMode
                          ? onRemove()
                          : (() => {
                              setErrorMessage('')
                              setShowConfirm(true)
                            })()
                      }}
                      disabled={!isValid || signatureData === null}
                      error={
                        !isValid &&
                        !!parsedAmounts[Field.CURRENCY_A] &&
                        !!parsedAmounts[Field.CURRENCY_B]
                      }
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {error || 'Remove'}
                      </Text>
                    </ButtonError>
                  </RowBetween>
                )}
                {isExpertMode && errorMessage ? (
                  <div>
                    <SwapCallbackError error={errorMessage} />
                  </div>
                ) : null}
              </div>
            </AutoColumn>
          </Wrapper>
        </AppBody>
      </AppBodyContainer>

      {pair ? (
        <AutoColumn
          style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}
        >
          <MinimalPositionCard showUnwrapped={false} pair={pair} />
        </AutoColumn>
      ) : null}
    </>
  )
}
