import Modal from 'components/Modal'
import React from 'react'
import { ApplicationModal } from 'state/application/actions'
import { useCloseModals, useModalOpen } from 'state/application/hooks'
import styled from 'styled-components'

import { ReactComponent as Close } from '../../assets/images/x.svg'

export function SunsetModal() {
  const isModalOpen = useModalOpen(ApplicationModal.SUNSET)
  const closeModal = useCloseModals()

  return (
    <Modal isOpen={isModalOpen} onDismiss={closeModal} minHeight={false} maxHeight={90}>
      <Wrapper>
        <UpperSection>
          <CloseIcon onClick={closeModal}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>SUNSET NOTIFICATION</HeaderRow>
          <ContentWrapper>
            <p>
              Starting from July 1, Xata will no longer be available to users. As we sunset Xata,
              we&lsquo;ll be taking a close look at our learnings during this time, evaluating what
              works, and re-prioritizing our efforts to build a privacy-protected and fair Web3.
            </p>
            <p>
              While we will no longer actively support Xata, there are no immediate changes to
              access or functionality. Make sure to backup any relevant data or information in this
              transition phase.
            </p>
            <p>
              Drop us a message on{' '}
              <a href="http://ata.ws/discord" target="_blank" rel="noopener noreferrer">
                Discord
              </a>{' '}
              if you have any questions.
            </p>
          </ContentWrapper>
        </UpperSection>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`
const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`
const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`
const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`
const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  line-height: 1.25em;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};

  p {
    margin-block: 0 1em;
    text-align: justify;
    hyphens: auto;
    &:last-child {
      margin-block-end: 0;
    }
  }

  a {
    outline: none;
  }
`
