import {
  ADD_LIQUIDITY_GAS_LIMIT,
  ARBITRUM_ADD_LIQUIDITY_GAS_LIMIT,
  ARBITRUM_CREATE_PAIR_GAS_LIMIT,
  ARBITRUM_HOP_ADDITIONAL_GAS,
  ARBITRUM_REMOVE_LIQUIDITY_GAS_LIMIT,
  ARBITRUM_SWAP_GAS_LIMIT,
  ChainId,
  CREATE_PAIR_GAS_LIMIT,
  GasLimit,
  HOP_ADDITIONAL_GAS,
  REMOVE_LIQUIDITY_GAS_LIMIT,
  SWAP_GAS_LIMIT
} from '@xatadev/sdk'
import { useCallback, useMemo } from 'react'
import { useActiveWeb3React } from './index'
import { BigNumberish } from '@ethersproject/bignumber'
import { useIsExpertMode, useUserLiquidityGasLimit, useUserSwapGasLimit } from '../state/user/hooks'

// Fallback value when dependencies are not ready yet, for example the chain id value
// probably undefined when app still loaded
const GAS_LIMIT_FALLBACK = 9_900_000

const defaultGasLimitMap: { [key: string]: { [gasType in GasLimit]: number } } = {
  general: {
    [GasLimit.HOP]: SWAP_GAS_LIMIT,
    [GasLimit.SWAP]: HOP_ADDITIONAL_GAS,
    [GasLimit.CREATE_PAIR]: CREATE_PAIR_GAS_LIMIT,
    [GasLimit.LIQUIDITY_ADD]: ADD_LIQUIDITY_GAS_LIMIT,
    [GasLimit.LIQUIDITY_REMOVE]: REMOVE_LIQUIDITY_GAS_LIMIT
  },
  arbitrum: {
    [GasLimit.HOP]: ARBITRUM_HOP_ADDITIONAL_GAS,
    [GasLimit.SWAP]: ARBITRUM_SWAP_GAS_LIMIT,
    [GasLimit.CREATE_PAIR]: ARBITRUM_CREATE_PAIR_GAS_LIMIT,
    [GasLimit.LIQUIDITY_ADD]: ARBITRUM_ADD_LIQUIDITY_GAS_LIMIT,
    [GasLimit.LIQUIDITY_REMOVE]: ARBITRUM_REMOVE_LIQUIDITY_GAS_LIMIT
  }
}

/**
 * Get default gas limit value from the default gas limit map
 */
export const getDefaultGasLimit = (chainId: ChainId | undefined, gasType: GasLimit) => {
  if (!chainId) return GAS_LIMIT_FALLBACK

  if (chainId === ChainId.ARBITRUM) return defaultGasLimitMap.arbitrum[gasType]

  return defaultGasLimitMap.general[gasType]
}

const useGasLimit = () => {
  const { chainId } = useActiveWeb3React()
  const isExpertMode = useIsExpertMode()

  const [userSwapGasLimit, setUserSwapGasLimit] = useUserSwapGasLimit()
  const [userLiquidityGasLimit, setUserLiquidityGasLimit] = useUserLiquidityGasLimit()

  const gasLimit = useMemo(() => {
    if (!chainId) return { ...defaultGasLimitMap.general }

    const values = chainId === ChainId.ARBITRUM ? { ...defaultGasLimitMap.arbitrum } : { ...defaultGasLimitMap.general }

    if (isExpertMode) {
      if (userSwapGasLimit !== values[GasLimit.SWAP]) {
        values[GasLimit.SWAP] = userSwapGasLimit
      }
      if (userLiquidityGasLimit !== values[GasLimit.LIQUIDITY_ADD]) {
        values[GasLimit.LIQUIDITY_ADD] = userLiquidityGasLimit
      }
    }

    return values
  }, [chainId, isExpertMode, userLiquidityGasLimit, userSwapGasLimit])

  const updateUserGasLimit = useCallback(
    (gasType: GasLimit, value: number | BigNumberish) => {
      if (chainId) {
        switch (gasType) {
          case GasLimit.SWAP:
            setUserSwapGasLimit(Number(value))
            break

          case GasLimit.CREATE_PAIR:
          case GasLimit.LIQUIDITY_ADD:
          case GasLimit.LIQUIDITY_REMOVE:
            setUserLiquidityGasLimit(Number(value))
            break

          default:
            break
        }
      }
    },
    [chainId, setUserLiquidityGasLimit, setUserSwapGasLimit]
  )

  const resetUserGasLimit = useCallback(
    gasType => {
      const value = getDefaultGasLimit(chainId, gasType)
      updateUserGasLimit(gasType, value)
    },
    [chainId, updateUserGasLimit]
  )

  return { gasLimit, updateUserGasLimit, resetUserGasLimit }
}

export default useGasLimit
