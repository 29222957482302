import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { useAddLiquidityState } from '../../state/AddLiquidity/hooks'
import { useToggleSettingsMenu } from '../../state/application/hooks'
import { Field as RemoveLiquidityField } from '../../state/burn/actions'
import {
  addLiquitityFeeInTokens,
  feeTokenSymbol,
  removeLiquidityFeeInTokens,
  swapFeeInTokens
} from '../../state/fee/actions'
import { Field as AddLiquidityField } from '../../state/mint/actions'
import { useRemoveLiquidityState } from '../../state/RemoveLiquidity/hooks'
import { Field as SwapField } from '../../state/swap/actions'
import { useSwapState } from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserFeeTokens,
  useUserSingleHopOnly,
  useUserSlippageTolerance,
  useUserTransactionTTL,
  useUserUseCustomFeeToken
} from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'

type MenuWrapperAttributes = {
  isDesktop: boolean
}

const MenuWrapper = styled.span.attrs((props: MenuWrapperAttributes) => ({
  isDesktop: props.isDesktop
}))`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    // min-width: 20.125rem;
    min-width: 18.125rem;
    background-color: ${({ theme }) => theme.bg2};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    position: absolute;
    top: 3rem;
    right: 0rem;
    z-index: 100;
  `}

  position: relative;
`

type MenuFlyoutProps = {
  // toggle: () => void
  onShowConfirmation: (value: boolean) => void
  isDesktop?: boolean
}

const MenuFlyout = ({ onShowConfirmation, isDesktop = false }: MenuFlyoutProps) => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<AppDispatch>()

  const { chainId } = useActiveWeb3React()
  const { pathname } = useLocation()

  const toggle = useToggleSettingsMenu()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [, setUserFeeTokens] = useUserFeeTokens()
  const [userUseCustomFeeToken, setUserUseCustomFeeToken] = useUserUseCustomFeeToken()
  const swapState = useSwapState()
  const addLiquidityState = useAddLiquidityState()
  const removeLiquidityState = useRemoveLiquidityState()
  // console.debug(userFeeTokens, setUserFeeTokens)

  const handleShowConfirmation = (show: boolean) => {
    onShowConfirmation(show)
  }

  const onCustomFeeTokenToggle = useCallback(() => {
    if (!chainId) return

    setUserUseCustomFeeToken(!userUseCustomFeeToken)

    if (userUseCustomFeeToken) {
      let feeTokenAddress: string | undefined
      if (pathname === '/swap') {
        feeTokenAddress = swapState[SwapField.INPUT].currencyId
        dispatch(swapFeeInTokens(undefined))
      } else if (pathname === '/add') {
        feeTokenAddress = addLiquidityState[AddLiquidityField.CURRENCY_A].currencyId
        dispatch(addLiquitityFeeInTokens(undefined))
      } else if (pathname === '/remove') {
        feeTokenAddress = removeLiquidityState[RemoveLiquidityField.CURRENCY_A].currencyId
        dispatch(removeLiquidityFeeInTokens(undefined))
      }

      if (feeTokenAddress) {
        setUserFeeTokens(chainId, feeTokenAddress)
        dispatch(feeTokenSymbol(undefined))
      }
    }
  }, [
    addLiquidityState,
    chainId,
    dispatch,
    pathname,
    removeLiquidityState,
    setUserFeeTokens,
    setUserUseCustomFeeToken,
    swapState,
    userUseCustomFeeToken
  ])

  return (
    <MenuWrapper isDesktop={isDesktop}>
      <AutoColumn gap="md" style={{ padding: isDesktop ? '1rem 24px' : '1rem' }}>
        <Text fontWeight={500} fontSize={14} color={theme.isDarkMode ? '#B0B0B0' : '#7D7D7D'}>
          Transaction Settings
        </Text>
        <TransactionSettings
          rawSlippage={userSlippageTolerance}
          setRawSlippage={setUserslippageTolerance}
          deadline={ttl}
          setDeadline={setTtl}
        />
        <Text
          fontWeight={500}
          fontSize={14}
          color={theme.isDarkMode ? '#B0B0B0' : '#7D7D7D'}
          marginTop={'4px'}
        >
          Interface Settings
        </Text>
        <RowBetween
          flexDirection={isDesktop ? 'column' : 'row'}
          alignItems={isDesktop ? 'flex-start !important' : 'center'}
        >
          <RowFixed>
            <TYPE.black
              fontWeight={400}
              fontSize={14}
              color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}
            >
              Toggle Expert Mode
            </TYPE.black>
            <QuestionHelper text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk." />
          </RowFixed>
          <Toggle
            id="toggle-expert-mode-button"
            isActive={expertMode}
            toggle={
              expertMode
                ? () => {
                    toggleExpertMode()
                    handleShowConfirmation(false)
                  }
                : () => {
                    if (!isDesktop) toggle()
                    handleShowConfirmation(true)
                  }
            }
          />
        </RowBetween>
        <RowBetween
          flexDirection={isDesktop ? 'column' : 'row'}
          alignItems={isDesktop ? 'flex-start !important' : 'center'}
        >
          <RowFixed>
            <TYPE.black
              fontWeight={400}
              fontSize={14}
              color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}
            >
              Disable Multihops
            </TYPE.black>
            <QuestionHelper text="Restricts swaps to direct pairs only." />
          </RowFixed>
          <Toggle
            id="toggle-disable-multihop-button"
            isActive={singleHopOnly}
            toggle={() => (singleHopOnly ? setSingleHopOnly(false) : setSingleHopOnly(true))}
          />
        </RowBetween>
        <RowBetween
          flexDirection={isDesktop ? 'column' : 'row'}
          alignItems={isDesktop ? 'flex-start !important' : 'center'}
        >
          <RowFixed>
            <TYPE.black
              fontWeight={400}
              fontSize={14}
              color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}
            >
              Custom Fee Token
            </TYPE.black>
            <QuestionHelper text="When this option turned on, you will be able to select custom token for paying fee." />
          </RowFixed>
          <Toggle
            id="toggle-custom-fee-token"
            isActive={userUseCustomFeeToken}
            toggle={onCustomFeeTokenToggle}
          />
        </RowBetween>
      </AutoColumn>
    </MenuWrapper>
  )
}

export default MenuFlyout
