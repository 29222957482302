import styled, { ThemeContext } from 'styled-components'
import React, { useContext, useRef, useState } from 'react'

import { ReactComponent as MagnifyingGlass } from '../../assets/svg/iconset/search.svg'

import { InputWrap, ToolWrap, ToolLabel } from './Common'

type SearchProps = {
  row?: number
  column?: number
  columnSpan?: number
  onQueryChange: (query: string) => void
}

const SearchInputWrap = styled(InputWrap)`
  cursor: text;

  > input {
    width: 100%;
    border: none;
    background: none;
    appearance: none;
    font-size: 1rem;
    color: ${({ theme }) => theme.text1};
    outline: none;
    margin-left: 9px;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${({ theme }) => theme.farms.textSub};
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: ${({ theme }) => theme.farms.textSub};
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: ${({ theme }) => theme.farms.textSub};
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: ${({ theme }) => theme.farms.textSub};
    }
  }
`

function Search({ row, column, columnSpan, onQueryChange }: SearchProps) {
  const theme = useContext(ThemeContext)

  const [searchQuery, setSearchQuery] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)
  const handleInputWrapClick = () => {
    if (inputRef.current === null) return

    inputRef.current.focus()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchQuery(value)

    onQueryChange(value)
  }

  return (
    <ToolWrap row={row} column={column} columnSpan={columnSpan}>
      <ToolLabel>search:</ToolLabel>
      <SearchInputWrap onClick={handleInputWrapClick}>
        <MagnifyingGlass fill={theme.farms.textSub} width={16} height={16} />
        <input
          type="text"
          placeholder="Search within our farm list..."
          value={searchQuery}
          ref={inputRef}
          onChange={handleInputChange}
        />
      </SearchInputWrap>
    </ToolWrap>
  )
}

export default Search
