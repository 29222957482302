import { ChainId } from '@xatadev/sdk'
import serializeToken, { SerializedToken } from 'utils/serializeToken'

import { ATA, USDT } from './tokens'

export type SerializedFarm = {
  symbol: string
  address: string
  tokenA: SerializedToken
  tokenB: SerializedToken
  isMulti: boolean
}

export type FarmsDataMap = { [chainId in ChainId]?: SerializedFarm[] }

export const FARMS: FarmsDataMap = {
  [ChainId.BSC]: [
    {
      address: '0x3dD417Ff4144bE35f203CB2CA569adF01Cd3574a',
      isMulti: true,
      symbol: 'ATA/USDT',
      tokenA: serializeToken(ATA[ChainId.BSC]),
      tokenB: serializeToken(USDT[ChainId.BSC])
    }
  ],
  [ChainId.MATIC]: [
    {
      address: '0x3dD417Ff4144bE35f203CB2CA569adF01Cd3574a',
      isMulti: true,
      symbol: 'ATA/USDT',
      tokenA: serializeToken(ATA[ChainId.MATIC]),
      tokenB: serializeToken(USDT[ChainId.MATIC])
    }
    // {
    //   symbol: 'WMATIC/DAI',
    //   address: '0x97f74cc51ae5a037acdb09f8c1eae74c5b771067',
    //   tokenA: serializeToken(WNATIVE[ChainId.MATIC]),
    //   tokenB: serializeToken(DAI[ChainId.MATIC]),
    //   isMulti: false
    // },
    // {
    //   symbol: 'WMATIC/DAI',
    //   address: '0x0b8d0f84767a76b15918ddb377df8d6052c5c3fd',
    //   tokenA: serializeToken(WNATIVE[ChainId.MATIC]),
    //   tokenB: serializeToken(DAI[ChainId.MATIC]),
    //   isMulti: false
    // },
    // {
    //   symbol: 'WMATIC/WETH',
    //   address: '0xCf4c0AD387E555eEEF779dCfA848d34086183E76',
    //   tokenA: serializeToken(WNATIVE[ChainId.MATIC]),
    //   tokenB: serializeToken(WETH9[ChainId.MATIC]),
    //   isMulti: true
    // },
    // {
    //   symbol: 'WMATIC/DAI',
    //   address: '0x4BE4E30aa6DcFad5F0600379121D20e2c6577377',
    //   tokenA: serializeToken(WNATIVE[ChainId.MATIC]),
    //   tokenB: serializeToken(DAI[ChainId.MATIC]),
    //   isMulti: true
    // },
    // {
    //   symbol: 'SUSHI/WMATIC',
    //   address: '0x85b2E2c12B49B2923082d4A8784703c6E8850e41',
    //   tokenA: serializeToken(SUSHI[ChainId.MATIC]),
    //   tokenB: serializeToken(WNATIVE[ChainId.MATIC]),
    //   isMulti: true
    // },
    // {
    //   symbol: 'ATA/MWATIC',
    //   address: '0xBf137d05CdFCDA8D4975018eDcb1B70aAA56D296',
    //   tokenA: serializeToken(ATA[ChainId.MATIC]),
    //   tokenB: serializeToken(WNATIVE[ChainId.MATIC]),
    //   isMulti: true
    // }
  ]
}
