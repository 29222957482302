import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'
import { Currency, Ether, Token } from '@xatadev/sdk'
import { selectCurrency, Field } from './actions'
import { useCallback } from 'react'

export function useAddLiquidityState(): AppState['addliquidity'] {
  return useSelector<AppState, AppState['addliquidity']>(state => state.addliquidity)
}

export function useAddliquidityActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency | undefined) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency | undefined) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token ? currency.address : currency === Ether.onChain(currency?.chainId!) ? 'ETH' : ''
        })
      )
    },
    [dispatch]
  )

  return {
    onCurrencySelection
  }
}
