import {
  calculateFee,
  calculateFeeThenConvert,
  ChainId,
  Currency,
  NATIVE,
  Token
} from '@xatadev/sdk'
import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useApproveCallback } from '../../hooks/useApproveCallback'
import { useConveyorV2RouterContract } from '../../hooks/useContract'
import useEnvironment from '../../hooks/useEnvironment'
import { AppState } from '../index'
import { useUserFeeTokens, useUserUseCustomFeeToken } from '../user/hooks'
import { useTokenBalance } from '../wallet/hooks'
// import { feeTokenSymbol, isFetching as isFetchingFee, swapFeeInTokens } from './actions'

export const useGasFee = () => {
  // const dispatch = useDispatch<AppDispatch>()
  const feeState = useSelector<AppState, AppState['fee']>(state => state.fee)

  const { account, chainId, library } = useActiveWeb3React()

  const environment = useEnvironment()

  const routerContract = useConveyorV2RouterContract()

  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [userFeeTokens] = useUserFeeTokens()
  const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  const feeTokenAmount = useTokenBalance(
    account ?? undefined,
    userUseCustomFeeToken && userFeeCurrency ? userFeeCurrency.wrapped : undefined
  )
  const [feeTokenApproval, approveFeeTokenCallback] = useApproveCallback(
    feeTokenAmount,
    userUseCustomFeeToken && userFeeCurrency ? routerContract?.address : undefined
  )

  const fetchGasFeeInToken = useCallback(
    (gasLimit: BigNumber, defaultFeeToken: Token | Currency | undefined) => {
      if (!library) return

      return library.getGasPrice().then(async (gasPrice: BigNumber) => {
        console.log('fetch called')
        if (!gasPrice) return

        const gasFee = gasPrice.mul(gasLimit)
        let maxGasFeeInToken = BigNumber.from(0)

        const token: Token | undefined =
          userUseCustomFeeToken && userFeeCurrency
            ? userFeeCurrency.wrapped
            : defaultFeeToken?.wrapped

        const [tokenAddress, tokenSymbol] = [token?.address, token?.symbol]
        const tokenDecimals = token?.decimals ? BigNumber.from(token.decimals) : undefined

        if (tokenAddress && tokenSymbol && tokenDecimals) {
          switch (chainId) {
            case ChainId.MATIC:
            case ChainId.MOONRIVER:
              maxGasFeeInToken = await calculateFeeThenConvert(
                chainId,
                tokenAddress,
                tokenDecimals,
                gasFee,
                environment
              )
              break
            case ChainId.BSC:
              maxGasFeeInToken = await calculateFee(
                chainId,
                tokenAddress,
                tokenDecimals,
                gasFee,
                NATIVE[ChainId.BSC].symbol?.toLowerCase() || 'bnb',
                NATIVE[ChainId.BSC].decimals,
                environment
              )
              break
            case ChainId.ARBITRUM:
              maxGasFeeInToken = await calculateFee(
                chainId,
                tokenAddress,
                tokenDecimals,
                gasFee,
                NATIVE[ChainId.ARBITRUM].symbol?.toLowerCase() || 'eth',
                NATIVE[ChainId.ARBITRUM].decimals,
                environment
              )
              break
          }
        }

        return { maxGasFeeInToken, tokenSymbol }
      })
    },
    [chainId, environment, library, userFeeCurrency, userUseCustomFeeToken]
  )

  return {
    ...feeState,
    approveFeeTokenCallback,
    feeTokenApproval,
    fetchGasFeeInToken
  }
}
