import { darken } from 'polished'
import { GasLimit } from '@xatadev/sdk'
import { useLocation } from 'react-router'
import { useActiveWeb3React } from '../../hooks'
import { useIsExpertMode } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components'
import { useWindowSize } from '../../hooks/useWindowSize'
import useGasLimit, { getDefaultGasLimit } from '../../hooks/useGasLimit'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import QuestionHelper from '../QuestionHelper'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh'
}

enum DeadlineError {
  InvalidInput = 'InvalidInput'
}

enum GasLimitError {
  InvalidInput = 'InvalidInput'
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) => active && theme.primary1};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
`

const SlippageOption = styled(Option)`
  margin-right: 5px;
  min-width: unset;
  width: 31%;
  height: 23px;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1;
  padding-top: 3px;
  padding-bottom: 4px;
  color: ${({ active, theme }) => (active ? theme.white : theme.isDarkMode ? theme.text1 : '#b0b0b0')};

  &:nth-child(3) {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 3.5rem;
    // margin-right: 8px;
    // width: auto;
    overflow: auto;
    // font-size: 1rem;

    // &:nth-child(3) {
    //   margin-right: 8px;
    // }
  `}
`

const DefaultGasOption = styled(SlippageOption)`
  width: auto;
`

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  // height: 2rem;
  // position: relative;
  // padding: 0 0.75rem;
  // flex: 1;
  // border: ${({ theme, active, warning }) => active && `1px solid ${warning ? theme.red1 : theme.primary1}`};
  height: 23px;
  width: 35%;
  font-size: 0.875rem;
  line-height: 1;
  padding-top: 3px;
  padding-bottom: 4px;
  
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 2rem;
    font-size: 0.875rem;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #DEDEDE;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #DEDEDE;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #DEDEDE;
    }
  }
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
}

export default function SlippageTabs({ rawSlippage, setRawSlippage, deadline, setDeadline }: SlippageTabsProps) {
  const { chainId } = useActiveWeb3React()

  const theme = useContext(ThemeContext)

  const inputRef = useRef<HTMLInputElement>()

  const location = useLocation()

  const isExpertMode = useIsExpertMode()

  // const environment = useEnvironment()

  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const { gasLimit, updateUserGasLimit, resetUserGasLimit } = useGasLimit()
  console.log('gasLimit', gasLimit)
  // const [userSwapGasLimit, setUserSwapGasLimit] = useUserSwapGasLimit()
  const [swapGasLimitInput, setSwapGasLimitInput] = useState<string>(gasLimit[GasLimit.SWAP].toString())
  const [swapGasLimitError, setSwapGasLimitError] = useState<GasLimitError | false>(false)

  // const [userLiquidityGasLimit, setUserLiquidityGasLimit] = useUserLiquidityGasLimit()
  const [liquidityGasLimitInput, setLiquidityGasLimitInput] = useState<string>(
    gasLimit[GasLimit.LIQUIDITY_ADD].toString()
  )
  const [liquidityGasLimitError, setLiquidityGasLimitError] = useState<GasLimitError | false>(false)

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (deadline / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  useEffect(() => {
    if (!isExpertMode) {
      // if (userSwapGasLimit !== GAS_LIMIT[environment].swap) {
      //   setUserSwapGasLimit(GAS_LIMIT[environment].swap)
      // }
      resetUserGasLimit(GasLimit.SWAP)

      // if (location.pathname.includes('/add') && userLiquidityGasLimit !== GAS_LIMIT[environment].addLiquidity) {
      //   setUserLiquidityGasLimit(GAS_LIMIT[environment].addLiquidity)
      // }
      //
      // if (location.pathname.includes('/create') && userLiquidityGasLimit !== GAS_LIMIT[environment].createPair) {
      //   setUserLiquidityGasLimit(GAS_LIMIT[environment].createPair)
      // }
      const { pathname } = location
      if (pathname.includes('/create')) {
        resetUserGasLimit(GasLimit.CREATE_PAIR)
      } else {
        resetUserGasLimit(GasLimit.LIQUIDITY_ADD)
      }
    }
  }, [isExpertMode, location, resetUserGasLimit])

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
        setRawSlippage(valueAsIntFromRoundedFloat)
      }
    } catch {}
  }

  function parseCustomDeadline(value: string) {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setDeadline(valueAsInt)
      }
    } catch {}
  }

  function parseCustomSwapGasLimit(value: string) {
    setSwapGasLimitInput(value)
    setSwapGasLimitError(false)

    if (value.length === 0) {
      // setUserSwapGasLimit(GAS_LIMIT[environment].swap)
      resetUserGasLimit(GasLimit.SWAP)
    } else {
      try {
        const parsed = Number(value)
        if (!Number.isInteger(parsed) || !Number.isSafeInteger(parsed) || parsed < 0) {
          setSwapGasLimitError(GasLimitError.InvalidInput)
        } else {
          // setUserSwapGasLimit(parsed)
          updateUserGasLimit(GasLimit.SWAP, parsed)
        }
      } catch (error) {
        console.error(error)
        setSwapGasLimitError(GasLimitError.InvalidInput)
      }
    }
  }

  function parseCustomLiquidityGasLimit(value: string) {
    setLiquidityGasLimitInput(value)
    setLiquidityGasLimitError(false)

    if (value.length === 0) {
      // setUserLiquidityGasLimit(
      //   location.pathname.includes('/create') ? GAS_LIMIT[environment].createPair : GAS_LIMIT[environment].addLiquidity
      // )
      const { pathname } = location
      if (pathname.includes('/add')) {
        resetUserGasLimit(GasLimit.LIQUIDITY_ADD)
      } else if (pathname.includes('/create')) {
        resetUserGasLimit(GasLimit.CREATE_PAIR)
      }
    } else {
      try {
        const parsed = Number(value)
        if (!Number.isInteger(parsed) || !Number.isSafeInteger(parsed) || parsed < 0) {
          setLiquidityGasLimitError(GasLimitError.InvalidInput)
        } else {
          // setUserLiquidityGasLimit(parsed)
          updateUserGasLimit(GasLimit.LIQUIDITY_ADD, parsed)
        }
      } catch (error) {
        console.error(error)
        setLiquidityGasLimitError(GasLimitError.InvalidInput)
      }
    }
  }

  const windowSize = useWindowSize()
  const isDesktop = useMemo(() => windowSize.width && windowSize.width > 960, [windowSize.width])

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowFixed>
          <TYPE.black fontWeight={400} fontSize={14} color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}>
            Slippage tolerance
          </TYPE.black>
          <QuestionHelper text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
        </RowFixed>
        <RowBetween flexWrap={'wrap'}>
          <SlippageOption
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(10)
            }}
            active={rawSlippage === 10}
          >
            0.1%
          </SlippageOption>
          <SlippageOption
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(50)
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </SlippageOption>
          <SlippageOption
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(100)
            }}
            active={rawSlippage === 100}
          >
            1%
          </SlippageOption>
          <div
            style={{
              display: 'flex',
              marginTop: '8px'
              // flexGrow: isDesktop ? 0.58 : 1
            }}
          >
            <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
              <RowBetween color={!slippageInputIsValid ? 'red' : !slippageInput ? '#DEDEDE' : theme.text1}>
                {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
                <Input
                  ref={inputRef as any}
                  placeholder={(rawSlippage / 100).toFixed(2)}
                  value={slippageInput}
                  onBlur={() => {
                    parseCustomSlippage((rawSlippage / 100).toFixed(2))
                  }}
                  onChange={e => parseCustomSlippage(e.target.value)}
                  color={!slippageInputIsValid ? 'red' : theme.text1}
                />
                %
              </RowBetween>
            </OptionCustom>
            <TYPE.body style={{ alignSelf: 'center', paddingLeft: '8px', color: '#B2B2B2' }} fontSize={12}>
              Insert Value
            </TYPE.body>
          </div>
        </RowBetween>
        {!!slippageError && (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E',
              alignItems: 'baseline'
            }}
          >
            {slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh ? (
              <SlippageEmojiContainer>
                <span role="img" aria-label="warning">
                  ⚠️
                </span>
              </SlippageEmojiContainer>
            ) : null}
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </RowBetween>
        )}
      </AutoColumn>

      <AutoColumn gap="sm">
        <RowFixed>
          <TYPE.black fontSize={14} fontWeight={400} color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}>
            Transaction deadline
          </TYPE.black>
          <QuestionHelper text="Your transaction will revert if it is pending for more than this long." />
        </RowFixed>
        <RowFixed fontSize={isDesktop ? '0.875rem' : '1rem'}>
          <OptionCustom tabIndex={-1}>
            <Input
              color={!!deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((deadline / 60).toString())
              }}
              placeholder={(deadline / 60).toString()}
              value={deadlineInput}
              onChange={e => parseCustomDeadline(e.target.value)}
            />
          </OptionCustom>
          <TYPE.body style={{ paddingLeft: '8px', color: '#B2B2B2' }} fontSize={12}>
            Minutes
          </TYPE.body>
        </RowFixed>
      </AutoColumn>

      {isExpertMode && (
        <>
          <AutoColumn gap="sm">
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}>
                Swap gas limit
              </TYPE.black>

              <QuestionHelper text="Gas limit for Swap operation. Your transaction will revert if the limitation is not enough." />
            </RowFixed>
            <RowBetween fontSize={isDesktop ? '0.875rem' : '1rem'} justifyContent={'flex-start !important'}>
              <OptionCustom style={{ width: '50%' }} tabIndex={-1}>
                <Input
                  // placeholder={GAS_LIMIT[environment].swap.toString()}
                  placeholder={gasLimit[GasLimit.SWAP].toString()}
                  value={swapGasLimitInput}
                  onBlur={e => parseCustomSwapGasLimit(e.target.value)}
                  onChange={e => parseCustomSwapGasLimit(e.target.value)}
                  color={swapGasLimitError === GasLimitError.InvalidInput ? 'red' : ''}
                />
              </OptionCustom>
              <DefaultGasOption
                onClick={() => {
                  setSwapGasLimitInput('')
                  // setUserSwapGasLimit(GAS_LIMIT[environment].swap)
                  resetUserGasLimit(GasLimit.SWAP)
                }}
                active={gasLimit[GasLimit.SWAP] === getDefaultGasLimit(chainId, GasLimit.SWAP)}
                style={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  marginLeft: 8
                  // marginRight: 0
                }}
              >
                Default
              </DefaultGasOption>
            </RowBetween>
            {swapGasLimitError ? (
              <RowBetween
                style={{
                  fontSize: '14px',
                  paddingTop: '7px',
                  color: swapGasLimitError === GasLimitError.InvalidInput ? 'red' : '#F3841E'
                }}
              >
                {swapGasLimitError === GasLimitError.InvalidInput && 'Enter a valid gas number'}
              </RowBetween>
            ) : null}
          </AutoColumn>

          <AutoColumn gap="sm">
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.isDarkMode ? '#7D7D7D' : '#B0B0B0'}>
                Liquidity gas limit
              </TYPE.black>

              <QuestionHelper text="Gas limit for Liquidity operation, applied on add and remove. Your transaction will revert if the limitation is not enough." />
            </RowFixed>
            <RowBetween fontSize={isDesktop ? '0.875rem' : '1rem'} justifyContent={'flex-start !important'}>
              <OptionCustom style={{ width: '50%' }} tabIndex={-1}>
                <Input
                  placeholder={
                    // location.pathname.includes('/create')
                    //   ? GAS_LIMIT[environment].createPair.toString()
                    //   : GAS_LIMIT[environment].addLiquidity.toString()
                    gasLimit[
                      location.pathname.includes('/create') ? GasLimit.CREATE_PAIR : GasLimit.LIQUIDITY_ADD
                    ].toString()
                  }
                  value={liquidityGasLimitInput}
                  onBlur={e => parseCustomLiquidityGasLimit(e.target.value)}
                  onChange={e => parseCustomLiquidityGasLimit(e.target.value)}
                  color={liquidityGasLimitError === GasLimitError.InvalidInput ? 'red' : ''}
                />
              </OptionCustom>
              <DefaultGasOption
                onClick={() => {
                  setLiquidityGasLimitInput('')
                  // setUserLiquidityGasLimit(
                  //   location.pathname.includes('/create')
                  //     ? GAS_LIMIT[environment].createPair
                  //     : GAS_LIMIT[environment].addLiquidity
                  // )
                  resetUserGasLimit(
                    location.pathname.includes('/create')
                      ? GasLimit.CREATE_PAIR
                      : location.pathname.includes('/remove')
                      ? GasLimit.LIQUIDITY_REMOVE
                      : GasLimit.LIQUIDITY_ADD
                  )
                }}
                // active={
                //   (location.pathname.includes('/create') &&
                //     userLiquidityGasLimit === GAS_LIMIT[environment].createPair) ||
                //   (location.pathname.includes('/add') &&
                //     userLiquidityGasLimit === GAS_LIMIT[environment].addLiquidity) ||
                //   (!location.pathname.includes('/create') &&
                //     !location.pathname.includes('/add') &&
                //     (userLiquidityGasLimit === GAS_LIMIT[environment].createPair ||
                //       userLiquidityGasLimit === GAS_LIMIT[environment].addLiquidity))
                // }
                active={
                  (location.pathname.includes('/create') &&
                    gasLimit[GasLimit.LIQUIDITY_ADD] === getDefaultGasLimit(chainId, GasLimit.CREATE_PAIR)) ||
                  (location.pathname.includes('/add') &&
                    gasLimit[GasLimit.LIQUIDITY_ADD] === getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_ADD)) ||
                  (location.pathname.includes('/remove') &&
                    gasLimit[GasLimit.LIQUIDITY_ADD] === getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_REMOVE)) ||
                  (!location.pathname.includes('/create') &&
                    !location.pathname.includes('/add') &&
                    !location.pathname.includes('/remove') &&
                    (gasLimit[GasLimit.LIQUIDITY_ADD] === getDefaultGasLimit(chainId, GasLimit.CREATE_PAIR) ||
                      gasLimit[GasLimit.LIQUIDITY_ADD] === getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_ADD) ||
                      gasLimit[GasLimit.LIQUIDITY_ADD] === getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_REMOVE)))
                }
                style={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  marginLeft: 8
                  // marginRight: 0
                }}
              >
                Default
              </DefaultGasOption>
            </RowBetween>
            {liquidityGasLimitError ? (
              <RowBetween
                style={{
                  fontSize: '14px',
                  paddingTop: '7px',
                  color: liquidityGasLimitError === GasLimitError.InvalidInput ? 'red' : '#F3841E'
                }}
              >
                {liquidityGasLimitError === GasLimitError.InvalidInput && 'Enter a valid gas number'}
              </RowBetween>
            ) : null}
          </AutoColumn>
        </>
      )}
    </AutoColumn>
  )
}
