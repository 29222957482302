import { Environment } from '@xatadev/sdk'

const useEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT
  if (env === 'staging') return Environment.STAGING

  return Environment.PRODUCTION
}

export default useEnvironment
