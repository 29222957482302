// import { BigNumber } from '@ethersproject/bignumber'
// import { TransactionResponse } from '@ethersproject/providers'
// import { currencyEquals, ETHER, TokenAmount, WETH, Pair, Token, ChainId } from '@xatadev/sdk'
import {
  Currency,
  CurrencyAmount,
  currencyEquals,
  Ether,
  GasLimit,
  WETH9,
  Xata
} from '@xatadev/sdk'
// import { GasFeeBar } from './GasFeeBar'
import { BigNumber as JSBigNumber } from 'bignumber.js'
import DesktopSettings from 'components/Settings/DesktopSettings'
import { SwapCallbackError } from 'components/swap/styleds'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
// import { GTOKEN_MAP } from '../../constants/gtokens/gtokens'
// import { GAS_LIMIT } from '../../constants/price/price'
// import CONTROLLER_ABI from '../../constants/abis/Controller.json'
import { BigNumber } from 'ethers'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useConveyorV2RouterContract } from 'hooks/useContract'
// import { EIP712_DOMAIN_TYPE, FORWARDER_TYPE } from 'constants/abis/conveyor-v2'
import useEnvironment from 'hooks/useEnvironment'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
// import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import { AppDispatch, AppState } from 'state'
import { ApplicationModal } from 'state/application/actions'
// import { TransactionReceipt } from '@ethersproject/abstract-provider/lib/index'
// import { recoverTypedSignature } from 'eth-sig-util'
import styled, { ThemeContext } from 'styled-components'
import { toRawAmount } from 'utils/toRawAmount'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { LightCard, TipCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../../components/TransactionConfirmationModal'
import TransactionFeeCard from '../../components/TransactionFeeCard'
// import { ROUTER_ADDRESS } from '../../constants'
import { PairState } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
// import { GTOKEN_CONTROLLER_MAP } from '../../constants'
// import { splitSignature } from 'ethers/lib/utils'
// import { RELAYER_ENDPOINT_MAP } from '../../constants/relayer'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useGasLimit, { getDefaultGasLimit } from '../../hooks/useGasLimit'
// import { useCurrency } from '../../hooks/Tokens'
// import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useAddliquidityActionHandlers } from '../../state/AddLiquidity/hooks'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import {
  addLiquitityFeeInTokens as addLiquitityFeeInTokensAction,
  feeTokenSymbol as feeTokenSymbolAction
} from '../../state/fee/actions'
import { useGasFee } from '../../state/fee/hooks'
import { Field } from '../../state/mint/actions'
// import { WrappedTokenInfo } from '../../state/lists/hooks'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'
import { useSimpleTransactionAdder } from '../../state/transactions/hooks'
import {
  useIsExpertMode,
  useUserFeeTokens,
  useUserLiquidityGasLimit,
  useUserSlippageTolerance,
  useUserUseCustomFeeToken
} from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
// import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import { calculateSlippageAmount } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
// import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
// import { Dots } from '../Pool/styleds'
import { Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
// import { currencyId } from '../../utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'

// const { defaultAbiCoder, keccak256, toUtf8Bytes, Interface } = utils

const AppBodyContainer = styled.div`
  max-width: 420px;
  width: 100%;
  position: relative;
`

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  const environment = useEnvironment()

  const [isFetchingFee, setIsFetchingFee] = useState<boolean>(false)

  // const currencyA = useCurrency(currencyIdA)
  // const currencyB = useCurrency(currencyIdB)

  let weth
  if (chainId && WETH9.hasOwnProperty(chainId)) {
    weth = (WETH9 as any)[chainId]
  } else {
    weth = undefined
  }

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const isExpertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    // parsedOriginalAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
    // feeCurrency
  } = useDerivedMintInfo()
  const currencyA = currencies[Field.CURRENCY_A]
  const currencyB = currencies[Field.CURRENCY_B]

  // console.log('feeCurrency', feeCurrency)

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      weth &&
      ((currencyA && currencyEquals(currencyA, weth)) ||
        (currencyB && currencyEquals(currencyB, weth)))
  )

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field])
    }
  }, {})

  // const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
  //   (accumulator, field) => {
  //     return {
  //       ...accumulator,
  //       [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
  //     }
  //   },
  //   {}
  // )

  // check whether the user has approved the router on the tokens
  const tokenAAddress = currencies[Field.CURRENCY_A]
    ? currencies[Field.CURRENCY_A]!.wrapped.address
    : undefined
  const tokenBAddress = currencies[Field.CURRENCY_B]
    ? currencies[Field.CURRENCY_B]!.wrapped.address
    : undefined

  // const routerContract = getContract(ROUTER_ADDRESS[environment][chainId!], CONVEYOR_V2_ROUTER_ABI, library!, account!)
  const routerContract = useConveyorV2RouterContract()

  const [approvalA, approveACallback] = useApproveCallback(
    // parsedOriginalAmounts[Field.CURRENCY_A],
    // tokenAAddress ? GTOKEN_MAP[chainId!]!.get(tokenAAddress) : undefined
    parsedAmounts[Field.CURRENCY_A],
    tokenAAddress ? routerContract?.address : undefined
  )
  const [approvalB, approveBCallback] = useApproveCallback(
    // parsedOriginalAmounts[Field.CURRENCY_B],
    // tokenBAddress ? GTOKEN_MAP[chainId!]!.get(tokenBAddress) : undefined
    parsedAmounts[Field.CURRENCY_B],
    tokenBAddress ? routerContract?.address : undefined
  )

  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [userFeeTokens] = useUserFeeTokens()
  const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  const { addLiquitityFeeInTokens, feeTokenSymbol, fetchGasFeeInToken } = useGasFee()

  const feeTokenAmount = useTokenBalance(
    account ?? undefined,
    userUseCustomFeeToken && userFeeCurrency
      ? userFeeCurrency.wrapped
      : feeTokenSymbol === 'CON-V2'
      ? pair?.liquidityToken
      : currencies[Field.CURRENCY_A]?.wrapped
  )
  const [feeTokenApproval, approveFeeTokenCallback] = useApproveCallback(
    feeTokenAmount,
    routerContract?.address ?? undefined
  )
  console.log('pairAddress', pair?.liquidityToken.address)
  console.log('feeTokenAmount', feeTokenAmount?.toFixed())
  console.log('feeTokenApproval', feeTokenApproval)
  console.debug(approveFeeTokenCallback)

  const [userLiquidityGasLimit] = useUserLiquidityGasLimit()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!isValid) {
      if (isFetchingFee) {
        setIsFetchingFee(false)
      }

      return
    }

    if (!feeTokenSymbol && !addLiquitityFeeInTokens) {
      setIsFetchingFee(true)

      const gasLimit = isExpertMode
        ? BigNumber.from(userLiquidityGasLimit)
        : pairState === PairState.NOT_EXISTS
        ? BigNumber.from(getDefaultGasLimit(chainId, GasLimit.CREATE_PAIR))
        : BigNumber.from(getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_ADD))

      // dispatch(isFetchingFee(true))
      fetchGasFeeInToken(gasLimit, currencyA)?.then(response => {
        if (response?.maxGasFeeInToken && response?.tokenSymbol) {
          dispatch(addLiquitityFeeInTokensAction(response.maxGasFeeInToken.toString()))
          dispatch(feeTokenSymbolAction(response.tokenSymbol))
          // console.log('test')
        }
        // dispatch(isFetchingFee(false))
      })
    } else {
      setIsFetchingFee(false)
    }
  }, [
    addLiquitityFeeInTokens,
    chainId,
    currencyA,
    dispatch,
    feeTokenSymbol,
    fetchGasFeeInToken,
    isExpertMode,
    isFetchingFee,
    isValid,
    pairState,
    userLiquidityGasLimit
  ])

  const resetFeeState = useCallback(() => {
    // setIsFetchingFee(true)
    dispatch(addLiquitityFeeInTokensAction(undefined))
    dispatch(feeTokenSymbolAction(undefined))
  }, [dispatch])

  const handleFieldAInput = useCallback(
    (value: string) => {
      resetFeeState()
      onFieldAInput(value)
    },
    [onFieldAInput, resetFeeState]
  )

  const handleFieldBInput = useCallback(
    (value: string) => {
      resetFeeState()
      onFieldBInput(value)
    },
    [onFieldBInput, resetFeeState]
  )

  // const location = useLocation()
  // const [userLiquidityGasLimit, setUserLiquidityGasLimit] = useUserLiquidityGasLimit()
  const { gasLimit /*, resetUserGasLimit*/ } = useGasLimit()
  // useEffect(() => {
  //   if (location.pathname.includes('/create')) {
  //     // setUserLiquidityGasLimit(GAS_LIMIT[environment].createPair)
  //     resetUserGasLimit(GasLimit.CREATE_PAIR)
  //   } else if (location.pathname.includes('/add')) {
  //     // setUserLiquidityGasLimit(GAS_LIMIT[environment].addLiquidity)
  //     resetUserGasLimit(GasLimit.LIQUIDITY_ADD)
  //   }
  // }, [location.pathname, resetUserGasLimit])

  const addTransaction = useSimpleTransactionAdder()

  async function onAdd() {
    setErrorMessage('')

    if (!chainId || !library || !account || !routerContract) return

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    // initialize the api sdk
    const xataApi = new Xata()
    await xataApi.init(
      library,
      userUseCustomFeeToken && userFeeCurrency
        ? userFeeCurrency.wrapped.address
        : feeTokenSymbol === 'CON-V2' && pair
        ? pair.liquidityToken.address
        : currencies[Field.CURRENCY_A]?.wrapped.address!,
      environment
    )

    console.table(xataApi)

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? 0 : allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? 0 : allowedSlippage
      )[0]
    }
    const amountADesired = toRawAmount(parsedAmountA)
    const amountBDesired = toRawAmount(parsedAmountB)
    const amountAMin = amountsMin[Field.CURRENCY_A].toString()
    const amountBMin = amountsMin[Field.CURRENCY_B].toString()

    const gasPrice = await library?.getGasPrice()
    const userGasLimit = isExpertMode
      ? gasLimit[GasLimit.LIQUIDITY_ADD] // userLiquidityGasLimit
      : pairState === PairState.NOT_EXISTS
      ? getDefaultGasLimit(chainId, GasLimit.CREATE_PAIR) // ? GAS_LIMIT[environment].createPair
      : getDefaultGasLimit(chainId, GasLimit.LIQUIDITY_ADD) // : GAS_LIMIT[environment].addLiquidity
    const _gasLimit = BigNumber.from(userGasLimit)

    const payload = {
      tokenA: currencyA.wrapped.address,
      tokenB: currencyB.wrapped.address,
      amountADesired: BigNumber.from(amountADesired),
      amountBDesired: BigNumber.from(amountBDesired),
      amountAMin: BigNumber.from(amountAMin),
      amountBMin: BigNumber.from(amountBMin),
      user: account,
      deadline: deadline
    }

    console.table(payload)

    console.log('gasLimit: ', _gasLimit?.toString())
    console.log('gasPrice: ', gasPrice.toString())

    setAttemptingTxn(true)

    xataApi
      .addLiquidity(
        payload.tokenA,
        payload.tokenB,
        payload.amountADesired,
        payload.amountBDesired,
        payload.amountAMin,
        payload.amountBMin,
        payload.user,
        payload.deadline,
        _gasLimit,
        gasPrice
      )
      .then(res => {
        setAttemptingTxn(false)
        const { result } = res
        if (result.success) {
          addTransaction(result.txnHash as string, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_B]?.symbol
            }`
          })

          setTxHash(result.txnHash)
          // if (isExpertMode) {
          //   setShowConfirm(true)
          // }
        } else {
          throw new Error(result.errorMessage)
        }
      })
      .catch(err => {
        setAttemptingTxn(false)
        console.dir(err)
        setErrorMessage(err.message)
        if (err?.code !== 4001) {
          console.error(err)
        }
      })
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text
              fontSize="48px"
              fontWeight={500}
              lineHeight="42px"
              marginRight={10}
              style={{ wordBreak: 'break-all' }}
            >
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text
            fontSize="48px"
            fontWeight={500}
            lineHeight="42px"
            marginRight={10}
            style={{ wordBreak: 'break-all' }}
          >
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol +
              '/' +
              currencies[Field.CURRENCY_B]?.symbol +
              ' Pool Tokens'}
          </Text>
        </Row>
        <TYPE.italic
          fontSize={12}
          textAlign="left"
          padding={'8px 0 0 0 '}
          style={{ wordBreak: 'break-all' }}
        >
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_B]?.symbol
  }`

  const { onCurrencySelection } = useAddliquidityActionHandlers()

  const handleCurrencyASelect = useCallback(
    inputCurrency => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.CURRENCY_A, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleCurrencyBSelect = useCallback(
    inputCurrency => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.CURRENCY_B, inputCurrency)
    },
    [onCurrencySelection]
  )

  // const handleCurrencyASelect = useCallback(
  //   (currencyA: Currency) => {
  //     const newCurrencyIdA = currencyId(currencyA)
  //     if (newCurrencyIdA === currencyIdB) {
  //       history.push(`/add/${currencyIdB}/${currencyIdA}`)
  //     } else {
  //       history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
  //     }
  //   },
  //   [currencyIdB, history, currencyIdA]
  // )
  // const handleCurrencyBSelect = useCallback(
  //   (currencyB: Currency) => {
  //     const newCurrencyIdB = currencyId(currencyB)
  //     if (currencyIdA === newCurrencyIdB) {
  //       if (currencyIdB) {
  //         history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
  //       } else {
  //         history.push(`/add/${newCurrencyIdB}`)
  //       }
  //     } else {
  //       // history.push(`/add/${currencyIdA ? currencyIdA : undefined}/${newCurrencyIdB}`)
  //       history.push(`/add/${currencyIdA ? currencyIdA : undefined}/${newCurrencyIdB}`)
  //     }
  //   },
  //   [currencyIdA, history, currencyIdB]
  // )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsTransactionUnsupported(
    currencies?.CURRENCY_A,
    currencies?.CURRENCY_B
  )

  const showApprovalA = approvalA !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_A]
  const showApprovalB = approvalB !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_B]
  const showFeeTokenApproval =
    feeTokenApproval !== ApprovalState.APPROVED &&
    !!parsedAmounts[Field.CURRENCY_A] &&
    !!parsedAmounts[Field.CURRENCY_B]
  console.log('showFeeTokenApproval', showFeeTokenApproval)

  const addLiquidityFee = useSelector<AppState, AppState['fee']['addLiquitityFeeInTokens']>(
    state => state.fee.addLiquitityFeeInTokens
  )
  const roundedAddLiquidityFee =
    addLiquidityFee === undefined ? undefined : new JSBigNumber(addLiquidityFee).toFixed(0, 2)
  const addLiquidityFeeFraction =
    roundedAddLiquidityFee === undefined
      ? undefined
      : currencyA === undefined
      ? undefined
      : new JSBigNumber(roundedAddLiquidityFee).div(
          new JSBigNumber(10)
            .pow(
              userUseCustomFeeToken && userFeeCurrency
                ? userFeeCurrency.decimals
                : currencyA.decimals
            )
            .toFixed(
              userUseCustomFeeToken && userFeeCurrency
                ? userFeeCurrency.decimals
                : currencyA.decimals
            )
        )

  const handleConfirmDismiss = useCallback(() => {
    setErrorMessage('')
  }, [])

  const isSettingsModalOpen = useModalOpen(ApplicationModal.SETTINGS)
  const windowSize = useWindowSize()
  const isDesktop = useMemo(() => windowSize.width && windowSize.width > 960, [windowSize.width])

  const handleFeeSelect = useCallback(() => {
    resetFeeState()
  }, [resetFeeState])

  return (
    <>
      <AppBodyContainer>
        {isDesktop && isSettingsModalOpen ? <DesktopSettings /> : null}

        <AppBody>
          <AddRemoveTabs creating={isCreate} adding={true} />
          <Wrapper>
            <TransactionConfirmationModal
              isOpen={showConfirm}
              onDismiss={handleDismissConfirmation}
              attemptingTxn={attemptingTxn}
              hash={txHash}
              preventedLoss={undefined}
              content={() =>
                errorMessage && errorMessage.length > 0 ? (
                  <TransactionErrorContent
                    onDismiss={handleConfirmDismiss}
                    message={errorMessage}
                  />
                ) : (
                  <ConfirmationModalContent
                    title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
                    onDismiss={handleDismissConfirmation}
                    topContent={modalHeader}
                    bottomContent={modalBottom}
                  />
                )
              }
              pendingText={pendingText}
            />
            <AutoColumn gap="20px">
              {noLiquidity ||
                (isCreate ? (
                  <ColumnCenter>
                    <TipCard>
                      <AutoColumn gap="10px">
                        <TYPE.link fontWeight={600} color={'tipText'}>
                          You are the first liquidity provider.
                        </TYPE.link>
                        <TYPE.link fontWeight={400} color={'tipText'}>
                          The ratio of tokens you add will set the price of this pool.
                        </TYPE.link>
                        <TYPE.link fontWeight={400} color={'tipText'}>
                          Once you are happy with the rate click supply to review.
                        </TYPE.link>
                      </AutoColumn>
                    </TipCard>
                  </ColumnCenter>
                ) : (
                  <ColumnCenter>
                    <TipCard>
                      <AutoColumn gap="10px">
                        <TYPE.subHeader fontWeight={400} color={'tipText'}>
                          <b>Tip:</b> When you add liquidity, you will receive pool tokens
                          representing your position. These tokens automatically earn fees
                          proportional to your share of the pool, and can be redeemed at any time.
                        </TYPE.subHeader>
                      </AutoColumn>
                    </TipCard>
                  </ColumnCenter>
                ))}
              <CurrencyInputPanel
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={handleFieldAInput}
                onMax={() => {
                  handleFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={false}
                currency={currencies[Field.CURRENCY_A]}
                id="add-liquidity-input-tokena"
                showCommonBases={false}
                // gasFee={
                //   currencies[Field.CURRENCY_A] &&
                //   currencies[Field.CURRENCY_B] &&
                //   pairState !== PairState.INVALID
                //     ? addLiquidityFeeFraction
                //     : undefined
                // }
              />
              <ColumnCenter>
                <Plus size="16" color={theme.text2} />
              </ColumnCenter>
              <CurrencyInputPanel
                value={formattedAmounts[Field.CURRENCY_B]}
                onUserInput={handleFieldBInput}
                onCurrencySelect={handleCurrencyBSelect}
                onMax={() => {
                  handleFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                }}
                showMaxButton={false}
                currency={currencies[Field.CURRENCY_B]}
                id="add-liquidity-input-tokenb"
                showCommonBases={false}
              />

              <TransactionFeeCard
                currency={
                  userFeeCurrency
                    ? userFeeCurrency
                    : feeTokenSymbol === 'CON-V2' && pair
                    ? pair.liquidityToken
                    : currencies[Field.CURRENCY_A] !== Ether.onChain(chainId!)
                    ? currencies[Field.CURRENCY_A]
                    : undefined
                }
                gasFee={addLiquidityFeeFraction}
                onFeeSelect={handleFeeSelect}
                isFetchingFee={isFetchingFee}
                inputIsValid={isValid}
              />

              {currencies[Field.CURRENCY_A] &&
                currencies[Field.CURRENCY_B] &&
                pairState !== PairState.INVALID && (
                  <>
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
                    </TYPE.subHeader>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                    {/* <LightCard padding="0px" borderRadius={'20px'}>
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
                    </TYPE.subHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius={'20px'}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </LightCard>
                </LightCard> */}

                    {/*<AutoColumn gap={'20px'}>*/}
                    {/*  <AutoRow justify="space-between">*/}
                    {/*    <AutoColumn justify="center">*/}
                    {/*      <TYPE.subHeader fontWeight={500} fontSize={14}>*/}
                    {/*        Estimated Gas Fee*/}
                    {/*      </TYPE.subHeader>*/}
                    {/*    </AutoColumn>*/}
                    {/*    {!userUseCustomFeeToken ? (*/}
                    {/*      <AutoColumn justify="center">*/}
                    {/*        <TYPE.subHeader fontWeight={500} fontSize={14}>*/}
                    {/*          /!*{addLiquidityFeeFraction*!/*/}
                    {/*          /!*  ? `${addLiquidityFeeFraction.toFixed(*!/*/}
                    {/*          /!*      currencies[Field.CURRENCY_A]?.decimals!*!/*/}
                    {/*          /!*    )} ${currencies[Field.CURRENCY_A]?.symbol}`*!/*/}
                    {/*          /!*  : '-'}*!/*/}
                    {/*          {addLiquidityFeeFraction ? (*/}
                    {/*            <>*/}
                    {/*              {feeTokenSymbol === 'CON-V2' && pair?.liquidityToken*/}
                    {/*                ? `${addLiquidityFeeFraction.toFixed(*/}
                    {/*                    pair.liquidityToken.decimals*/}
                    {/*                  )} ${pair.liquidityToken.symbol}`*/}
                    {/*                : `${addLiquidityFeeFraction.toFixed(*/}
                    {/*                    currencies[Field.CURRENCY_A]?.decimals!*/}
                    {/*                  )} ${feeTokenSymbol}`}*/}
                    {/*            </>*/}
                    {/*          ) : (*/}
                    {/*            '-'*/}
                    {/*          )}*/}
                    {/*        </TYPE.subHeader>*/}
                    {/*      </AutoColumn>*/}
                    {/*    ) : null}*/}
                    {/*  </AutoRow>*/}
                    {/*</AutoColumn>*/}

                    {/* <LightCard padding="0px" borderRadius={'20px'}>
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      Estimated Gas Fee
                    </TYPE.subHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius={'20px'}>
                    <GasFeeBar currencies={currencies} gasFee={addLiquidityFeeFraction} />
                  </LightCard>
                </LightCard> */}
                  </>
                )}

              {addIsUnsupported ? (
                <ButtonPrimary disabled={true}>
                  <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
                </ButtonPrimary>
              ) : !account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : (
                <AutoColumn gap={'md'}>
                  {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <RowBetween>
                        {showApprovalA && (
                          <ButtonPrimary
                            borderRadius="12px"
                            padding={'12px'}
                            onClick={approveACallback}
                            disabled={approvalA === ApprovalState.PENDING}
                            width={showApprovalB ? '48%' : '100%'}
                          >
                            {approvalA === ApprovalState.PENDING
                              ? `Approving ${currencies[Field.CURRENCY_A]?.symbol}`
                              : `Approve ${currencies[Field.CURRENCY_A]?.symbol}`}
                          </ButtonPrimary>
                        )}
                        {showApprovalB && (
                          <ButtonPrimary
                            borderRadius="12px"
                            padding={'12px'}
                            onClick={approveBCallback}
                            disabled={approvalB === ApprovalState.PENDING}
                            width={showApprovalA ? '48%' : '100%'}
                          >
                            {approvalB === ApprovalState.PENDING
                              ? `Approving ${currencies[Field.CURRENCY_B]?.symbol}`
                              : `Approve ${currencies[Field.CURRENCY_B]?.symbol}`}
                          </ButtonPrimary>
                        )}
                      </RowBetween>
                    )}

                  {(feeTokenApproval === ApprovalState.NOT_APPROVED ||
                    feeTokenApproval === ApprovalState.PENDING) &&
                    isValid &&
                    showFeeTokenApproval &&
                    userFeeCurrency &&
                    feeTokenSymbol && (
                      <ButtonPrimary
                        borderRadius="12px"
                        padding={'12px'}
                        onClick={approveFeeTokenCallback}
                        disabled={feeTokenApproval === ApprovalState.PENDING}
                        // width={showApprovalA ? '48%' : '100%'}
                      >
                        {feeTokenApproval === ApprovalState.PENDING
                          ? `Approving ${feeTokenSymbol}`
                          : `Approve ${feeTokenSymbol}`}
                      </ButtonPrimary>
                    )}

                  <ButtonError
                    onClick={() => {
                      isExpertMode
                        ? onAdd()
                        : (() => {
                            setErrorMessage('')
                            setShowConfirm(true)
                          })()
                    }}
                    disabled={
                      !isValid ||
                      isFetchingFee ||
                      approvalA !== ApprovalState.APPROVED ||
                      approvalB !== ApprovalState.APPROVED ||
                      feeTokenApproval !== ApprovalState.APPROVED
                    }
                    error={
                      !isValid &&
                      isFetchingFee &&
                      !!parsedAmounts[Field.CURRENCY_A] &&
                      !!parsedAmounts[Field.CURRENCY_B]
                    }
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {error ? error : isFetchingFee ? 'Waiting for Gas Fee' : 'Supply'}
                    </Text>
                  </ButtonError>
                </AutoColumn>
              )}
              {isExpertMode && errorMessage ? <SwapCallbackError error={errorMessage} /> : null}
            </AutoColumn>
          </Wrapper>
        </AppBody>
      </AppBodyContainer>

      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn
            style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}
          >
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </>
  )
}
