import { ChainId, Token } from '@xatadev/sdk'
import { TokenMap } from '@xatadev/sdk/dist/types/TokenMap'

export const ATA: TokenMap = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xa2120b9e674d3fc3875f415a7df52e382f141225',
    18,
    'ATA',
    'Automata'
  ),
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x0df0f72ee0e5c9b7ca761ecec42754992b2da5bf',
    18,
    'ATA',
    'Automata'
  )
}

export const DAI: TokenMap = {
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    18,
    'DAI',
    'Dai Stablecoin'
  )
}

export const SUSHI: TokenMap = {
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
    18,
    'SUSHI',
    'Sushi'
  )
}

export const USDT: TokenMap = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059ff775485246999027b3197955',
    18,
    'USDT',
    'Tether USD'
  ),
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    6,
    'USDT',
    'Tether USD'
  )
}
