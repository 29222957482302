import { Currency, CurrencyAmount, Pair } from '@xatadev/sdk'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import uniqid from 'uniqid'

import { AutoColumn } from '../../components/Column'
import { FarmStatus, PositionCard, RewardType, SortBy, TxAction } from '../../components/farms'
import MobilePoolDetailsModal from '../../components/farms/MobilePoolDetailsModal'
import TooltipContent, { TooltipCountdownContent } from '../../components/farms/TooltipContent'
import { AutoRow, RowFixed } from '../../components/Row'
import { Dots } from '../../components/swap/styleds'
import { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { RewardPool } from '../../entities/RewardPool'
import { useActiveWeb3React } from '../../hooks'
import useEnvironment from '../../hooks/useEnvironment'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  useFarms,
  useFarmsSearchQuery,
  useFarmStatusOption,
  useSortByOption,
  useTokenPrices
} from '../../state/farms/hooks'
import { TYPE } from '../../theme'

// console.debug(useCallback, useEffect, useState)

type Props = {
  handleActionClick: (
    actionToCall: TxAction,
    contractAddress: string,
    pairToTrade: Pair | null,
    reward?: {
      amount: string
      symbol: string
      decimals: number
      logoURI: string
    }[],
    userStakedAmount?: CurrencyAmount<Currency>,
    pool?: any
  ) => void
}

export type PoolModalData = {
  rewardsPerDay: string
  tokenLogos: string[]
  tokenSymbols: string[]
  aprPerToken: string[]
  periodPerToken: number[]
  earnedRewards: string[]
}

const ContentRow = styled(AutoColumn)``

const DoubleLogoWrapper = styled(RowFixed)`
  margin-right: 1rem;
  flex: 0 0 auto;

  > img:last-child {
    margin-left: -6px;
  }
`

const TokenLogo = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 26px;
  border: solid 1px ${({ theme }) => theme.farms.border2};
  overflow: hidden;
  background-color: ${({ theme }) => theme.farms.primer};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RewardPools = ({ handleActionClick }: Props) => {
  const theme = useContext(ThemeContext)

  const environment = useEnvironment()

  // Hooks
  const { account, chainId, library } = useActiveWeb3React()
  const [tokenPrices] = useTokenPrices(chainId, 'usd')
  const { width: windowWidth } = useWindowSize()

  // Pool details modal on mobile
  const [poolModalData, setPoolModalData] = useState<PoolModalData>()
  const [poolModalShown, setPoolModalShown] = useState<boolean>(false)

  const handlePoolDetailsClick = (data: PoolModalData) => {
    setPoolModalData(data)
    setPoolModalShown(true)
  }

  const modalTopContent = useMemo(() => {
    const {
      rewardsPerDay,
      tokenLogos,
      tokenSymbols,
      aprPerToken,
      periodPerToken,
      earnedRewards
    } = poolModalData ?? {
      rewardsPerDay: undefined,
      tokenLogos: undefined,
      tokenSymbols: undefined,
      aprPerToken: undefined,
      periodPerToken: undefined,
      earnedRewards: undefined
    }

    return (
      <>
        {rewardsPerDay &&
          tokenLogos &&
          tokenSymbols &&
          !isEmpty(tokenSymbols) &&
          aprPerToken &&
          periodPerToken &&
          earnedRewards && (
            <>
              <AutoRow margin={'32px 0 1rem'}>
                <AutoColumn>
                  <DoubleLogoWrapper>
                    {tokenLogos.map((logo, i) => (
                      <TokenLogo src={logo} key={`${tokenSymbols[i]}_${uniqid}`} />
                    ))}
                  </DoubleLogoWrapper>
                </AutoColumn>

                <AutoColumn>
                  <RowFixed>Rewards for this pool</RowFixed>
                  <RowFixed>{rewardsPerDay}</RowFixed>
                </AutoColumn>
              </AutoRow>

              <AutoRow margin={'12px 0'}>
                <TooltipContent
                  title={'APR Per Token'}
                  tokenLogos={tokenLogos}
                  labels={tokenSymbols}
                  values={aprPerToken}
                />
              </AutoRow>

              <AutoRow margin={'12px 0'}>
                <TooltipCountdownContent
                  title={'Time Remaining'}
                  tokenLogos={tokenLogos}
                  labels={tokenSymbols}
                  values={periodPerToken}
                />
              </AutoRow>

              <AutoRow margin={'12px 0'}>
                <TooltipContent
                  title={'Rewards Earned'}
                  tokenLogos={tokenLogos}
                  labels={tokenSymbols}
                  values={earnedRewards}
                />
              </AutoRow>
            </>
          )}
      </>
    )
  }, [poolModalData])

  // Initiate pool instances
  const { networkSupported, farmsInfo } = useFarms(RewardType.MULTI)
  const [pools, setPools] = useState<RewardPool[] | null>(null)

  useEffect(() => {
    if (!library || !chainId || !networkSupported) return

    const fetchPoolRewardsData = async () => {
      const poolRewardsPromises = farmsInfo.map(async farm => {
        const { address, tokenA, tokenB } = farm

        return await RewardPool.initializeData(
          { address, chainId, tokenA, tokenB, tokenPrices },
          library,
          account ?? undefined,
          environment
        )
      })

      return await Promise.all(poolRewardsPromises)
    }

    fetchPoolRewardsData().then(poolRewardsData => {
      // console.log('result', poolRewardsData)
      setPools(poolRewardsData)
    })
  }, [account, chainId, environment, farmsInfo, library, networkSupported, tokenPrices])

  // Finalize all pools to be rendered by applying filter
  const [searchQuery] = useFarmsSearchQuery()
  const [selectedSortOption] = useSortByOption()
  const [selectedFarmStatusOption] = useFarmStatusOption()

  const sortPools = useCallback(
    (unsortedPools: RewardPool[]) => {
      switch (selectedSortOption) {
        case SortBy.TOTAL:
          return orderBy(
            unsortedPools,
            pool => (pool.totalInFarm ? Number(pool.totalInFarm) : 0),
            'desc'
          )

        case SortBy.EARNED:
          return orderBy(
            unsortedPools,
            pool => (pool.userStakedAmount ? Number(pool.userStakedAmount) : 0),
            'desc'
          )

        case SortBy.TIME:
          return orderBy(unsortedPools, pool => pool.getTimeRemaining(), 'desc')

        case SortBy.APR:
          return orderBy(unsortedPools, pool => Number(pool.getAprAccumulation()), 'desc')

        default:
          return unsortedPools
      }
    },
    [selectedSortOption]
  )

  const poolRewardCards = useMemo(() => {
    if (!pools) {
      return (
        <EmptyProposals>
          <TYPE.body color={theme.text3} textAlign="center">
            <Dots>Loading</Dots>
          </TYPE.body>
        </EmptyProposals>
      )
    }

    const poolsToShow = pools.reduce<RewardPool[]>((memo, pool) => {
      const rewardsActiveState = Object.values(pool.isActive!)
      if (
        (selectedFarmStatusOption === FarmStatus.ACTIVE &&
          rewardsActiveState.some(isActive => isActive === true)) ||
        (selectedFarmStatusOption === FarmStatus.INACTIVE &&
          rewardsActiveState.every(isActive => isActive === false))
      ) {
        memo.push(pool)
      }

      return memo
    }, [])

    if (poolsToShow.length === 0) {
      return (
        <EmptyProposals>
          <TYPE.body color={theme.text3} textAlign="center">
            {selectedFarmStatusOption === FarmStatus.ACTIVE
              ? 'No active pool found at the moment'
              : 'No inactive pool found at the moment'}
          </TYPE.body>
        </EmptyProposals>
      )
    }

    let finalPools = sortPools(poolsToShow)
    if (searchQuery !== '') {
      finalPools = poolsToShow.reduce<RewardPool[]>((memo, pool) => {
        if (
          pool.tags.findIndex(tag => tag.toLowerCase().includes(searchQuery.toLowerCase())) > -1
        ) {
          memo.push(pool)
        }

        return memo
      }, [])

      if (finalPools.length === 0) {
        return (
          <EmptyProposals>
            <TYPE.body color={theme.text3} textAlign="center">
              No pool match with the search keyword
            </TYPE.body>
          </EmptyProposals>
        )
      }
    }

    return finalPools.map(pool => (
      <PositionCard
        multiRewards
        key={pool.address}
        pool={pool}
        onActionClicked={handleActionClick}
        onPoolDetailsClicked={handlePoolDetailsClick}
      />
    ))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [handleActionClick, pools, searchQuery, selectedFarmStatusOption, sortPools])

  return (
    <>
      {windowWidth && windowWidth <= 720 && (
        <MobilePoolDetailsModal
          isOpen={poolModalShown}
          onDismiss={() => setPoolModalShown(false)}
          content={() => (
            <ConfirmationModalContent
              title={'Reward Pool Details'}
              onDismiss={() => setPoolModalShown(false)}
              topContent={() => modalTopContent}
            />
          )}
        />
      )}
      <ContentRow gap={'lg'}>
        {networkSupported ? (
          poolRewardCards
        ) : (
          <EmptyProposals>
            <TYPE.body color={theme.text3} textAlign="center">
              This network is not supported by our Farms at the moment
            </TYPE.body>
          </EmptyProposals>
        )}
      </ContentRow>
    </>
  )
}

export default RewardPools
