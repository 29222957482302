import {
  Currency,
  CurrencyAmount,
  Ether,
  HOP_ADDITIONAL_GAS,
  JSBI,
  SWAP_GAS_LIMIT,
  Token,
  Trade,
  TradeType
} from '@xatadev/sdk'
import { BigNumber as JSBigNumber } from 'bignumber.js'
import DesktopSettings from 'components/Settings/DesktopSettings'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { BigNumber } from 'ethers'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from 'rebass'
import { AppDispatch, AppState } from 'state'
import { ApplicationModal } from 'state/application/actions'
import styled, { ThemeContext } from 'styled-components'
import { isTradeBetter } from 'utils/trades'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import Loader from '../../components/Loader'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import {
  ArrowWrapper,
  BottomGrouping,
  SwapCallbackError,
  Wrapper
} from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import TransactionFeeCard from '../../components/TransactionFeeCard'
// import TransactionFeeCard from '../../components/TransactionFeeCard'
import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
// TODO Remove gToken support
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import {
  ApprovalState,
  useApproveCallback,
  useApproveCallbackFromTrade
} from '../../hooks/useApproveCallback'
import { useConveyorV2RouterContract } from '../../hooks/useContract'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import {
  useModalOpen,
  useToggleSettingsMenu,
  useWalletModalToggle
} from '../../state/application/hooks'
import {
  feeTokenSymbol as feeTokenSymbolAction,
  swapFeeInTokens as swapFeeInTokensAction
} from '../../state/fee/actions'
import { useGasFee } from '../../state/fee/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserFeeTokens,
  useUserSingleHopOnly,
  useUserSlippageTolerance,
  useUserSwapGasLimit,
  useUserUseCustomFeeToken
} from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'

const AppBodyContainer = styled.div`
  max-width: 420px;
  width: 100%;
  position: relative;
`

export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch()

  const [isFetchingFee, setIsFetchingFee] = useState<boolean>(false)

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ??
      [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])
  // dismiss warning if all imported tokens are in active lists
  // TODO Remove gToken support
  // const defaultTokens = useAllGTokens()
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !(token.address in defaultTokens)
    })

  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  // const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
  //   currencies[Field.INPUT],
  //   currencies[Field.OUTPUT],
  //   typedValue
  // )
  // const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const showWrap = false
  // const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  // const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  // const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]
  const trade = tradesByVersion[toggledVersion]
  const defaultTrade = tradesByVersion[DEFAULT_VERSION]

  const betterTradeLinkV2: Version | undefined =
    toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade) ? Version.v2 : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const [userSwapGasLimit] = useUserSwapGasLimit()

  const { feeTokenSymbol, swapFeeInTokens, fetchGasFeeInToken } = useGasFee()

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!isValid) {
      if (isFetchingFee) {
        setIsFetchingFee(false)
      }

      return
    }

    if (!feeTokenSymbol && !swapFeeInTokens) {
      setIsFetchingFee(true)

      const hopGas = v2Trade
        ? BigNumber.from((v2Trade.route.path.length - 2) * HOP_ADDITIONAL_GAS)
        : BigNumber.from(0)
      const gasLimit = BigNumber.from(isExpertMode ? userSwapGasLimit : SWAP_GAS_LIMIT).add(hopGas)

      // dispatch(isFetchingFee(true))
      fetchGasFeeInToken(gasLimit, currencies[Field.INPUT])?.then(response => {
        if (response?.maxGasFeeInToken && response?.tokenSymbol) {
          dispatch(swapFeeInTokensAction(response.maxGasFeeInToken.toString()))
          dispatch(feeTokenSymbolAction(response.tokenSymbol))
          // console.log('test')
        }
        // dispatch(isFetchingFee(false))
      })
    } else {
      setIsFetchingFee(false)
    }
  }, [
    currencies,
    dispatch,
    feeTokenSymbol,
    fetchGasFeeInToken,
    isExpertMode,
    isFetchingFee,
    isValid,
    swapFeeInTokens,
    userSwapGasLimit,
    v2Trade
  ])

  const resetFeeState = useCallback(() => {
    // setIsFetchingFee(true)
    dispatch(swapFeeInTokensAction(undefined))
    dispatch(feeTokenSymbolAction(undefined))
  }, [dispatch])

  const handleTypeInput = useCallback(
    (value: string) => {
      resetFeeState()
      onUserInput(Field.INPUT, value)
    },
    [resetFeeState, onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      resetFeeState()
      onUserInput(Field.OUTPUT, value)
    },
    [resetFeeState, onUserInput]
  )

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash, preventedLoss },
    setSwapState
  ] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
    preventedLoss: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
    preventedLoss: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  const routerContract = useConveyorV2RouterContract()

  const [userUseCustomFeeToken] = useUserUseCustomFeeToken()
  const [userFeeTokens] = useUserFeeTokens()
  const userFeeCurrency = useCurrency(chainId ? userFeeTokens[chainId] : undefined)

  const feeTokenAmount = useTokenBalance(
    account ?? undefined,
    userUseCustomFeeToken && userFeeCurrency ? userFeeCurrency.wrapped : undefined
  )

  // const feeTokenSymbol = useSelector<AppState, AppState['fee']['feeTokenSymbol']>(
  //   state => state.fee.feeTokenSymbol
  // )

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    currencies,
    allowedSlippage
  )

  const [feeTokenApproval, approveFeeTokenCallback] = useApproveCallback(
    feeTokenAmount,
    userUseCustomFeeToken && userFeeCurrency ? routerContract?.address : undefined
  )
  console.log('feeTokenApproval', feeTokenApproval)
  console.debug('approveFeeTokenCallback', approveFeeTokenCallback)

  // check if user has gone through approval process, used to show two step buttons,
  // reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  const [feeTokenApprovalSubmitted, setFeeTokenApprovalSubmitted] = useState<boolean>(false)
  // console.log('feeTokenApprovalSubmitted', feeTokenApprovalSubmitted)
  // console.debug('setFeeTokenApprovalSubmitted', setFeeTokenApprovalSubmitted)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
    if (feeTokenApproval === ApprovalState.PENDING) {
      setFeeTokenApprovalSubmitted(true)
    }
  }, [approval, feeTokenApproval])

  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  )
  // const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade?.route.chainId, trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
      preventedLoss: undefined
    })
    swapCallback()
      .then(result => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: result.txnHash,
          preventedLoss: result.preventedLoss
        })

        // ReactGA.event({
        //   category: 'Swap',
        //   action:
        //     recipient === null
        //       ? 'Swap w/o Send'
        //       : (recipientAddress ?? recipient) === account
        //       ? 'Swap w/o Send + recipient'
        //       : 'Swap w/ Send',
        //   label: [
        //     trade?.inputAmount?.currency?.symbol,
        //     trade?.outputAmount?.currency?.symbol,
        //     getTradeVersion(trade)
        //   ].join('/')
        // })

        // ReactGA.event({
        //   category: 'Routing',
        //   action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
        // })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
          preventedLoss: undefined
        })
      })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    tradeToConfirm,
    showConfirm
    // recipient,
    // recipientAddress,
    // account,
    // trade,
    // singleHopOnly
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproval =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.PENDING ||
    (approvalSubmitted && approval === ApprovalState.APPROVED)
  const showFeeTokenApproval =
    feeTokenApproval === ApprovalState.NOT_APPROVED ||
    feeTokenApproval === ApprovalState.PENDING ||
    (feeTokenApprovalSubmitted && feeTokenApproval === ApprovalState.APPROVED)
  const showApproveFlow =
    !swapInputError &&
    (showApproval || showFeeTokenApproval) &&
    // showApproval &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleFeeSelect = useCallback(() => {
    resetFeeState()
    setFeeTokenApprovalSubmitted(false)
  }, [resetFeeState])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
      preventedLoss
    })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash, preventedLoss])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
      preventedLoss
    })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash, preventedLoss])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const swapFee = useSelector<AppState, AppState['fee']['swapFeeInTokens']>(
    state => state.fee.swapFeeInTokens
  )
  const roundedSwapFee = swapFee === undefined ? undefined : new JSBigNumber(swapFee).toFixed(0, 2)
  const swapFeeFraction =
    roundedSwapFee === undefined
      ? undefined
      : currencies[Field.INPUT] === undefined
      ? undefined
      : new JSBigNumber(roundedSwapFee).div(
          new JSBigNumber(10)
            .pow(
              userUseCustomFeeToken && userFeeCurrency
                ? userFeeCurrency.decimals
                : currencies[Field.INPUT]!.decimals
            )
            .toFixed(
              userUseCustomFeeToken && userFeeCurrency
                ? userFeeCurrency.decimals
                : currencies[Field.INPUT]!.decimals
            )
        )

  const isSettingsModalOpen = useModalOpen(ApplicationModal.SETTINGS)
  const windowSize = useWindowSize()
  const isDesktop = useMemo(() => windowSize.width && windowSize.width > 960, [windowSize.width])

  return (
    <>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
      />
      {/* <SwapPoolTabs active={'swap'} /> */}

      <AppBodyContainer>
        {isDesktop && isSettingsModalOpen ? <DesktopSettings /> : null}

        <AppBody>
          <SwapHeader />
          <Wrapper id="swap-page">
            <ConfirmSwapModal
              isOpen={showConfirm}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              attemptingTxn={attemptingTxn}
              txHash={txHash}
              preventedLoss={preventedLoss}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              swapErrorMessage={swapErrorMessage}
              onDismiss={handleConfirmDismiss}
            />

            <AutoColumn gap={'md'}>
              <CurrencyInputPanel
                label={
                  independentField === Field.OUTPUT && !showWrap && trade
                    ? 'From (estimated)'
                    : 'From'
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={false}
                currency={
                  currencies[Field.INPUT] === Ether.onChain(chainId!)
                    ? undefined
                    : currencies[Field.INPUT]
                }
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                id="swap-currency-input"
                // gasFee={!userUseCustomFeeToken ? swapFeeFraction : undefined}
              />
              <AutoColumn justify="space-between">
                <AutoRow
                  justify={isExpertMode ? 'space-between' : 'center'}
                  style={{ padding: '0 1rem' }}
                >
                  <ArrowWrapper clickable>
                    <ArrowDown
                      size="16"
                      onClick={() => {
                        setApprovalSubmitted(false) // reset 2 step UI for approvals
                        onSwitchTokens()
                      }}
                      color={
                        currencies[Field.INPUT] && currencies[Field.OUTPUT]
                          ? theme.primary1
                          : theme.text2
                      }
                    />
                  </ArrowWrapper>
                  {recipient === null && !showWrap && isExpertMode ? (
                    <LinkStyledButton
                      id="add-recipient-button"
                      onClick={() => onChangeRecipient('')}
                    >
                      + Add a send (optional)
                    </LinkStyledButton>
                  ) : null}
                </AutoRow>
              </AutoColumn>
              <CurrencyInputPanel
                value={formattedAmounts[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                label={
                  independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'
                }
                showMaxButton={false}
                currency={currencies[Field.OUTPUT]}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                id="swap-currency-output"
                // gasFee={undefined}
              />

              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false}>
                      <ArrowDown size="16" color={theme.text2} />
                    </ArrowWrapper>
                    <LinkStyledButton
                      id="remove-recipient-button"
                      onClick={() => onChangeRecipient(null)}
                    >
                      - Remove send
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel
                    id="recipient"
                    value={recipient}
                    onChange={onChangeRecipient}
                  />
                </>
              ) : null}

              <TransactionFeeCard
                currency={
                  userFeeCurrency
                    ? userFeeCurrency
                    : currencies[Field.INPUT] !== Ether.onChain(chainId!)
                    ? currencies[Field.INPUT]
                    : undefined
                }
                gasFee={swapFeeFraction}
                onFeeSelect={handleFeeSelect}
                isFetchingFee={isFetchingFee}
                inputIsValid={isValid}
              />

              {showWrap ? null : (
                <Card padding={showWrap ? '.25rem 1rem 0 1rem' : '0px'} borderRadius={'20px'}>
                  <AutoColumn gap="8px" style={{ padding: '0 16px' }}>
                    {Boolean(trade) && (
                      <RowBetween align="center">
                        <Text fontWeight={500} fontSize={14} color={theme.text2}>
                          Price
                        </Text>
                        <TradePrice
                          price={trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      </RowBetween>
                    )}
                    {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                      <RowBetween align="center">
                        <ClickableText
                          fontWeight={500}
                          fontSize={14}
                          color={theme.text2}
                          onClick={toggleSettings}
                        >
                          Slippage Tolerance
                        </ClickableText>
                        <ClickableText
                          fontWeight={500}
                          fontSize={14}
                          color={theme.text2}
                          onClick={toggleSettings}
                        >
                          {allowedSlippage / 100}%
                        </ClickableText>
                      </RowBetween>
                    )}
                  </AutoColumn>
                </Card>
              )}
            </AutoColumn>
            <BottomGrouping>
              {swapIsUnsupported ? (
                <ButtonPrimary disabled={true}>
                  <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
                </ButtonPrimary>
              ) : !account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : // : showWrap ? (
              //   <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
              //     {wrapInputError ??
              //       (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
              //   </ButtonPrimary>
              // )
              noRoute && userHasSpecifiedInputOutput ? (
                <GreyCard style={{ textAlign: 'center' }}>
                  <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
                  {singleHopOnly && <TYPE.main mb="4px">Try enabling multi-hop trades.</TYPE.main>}
                </GreyCard>
              ) : showApproveFlow ? (
                <AutoColumn gap={'md'}>
                  <RowBetween>
                    {showApproval && (
                      <ButtonConfirmed
                        onClick={approveCallback}
                        disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                        // width={showFeeTokenApproval ? '48%' : '100%'}
                        width={'100%'}
                        // eslint-disable-next-line max-len
                        altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                        confirmed={approval === ApprovalState.APPROVED}
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          'Approve ' + currencies[Field.INPUT]?.symbol
                        )}
                      </ButtonConfirmed>
                    )}
                    {showFeeTokenApproval && userFeeCurrency && feeTokenSymbol && (
                      <ButtonConfirmed
                        onClick={approveFeeTokenCallback}
                        disabled={
                          feeTokenApproval !== ApprovalState.NOT_APPROVED ||
                          feeTokenApprovalSubmitted
                        }
                        width={showApproval ? '48%' : '100%'}
                        // eslint-disable-next-line max-len
                        altDisabledStyle={feeTokenApproval === ApprovalState.PENDING} // show solid button while waiting
                        confirmed={feeTokenApproval === ApprovalState.APPROVED}
                      >
                        {feeTokenApproval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : feeTokenApprovalSubmitted &&
                          feeTokenApproval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          'Approve ' + feeTokenSymbol
                        )}
                      </ButtonConfirmed>
                    )}
                  </RowBetween>

                  <ButtonError
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined,
                          preventedLoss: undefined
                        })
                      }
                    }}
                    // width="48%"
                    id="swap-button"
                    disabled={
                      !isValid ||
                      (priceImpactSeverity > 3 && !isExpertMode) ||
                      !!swapCallbackError ||
                      isFetchingFee ||
                      approval !== ApprovalState.APPROVED ||
                      feeTokenApproval !== ApprovalState.APPROVED
                    }
                    error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {swapInputError
                        ? swapInputError
                        : isFetchingFee
                        ? 'Waiting for Gas Fee'
                        : priceImpactSeverity > 3 && !isExpertMode
                        ? 'Price Impact Too High'
                        : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                    </Text>
                  </ButtonError>
                </AutoColumn>
              ) : (
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                        preventedLoss: undefined
                      })
                    }
                  }}
                  id="swap-button"
                  disabled={
                    !isValid ||
                    (priceImpactSeverity > 3 && !isExpertMode) ||
                    !!swapCallbackError ||
                    isFetchingFee
                  }
                  error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={18} fontWeight={500}>
                    {swapInputError
                      ? swapInputError
                      : isFetchingFee
                      ? 'Waiting for Gas Fee'
                      : priceImpactSeverity > 3 && !isExpertMode
                      ? 'Price Impact Too High'
                      : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                  </Text>
                </ButtonError>
              )}
              {/* {showApproveFlow && (
                <Column style={{ marginTop: '1rem' }}>
                  <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                </Column>
              )} */}
              {isExpertMode && swapErrorMessage ? (
                <SwapCallbackError error={swapErrorMessage} />
              ) : null}
              {betterTradeLinkV2 && !swapIsUnsupported && toggledVersion === Version.v1 ? (
                <BetterTradeLink version={betterTradeLinkV2} />
              ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
                <DefaultVersionLink />
              ) : null}
            </BottomGrouping>
          </Wrapper>
        </AppBody>
      </AppBodyContainer>

      {!swapIsUnsupported ? (
        <AdvancedSwapDetailsDropdown trade={trade} />
      ) : (
        <UnsupportedCurrencyFooter
          show={swapIsUnsupported}
          currencies={[currencies.INPUT, currencies.OUTPUT]}
        />
      )}
    </>
  )
}
