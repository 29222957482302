import React from 'react'
import styled from 'styled-components'

import loopBgMp4 from '../assets/loopBg.mp4'
import loopBgWebm from '../assets/loopBg.webm'

const Wrapper = styled.div`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;

  &::after {
    content: '';
    ${({ theme }) => theme.bgOverlay}
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ParticleBackground = () => (
  <Wrapper>
    <Video autoPlay muted loop>
      <source src={loopBgWebm} type="video/webm" />
      <source src={loopBgMp4} type="video/mp4" />
    </Video>
  </Wrapper>
)

export default ParticleBackground
