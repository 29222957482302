import { CurrencyAmount, Currency, Ether, JSBI } from '@xatadev/sdk'
import { MIN_ETH } from '../constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount<Currency>): CurrencyAmount<Currency> | undefined {
  if (!currencyAmount) return undefined

  const { chainId } = currencyAmount.currency
  if (currencyAmount.currency === Ether.onChain(chainId)) {
    if (JSBI.greaterThan(currencyAmount.numerator, MIN_ETH)) {
      return CurrencyAmount.fromRawAmount(Ether.onChain(chainId), JSBI.subtract(currencyAmount.numerator, MIN_ETH))
    } else {
      return CurrencyAmount.fromRawAmount(Ether.onChain(chainId), JSBI.BigInt(0))
    }
  }
  return currencyAmount
}
