import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@xatadev/sdk'

export type PriceOfToken = {
  [tokenAddress: string]: number
}

export const addTokenPrices = createAction<{
  chainId: ChainId
  currencyCode: string
  pricesToAdd: PriceOfToken
}>('tokenPrices/addTokenPrice')
