import React, { Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import ParticleBackground from '../components/ParticleBackground'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Farms from './Farms'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import { SunsetModal } from 'components/SunsetModal'
import { useShowSunsetModal } from 'state/application/hooks'
// import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
// import Earn from './Earn'
// import Manage from './Earn/Manage'
// import MigrateV1 from './MigrateV1'
// import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
// import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
// import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
// import { OpenClaimAddressModalAndRedirectToSwap } from './Swap/redirects'
// import Vote from './Vote'
// import VotePage from './Vote/VotePage'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  background-color: ${({ theme }) => theme.bg1};
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 2;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 100px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const App = () => {
  const openSunsetModal = useShowSunsetModal()

  useEffect(() => {
    openSunsetModal()
  }, [openSunsetModal])

  return (
    <Suspense fallback={null}>
      {/* <Route component={GoogleAnalyticsReporter} /> */}
      <Route component={DarkModeQueryParamReader} />

      <AppWrapper>
        <ParticleBackground />
        <URLWarning />

        <HeaderWrapper>
          <Header />
        </HeaderWrapper>

        <BodyWrapper>
          <SunsetModal />
          <Popups />
          <Polling />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/swap" component={Swap} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/find" component={PoolFinder} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/create" component={RedirectToAddLiquidity} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route
                exact
                path="/add/:currencyIdA"
                component={RedirectOldAddLiquidityPathStructure}
              />
              <Route
                exact
                path="/add/:currencyIdA/:currencyIdB"
                component={RedirectDuplicateTokenIds}
              />
              <Route exact path="/create" component={AddLiquidity} />
              <Route
                exact
                path="/create/:currencyIdA"
                component={RedirectOldAddLiquidityPathStructure}
              />
              <Route
                exact
                path="/create/:currencyIdA/:currencyIdB"
                component={RedirectDuplicateTokenIds}
              />
              <Route exact strict path="/remove" component={RemoveLiquidity} />
              <Route exact strict path="/farms" component={Farms} />

              {/* eslint-disable max-len */}
              {/*<Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />*/}
              {/*<Route exact strict path="/uni" component={Earn} />*/}
              {/*<Route exact strict path="/vote" component={Vote} />*/}
              {/*<Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />*/}
              {/* @TODO Redirect from remove for bad token address queries */}
              {/*<Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />*/}
              {/* @TODO Optimize remove liquidity route with token address queries */}
              {/*<Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />*/}
              {/*<Route exact strict path="/migrate/v1" component={MigrateV1} />*/}
              {/*<Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />*/}
              {/*<Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />*/}
              {/*<Route exact strict path="/vote/:id" component={VotePage} />*/}
              {/* eslint-enable max-len */}

              <Route component={RedirectToSwap} />
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}

export default App
