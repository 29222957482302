import { createReducer } from '@reduxjs/toolkit'

import {
  addLiquitityFeeInTokens,
  feeTokenSymbol,
  isFetching,
  removeLiquidityFeeInTokens,
  swapFeeInTokens
} from './actions'

export interface FeeStates {
  readonly isFetching: boolean
  readonly feeTokenSymbol: string | undefined
  readonly addLiquitityFeeInTokens: string | undefined
  readonly removeLiquidityFeeInTokens: string | undefined
  readonly swapFeeInTokens: string | undefined
}

const initState: FeeStates = {
  addLiquitityFeeInTokens: undefined,
  feeTokenSymbol: undefined,
  isFetching: false,
  removeLiquidityFeeInTokens: undefined,
  swapFeeInTokens: undefined
}

export default createReducer(initState, builder =>
  builder
    .addCase(isFetching, (state, { payload: isFetching }) => {
      state.isFetching = isFetching
    })
    .addCase(feeTokenSymbol, (state, { payload: symbol }) => {
      state.feeTokenSymbol = symbol
    })
    .addCase(addLiquitityFeeInTokens, (state, { payload: fee }) => {
      state.addLiquitityFeeInTokens = fee
    })
    .addCase(swapFeeInTokens, (state, { payload: fee }) => {
      state.swapFeeInTokens = fee
    })
    .addCase(removeLiquidityFeeInTokens, (state, { payload: fee }) => {
      state.removeLiquidityFeeInTokens = fee
    })
)
