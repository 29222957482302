import styled from 'styled-components'
import { transparentize } from 'polished'

import { AutoColumn } from '../Column'

export const ToolWrap = styled(AutoColumn)<{
  row?: number
  column?: number
  columnSpan?: number
}>`
  grid-row: ${({ row }) => row && row};
  grid-column: ${({ columnSpan, column }) =>
    column ? (columnSpan ? `${column} / span ${columnSpan}` : column) : 'auto'};
  position: relative;
`

export const ToolLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.00225rem;
  color: ${({ theme }) => theme.farms.textLightest};
  text-transform: uppercase;
  margin-bottom: 10px;
`

export const InputWrap = styled.div`
  height: 51px;
  background: ${({ theme }) => theme.farms.primer};
  border: 1px solid ${({ theme }) => theme.farms.border1};
  border-radius: 10px;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 2px 2px 4px ${({ theme }) => transparentize(0.75, theme.farms.shadow)};
`
