import { Currency, CurrencyAmount, Ether, JSBI, Pair, Rounding } from '@xatadev/sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { darken } from 'polished'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useActiveWeb3React } from '../../hooks'
import useTheme from '../../hooks/useTheme'
import { MaxButton } from '../../pages/Pool/styleds'
// import { Field } from '../../state/burn/actions'
import { useCurrencyBalance, useETHBalances } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
// import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { TxAction } from '../farms'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween } from '../Row'
// import Tooltip from '../Tooltip'
// import QuestionHelper from '../QuestionHelper'

// const InputRow = styled.div<{ selected: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: center;
//   padding: ${({ selected }) =>
//     selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem'};
// `

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg1 : theme.primary1)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 5px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.bg2 : darken(0.05, theme.primary1)};
  }
`

// const LabelRow = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: center;
//   color: ${({ theme }) => theme.text1};
//   font-size: 0.75rem;
//   line-height: 1rem;
//   padding: 0.75rem 1rem 0 1rem;
//   span:hover {
//     cursor: pointer;
//     color: ${({ theme }) => darken(0.2, theme.text2)};
//   }
// `

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
//   margin: 0 0.25rem 0 0.5rem;
//   height: 35%;
//
//   path {
//     stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
//     stroke-width: 1.5px;
//   }
// `

// const InputPanel = styled.div<{ hideInput?: boolean }>`
//   ${({ theme }) => theme.flexColumnNoWrap}
//   position: relative;
//   border-radius: ${({ hideInput }) => (hideInput ? '8px' : '15px')};
//   background-color: ${({ theme }) => theme.bg2};
//   z-index: 1;
// `

// const Container = styled.div<{ hideInput: boolean }>`
//   border-radius: ${({ hideInput }) => (hideInput ? '8px' : '15px')};
//   border: 1px solid ${({ theme }) => theme.bg3};
//   background-color: ${({ theme }) => theme.bg1};
// `

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '20px' : '16px')};

`

const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 0.875rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

const SIXTEEN_PERCENT = new BigNumberJS(16).div(100)
const TWENTY_FIVE_PERCENT = new BigNumberJS(25).div(100)
const FIFTY_PERCENT = new BigNumberJS(50).div(100)
const SEVENTY_FIVE_PERCENT = new BigNumberJS(75).div(100)
const HUNDRED_PERCENT = new BigNumberJS(1)

interface Props {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  // onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  // disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  // hideInput?: boolean
  // otherCurrency?: Currency | null
  // id: string
  // showCommonBases?: boolean
  customBalanceText?: string
  customBalanceAmount?: CurrencyAmount<Currency>
  // gasFee?: JSBigNumber
  inputType: TxAction
}

const StakeInputPanel = ({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  // onCurrencySelect,
  currency,
  // disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  // hideInput = false,
  // otherCurrency,
  // id,
  // showCommonBases,
  customBalanceText,
  customBalanceAmount,
  // gasFee,
  inputType
}: Props) => {
  const { t } = useTranslation()

  // const [modalOpen, setModalOpen] = useState(false)
  const { account, chainId } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const ethBalances = useETHBalances([account ?? undefined])
  const ethBalance =
    account === null || account === undefined
      ? CurrencyAmount.fromRawAmount(Ether.onChain(chainId!), JSBI.BigInt('0'))
      : ethBalances[account]
  const finalBalance = useMemo(() => {
    if (customBalanceAmount !== undefined) {
      return customBalanceAmount
    }

    return currency && currency.symbol === 'ETH' ? ethBalance : selectedCurrencyBalance
  }, [currency, customBalanceAmount, ethBalance, selectedCurrencyBalance])
  const theme = useTheme()

  const decimals = finalBalance?.currency.decimals

  // const handleDismissSearch = useCallback(() => {
  //   setModalOpen(false)
  // }, [setModalOpen])

  const [activePercentButton, setActivePercentButton] = useState<number>()
  const onPercentButtonClick = useCallback(
    (percent: number) => {
      if (!decimals || !finalBalance) return

      setActivePercentButton(percent)

      let percentBN: BigNumberJS
      switch (percent) {
        case 16:
          percentBN = SIXTEEN_PERCENT
          break
        case 25:
          percentBN = TWENTY_FIVE_PERCENT
          break
        case 50:
          percentBN = FIFTY_PERCENT
          break
        case 75:
          percentBN = SEVENTY_FIVE_PERCENT
          break
        default:
          // Max
          percentBN = HUNDRED_PERCENT
          break
      }

      const balance = finalBalance.toExact({
        decimalSeparator: '',
        groupSeparator: ''
      })
      const amount = new BigNumberJS(balance).multipliedBy(percentBN).div(10 ** decimals)

      onUserInput(amount.toFixed(decimals, BigNumberJS.ROUND_DOWN))
    },
    [decimals, finalBalance, onUserInput]
  )

  return (
    <StyledStakeInputPanel>
      <div className="labelRow">
        <RowBetween>
          <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
            {label}
          </TYPE.body>
          {/*{gasFee && (*/}
          {/*  <RowFixed>*/}
          {/*    <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>*/}
          {/*      /!* {`+ ${gasFee.toPrecision(6)}`} *!/*/}
          {/*      {`+ ${gasFee.toString()}`}*/}
          {/*    </TYPE.body>*/}
          {/*    <QuestionHelper text="The maximum possible gas fee to be deducted." />*/}
          {/*  </RowFixed>*/}
          {/*)}*/}
          {account && (
            <TYPE.body
              onClick={onMax}
              color={theme.text2}
              fontWeight={500}
              fontSize={14}
              style={{ display: 'inline', cursor: 'pointer' }}
            >
              {!hideBalance && !!currency && finalBalance
                ? (customBalanceText ?? 'Balance: ') +
                  finalBalance?.toSignificant(6, undefined, Rounding.ROUND_DOWN)
                : ' -'}
            </TYPE.body>
          )}
        </RowBetween>
      </div>

      <div className="inputRow">
        <NumericalInput
          className="token-amount-input"
          value={value}
          onUserInput={val => {
            onUserInput(val)
          }}
        />

        {account && currency && showMaxButton && label !== 'To' && (
          <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
        )}

        <CurrencySelect
          selected={!!currency}
          className="open-currency-select-button"
          onClick={() => {
            // if (!disableCurrencySelect) {
            //   setModalOpen(true)
            // }
          }}
        >
          <Aligner>
            {pair ? (
              <DoubleCurrencyLogo
                currency0={pair.token0}
                currency1={pair.token1}
                size={24}
                margin={true}
              />
            ) : currency ? (
              <CurrencyLogo currency={currency} size={'24px'} />
            ) : null}
            {pair ? (
              <StyledTokenName className="pair-name-container">
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </StyledTokenName>
            ) : (
              <StyledTokenName
                className="token-symbol-container"
                active={Boolean(currency && currency.symbol)}
              >
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? currency.symbol.slice(0, 4) +
                    '...' +
                    currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                  : currency?.symbol) || t('selectToken')}
              </StyledTokenName>
            )}
            {/*{!disableCurrencySelect && <StyledDropDown selected={!!currency} />}*/}
          </Aligner>
        </CurrencySelect>
      </div>

      {inputType === TxAction.UNSTAKE ? (
        <div className="percentRow">
          <MaxButton
            isActive={activePercentButton === 16 || false}
            onClick={() => onPercentButtonClick(16)}
          >
            16%
          </MaxButton>
          <MaxButton
            isActive={activePercentButton === 25 || false}
            onClick={() => onPercentButtonClick(25)}
          >
            25%
          </MaxButton>
          <MaxButton
            isActive={activePercentButton === 50 || false}
            onClick={() => onPercentButtonClick(50)}
          >
            50%
          </MaxButton>
          <MaxButton
            isActive={activePercentButton === 75 || false}
            onClick={() => onPercentButtonClick(75)}
          >
            75%
          </MaxButton>
          <MaxButton
            isActive={activePercentButton === 100 || false}
            onClick={() => onPercentButtonClick(100)}
          >
            MAX
          </MaxButton>
        </div>
      ) : null}
      {/*{!disableCurrencySelect && onCurrencySelect && (*/}
      {/*  <CurrencySearchModal*/}
      {/*    isOpen={modalOpen}*/}
      {/*    onDismiss={handleDismissSearch}*/}
      {/*    onCurrencySelect={onCurrencySelect}*/}
      {/*    selectedCurrency={currency}*/}
      {/*    otherSelectedCurrency={otherCurrency}*/}
      {/*    showCommonBases={showCommonBases}*/}
      {/*  />*/}
      {/*)}*/}
    </StyledStakeInputPanel>
  )
}

const StyledStakeInputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 15px;
  z-index: 1;

  .labelRow {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
    color: ${({ theme }) => theme.text1};
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.75rem 1rem 0 1rem;
  }

  .inputRow {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
    padding: 0.75rem 0.5rem 0.75rem 1rem;
  }

  .percentRow {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
    padding: 0 0.75rem 0.75rem;

    ${MaxButton} {
      padding: 0.5rem 0;
      flex-grow: 1;
    }
  }
`

export default StakeInputPanel
