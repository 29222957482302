import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Trade, CurrencyAmount, Currency, Token, TradeType } from '@xatadev/sdk'
import { useCallback, useMemo } from 'react'
// import { ROUTER_ADDRESS } from '../constants'
import { useTokenAllowance } from '../data/Allowances'
// import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { Field } from '../state/swap/actions'
import { useTransactionAdder, useHasPendingApproval } from '../state/transactions/hooks'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
import { calculateGasMargin } from '../utils'
import { useConveyorV2RouterContract, useTokenContract } from './useContract'
import { useActiveWeb3React } from './index'
// import { Version } from './useToggledVersion'
// import { GTOKEN_MAP } from '../constants/gtokens/gtokens'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const { account } = useActiveWeb3React()
  const token = amountToApprove instanceof CurrencyAmount ? amountToApprove.currency : undefined
  const currentAllowance = useTokenAllowance(token?.wrapped, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.wrapped.address, spender)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])

  const realApprovalState = amountToApprove?.currency.symbol === 'ETH' ? ApprovalState.APPROVED : approvalState

  const tokenContract = useTokenContract(token?.wrapped.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {
    if (realApprovalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    let useExact = false
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true
      // return tokenContract.estimateGas.approve(spender, amountToApprove.raw.toString())
      return tokenContract.estimateGas.approve(spender, amountToApprove.numerator.toString())
    })

    return tokenContract
      .approve(spender, useExact ? amountToApprove.numerator.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas)
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approve ' + amountToApprove.currency.symbol,
          approval: { tokenAddress: (token as Token).address, spender: spender }
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [realApprovalState, token, tokenContract, amountToApprove, spender, addTransaction])

  return [realApprovalState, approve]
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(
  trade?: Trade<Currency, Currency, TradeType>,
  currencies?: { [field in Field]?: Currency },
  allowedSlippage = 0
) {
  const amountToApprove = useMemo(
    () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT] : undefined),
    [trade, allowedSlippage]
  )
  const routerContract = useConveyorV2RouterContract()
  // const tradeIsV1 = getTradeVersion(trade) === Version.v1
  // const v1ExchangeAddress = useV1TradeExchangeAddress(trade)
  const amountToken = amountToApprove
  let realAmountToken: CurrencyAmount<Currency> | undefined
  if (
    currencies !== undefined &&
    amountToken &&
    amountToken.currency
    // && amountToken.currency.symbol?.substring(1) === currencies[Field.INPUT]?.symbol
  ) {
    const token = currencies[Field.INPUT]!
    realAmountToken = CurrencyAmount.fromRawAmount(token, amountToken.numerator)
  } else if (
    currencies !== undefined &&
    amountToken &&
    amountToken.currency
    // && amountToken.currency.symbol?.substring(1) === currencies[Field.OUTPUT]?.symbol
  ) {
    const token = currencies[Field.OUTPUT]!
    realAmountToken = CurrencyAmount.fromRawAmount(token, amountToken.numerator)
  } else {
    realAmountToken = undefined
  }
  return useApproveCallback(
    realAmountToken,
    amountToken && amountToken.currency ? routerContract?.address : undefined
  )
}
