import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'
import { Currency, Ether, Token } from '@xatadev/sdk'
import { selectCurrency, Field } from './actions'
import { useCallback } from 'react'

export function useRemoveLiquidityState(): AppState['removeliquidity'] {
  return useSelector<AppState, AppState['removeliquidity']>(state => state.removeliquidity)
}

export function useRemoveLiquidityActionHandlers(): {
  onRemoveCurrencySelection: (field: Field, currency: Currency | undefined) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onRemoveCurrencySelection = useCallback(
    (field: Field, currency: Currency | undefined) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token ? currency.address : currency === Ether.onChain(currency?.chainId!) ? 'ETH' : ''
        })
      )
    },
    [dispatch]
  )

  return {
    onRemoveCurrencySelection
  }
}
