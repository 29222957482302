import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.bg2};
  border: solid 1px ${({ theme }) => (!theme.isDarkMode ? theme.bg3 : theme.bg1)};
  border-radius: 15px;

  --shadow-color: ${({ theme }) => transparentize(0.25, !theme.isDarkMode ? theme.bg4 : theme.bg2)};
  box-shadow: 4px 4px 20px var(--shadow-color);
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
