import { TransactionResponse } from '@ethersproject/abstract-provider'
import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, Pair } from '@xatadev/sdk'
import { useActiveWeb3React } from 'hooks'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useWindowSize } from 'hooks/useWindowSize'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useStakeTypedValue } from 'state/stake/hooks'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from 'theme'
import uniqid from 'uniqid'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import useDebouncedChangeHandler from 'utils/useDebouncedChangeHandler'

import { ButtonConfirmed, ButtonError, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import {
  DropdownSelect,
  FARM_STATUS_ITEMS_LABELS,
  FarmStatus,
  Search,
  SORT_BY_ITEMS_LABELS,
  SortBy,
  StakeTransactionModal,
  TxAction
} from '../../components/farms'
import Loader from '../../components/Loader'
import { AutoRow, RowBetween } from '../../components/Row'
import StakeInputPanel from '../../components/StakeInputPanel'
import {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../../components/TransactionConfirmationModal'
import {
  useDerivedStakeInfo,
  useFarmsSearchQuery,
  useFarmStatusOption,
  useSortByOption
} from '../../state/farms/hooks'
import { useSimpleTransactionAdder } from '../../state/transactions/hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import RewardPools from './RewardPools'

const Farms = () => {
  // Modal states and actions
  const [txHash, setTxHash] = useState<string>('')
  const [txAction, setTxAction] = useState<TxAction>()
  const [txModalShown, setTxModalShown] = useState<boolean>(false)
  const [attemptingTx, setAttemptingTx] = useState<boolean>(false)
  const [errorTxMessage, setErrorTxMessage] = useState<string>('')
  const [pendingTxMessage, setPendingTxMessage] = useState<string>('')

  const [pair, setPair] = useState<Pair | null>(null)

  const [reward, setReward] = useState<
    { amount: string; symbol: string; decimals: number; logoURI: string }[]
  >()

  const [address, setAddress] = useState<string>()

  const [userStakedAmount, setUserStakedAmount] = useState<CurrencyAmount<Currency>>()

  // Hooks and configs
  const { account } = useActiveWeb3React()

  // const location = useLocation()
  // const history = useHistory()

  const theme = useContext(ThemeContext)

  // const stakingContract = useStakingContract(address)

  const addTransaction = useSimpleTransactionAdder()

  const pairBalance = useCurrencyBalance(account ?? undefined, pair?.liquidityToken ?? undefined)
  const maxAmountToSpend = useMemo(() => {
    if (txAction === TxAction.STAKE) {
      return maxAmountSpend(pairBalance)
    } else if (txAction === TxAction.UNSTAKE) {
      return maxAmountSpend(userStakedAmount)
    }

    return
  }, [txAction, pairBalance, userStakedAmount])

  const [approval, approveCallback] = useApproveCallback(maxAmountToSpend, address)

  const [typedValue, onFieldInput] = useStakeTypedValue()
  const { parsedAmount, error } = useDerivedStakeInfo(
    typedValue,
    pair?.liquidityToken!,
    maxAmountToSpend
  )
  const isValid = !error

  // Filter hooks
  const [searchQuery, onSearchQueryChange] = useFarmsSearchQuery()
  const [selectedFarmStatusOption, onFarmStatusChange] = useFarmStatusOption()
  const [selectedSortByOption, onSortByOptionChange] = useSortByOption()

  const [pool, setPool] = useState<any>()

  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  const [approvalSignRequest, setApprovalSignRequest] = useState<boolean>(false)

  // Event handlers
  const onStake = async () => {
    if (!account) return

    setPendingTxMessage(
      `Attempting to stake ${parsedAmount?.toSignificant(3)} ${pair?.token0.symbol}/${
        pair?.token1.symbol
      }`
    )
    setAttemptingTx(true)

    pool
      ?.stake(
        parseUnits(parsedAmount?.toFixed(parsedAmount?.currency.decimals) as string).toHexString(),
        account
      )
      .then((response: TransactionResponse) => {
        const { hash } = response

        setAttemptingTx(false)
        addTransaction(hash, {
          summary: `Staked ${parsedAmount?.toSignificant(3)} ${pair?.token0.symbol}/${
            pair?.token1.symbol
          }`
        })
        setTxHash(hash)

        setApprovalSubmitted(false)
      })
      .catch((error: any) => {
        setAttemptingTx(false)
        setErrorTxMessage(error?.message)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const onUnstake = () => {
    if (!account) return

    setPendingTxMessage(
      `Attempting to unstake ${parsedAmount?.toSignificant(3)} ${pair?.token0.symbol}/${
        pair?.token1.symbol
      }`
    )
    setAttemptingTx(true)

    pool
      ?.withdraw(
        parseUnits(parsedAmount?.toFixed(parsedAmount?.currency.decimals) as string).toHexString(),
        account
      )
      .then((response: TransactionResponse) => {
        const { hash } = response

        setAttemptingTx(false)
        addTransaction(hash, {
          summary: `Unstaked ${parsedAmount?.toSignificant(3)} ${pair?.token0.symbol}/${
            pair?.token1.symbol
          }`
        })
        setTxHash(hash)
      })
      .catch((error: any) => {
        setAttemptingTx(false)
        setErrorTxMessage(error?.message)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const onHarvest = () => {
    if (!reward || (Array.isArray(reward) && isEmpty(reward))) return

    const rewardsLabel = reward.reduce<string>((label, r, i) => {
      if (i < reward.length - 1 && label.length) {
        label += ', '
      } else if (i === reward.length - 1 && label.length) {
        label += ' and '
      }

      const amount = r.amount.substring(0, r.amount.length - 3) //.div(10 ** r.decimals).toFixed(2, ROUND_DOWN)
      const symbol = r.symbol.toUpperCase()
      label += `${amount} ${symbol}`
      return label
    }, '')

    setPendingTxMessage(`Attempting to harvest ${rewardsLabel}`)
    setAttemptingTx(true)

    pool
      ?.harvestReward({ gasLimit: 350000 })
      .then((response: TransactionResponse) => {
        const { hash } = response

        setAttemptingTx(false)
        addTransaction(hash, {
          summary: `Earned ${rewardsLabel}`
        })
        setTxHash(hash)
      })
      .catch((error: any) => {
        setAttemptingTx(false)
        setErrorTxMessage(error?.message)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const onExit = () => {
    if (!reward || (Array.isArray(reward) && isEmpty(reward))) return

    const rewardsLabel = reward.reduce<string>((label, r, i) => {
      if (i < reward.length - 1 && label.length) {
        label += ', '
      } else if (i === reward.length - 1 && label.length) {
        label += ' and '
      }

      const amount = r.amount.substring(0, r.amount.length - 3) //.div(10 ** r.decimals).toFixed(2, ROUND_DOWN)
      const symbol = r.symbol.toUpperCase()
      label += `${amount} ${symbol}`
      return label
    }, '')

    setPendingTxMessage(`Attempting to harvest ${rewardsLabel}`)
    setAttemptingTx(true)

    pool
      ?.exit({ gasLimit: 350000 })
      .then((response: TransactionResponse) => {
        const { hash } = response

        setAttemptingTx(false)
        addTransaction(hash, {
          summary: `Earned ${rewardsLabel}`
        })
        setTxHash(hash)
      })
      .catch((error: any) => {
        setAttemptingTx(false)
        setErrorTxMessage(error?.message)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const handleActionClick = useCallback(
    (
      actionToCall: TxAction,
      contractAddress: string,
      pairToTrade: Pair | null,
      reward?: {
        amount: string
        symbol: string
        decimals: number
        logoURI: string
      }[],
      stakedAmount?: CurrencyAmount<Currency>,
      pool?: any
    ) => {
      setTxAction(actionToCall)
      setPair(pairToTrade)
      setReward(reward)
      setAddress(contractAddress)
      setUserStakedAmount(stakedAmount)
      setPool(pool)

      setTxModalShown(true)
    },
    []
  )

  const [, setSearchQuery] = useDebouncedChangeHandler<string>(
    searchQuery.toLowerCase(),
    onSearchQueryChange,
    250
  )

  const handleDismissTxModal = useCallback(() => {
    setTxModalShown(false)
    onFieldInput('')
    setTxHash('')
  }, [onFieldInput])

  const handleDismissTxModalWithError = useCallback(() => {
    setErrorTxMessage('')
    handleDismissTxModal()
  }, [handleDismissTxModal])

  const handleStakeAmountInput = (value: string) => {
    onFieldInput(value)
  }

  const handleFarmStatusChange = (status: FarmStatus) => {
    onFarmStatusChange(status)
  }

  const handleSortByChange = (sort: SortBy) => {
    onSortByOptionChange(sort)
  }

  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval])

  const showApproveFlow = useMemo(() => {
    return (
      isValid &&
      (approval === ApprovalState.NOT_APPROVED ||
        approval === ApprovalState.PENDING ||
        (approvalSubmitted && approval === ApprovalState.APPROVED))
    )
  }, [approval, approvalSubmitted, isValid])

  const onApproveButtonClick = async () => {
    setApprovalSignRequest(true)

    try {
      await approveCallback()
    } catch (e) {
      // Handle callback error
    }

    setApprovalSignRequest(false)
  }

  // Views
  const txModalTopContent = (action: TxAction) => {
    if (action === TxAction.STAKE || action === TxAction.UNSTAKE) {
      return (
        <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
          <StakeInputPanel
            value={typedValue}
            onUserInput={handleStakeAmountInput}
            onMax={() => {
              onFieldInput(maxAmountToSpend?.toExact() ?? '')
            }}
            showMaxButton={action === TxAction.STAKE}
            // disableCurrencySelect={true}
            currency={pair?.liquidityToken}
            customBalanceAmount={userStakedAmount}
            customBalanceText={
              txAction === TxAction.STAKE
                ? 'Available for stake: '
                : TxAction.UNSTAKE
                ? 'Available for unstake: '
                : undefined
            }
            pair={pair}
            // id="test"
            inputType={action}
          />
        </AutoColumn>
      )
    } else if (action === TxAction.HARVEST) {
      return (
        <AutoColumn gap={'md'} style={{ marginTop: '10px' }}>
          {reward && (
            <AutoRow
              style={{
                flex: '1 0 auto',
                margin: '0 -24px',
                padding: '0 24px',
                width: 'unset',
                border: `solid 1px ${theme.farms.border1}`,
                borderLeft: 0,
                borderRight: 0
              }}
            >
              <AutoColumn style={{ width: '100%', padding: '11px 0' }}>
                {reward.map(rewardItem => {
                  return (
                    <AutoRow key={`${rewardItem.symbol}_${uniqid}`} style={{ padding: '7px 0' }}>
                      <img
                        src={rewardItem.logoURI}
                        width={22}
                        height={22}
                        alt={`${rewardItem.symbol} logo`}
                        style={{
                          borderRadius: 11,
                          border: `solid 1px ${theme.farms.border2}`,
                          overflow: 'hidden',
                          backgroundColor: `${theme.farms.modalTopContentBackground}`
                        }}
                      />
                      <TYPE.body
                        color={theme.farms.textSub}
                        style={{ marginLeft: '9px', marginRight: 'auto' }}
                      >
                        {rewardItem.symbol}
                      </TYPE.body>
                      <TYPE.body
                        color={theme.farms.textMain}
                        fontWeight={'500'}
                      >{`${rewardItem.amount} ${rewardItem.symbol}`}</TYPE.body>
                    </AutoRow>
                  )
                })}
              </AutoColumn>
            </AutoRow>
          )}
          <TYPE.body color={theme.farms.textSub}>
            You will now be harvesting your rewards from this farm pool! Those will be displayed in
            your wallet in a few moments. Proceed?
          </TYPE.body>
        </AutoColumn>
      )
    } else if (action === TxAction.EXIT) {
      return (
        <AutoColumn gap={'md'} style={{ marginTop: '10px' }}>
          {reward && (
            <AutoRow
              style={{
                flex: '1 0 auto',
                margin: '0 -24px',
                padding: '0 24px',
                width: 'unset',
                border: `solid 1px ${theme.farms.border1}`,
                borderLeft: 0,
                borderRight: 0
              }}
            >
              <AutoColumn style={{ width: '100%', padding: '11px 0' }}>
                {reward.map(rewardItem => {
                  return (
                    <AutoRow key={`${rewardItem.symbol}_${uniqid}`} style={{ padding: '7px 0' }}>
                      <img
                        src={rewardItem.logoURI}
                        width={22}
                        height={22}
                        alt={`${rewardItem.symbol} logo`}
                        style={{
                          borderRadius: 11,
                          border: `solid 1px ${theme.farms.border2}`,
                          overflow: 'hidden',
                          backgroundColor: `${theme.farms.modalTopContentBackground}`
                        }}
                      />
                      <TYPE.body
                        color={theme.farms.textSub}
                        style={{ marginLeft: '9px', marginRight: 'auto' }}
                      >
                        {rewardItem.symbol}
                      </TYPE.body>
                      <TYPE.body
                        color={theme.farms.textMain}
                        fontWeight={'500'}
                      >{`${rewardItem.amount} ${rewardItem.symbol}`}</TYPE.body>
                    </AutoRow>
                  )
                })}
              </AutoColumn>
            </AutoRow>
          )}
          <TYPE.body color={theme.farms.textSub}>
            You will now be harvesting your rewards and also unstaking all of your staked amount
            from this pool! Those will be displayed in your wallet in a few moments. Proceed?
          </TYPE.body>
        </AutoColumn>
      )
    }

    return null
  }

  const txModalBottomContent = (action: TxAction) => {
    if (action === TxAction.STAKE) {
      return (
        <RowBetween
          style={{
            columnGap: '1rem'
          }}
        >
          {showApproveFlow && (
            <ButtonConfirmed
              padding={'8px'}
              onClick={onApproveButtonClick}
              disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
              altDisabledStyle={approval === ApprovalState.PENDING}
              confirmed={approval === ApprovalState.APPROVED}
            >
              {approval === ApprovalState.NOT_APPROVED && approvalSignRequest ? (
                <AutoRow gap="3px" justify="center">
                  <Text fontWeight={400} fontSize={16}>
                    Requesting Sign
                  </Text>
                  <Loader stroke="white" />
                </AutoRow>
              ) : approval === ApprovalState.PENDING ? (
                <AutoRow gap="6px" justify="center">
                  <Text fontWeight={400} fontSize={16}>
                    Approving
                  </Text>
                  <Loader stroke="white" />
                </AutoRow>
              ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                <Text fontWeight={400} fontSize={16}>
                  Approved
                </Text>
              ) : (
                <Text
                  fontWeight={400}
                  fontSize={16}
                >{`Approve ${pair?.token0.symbol}/${pair?.token1.symbol}`}</Text>
              )}
            </ButtonConfirmed>
          )}

          <ButtonError
            padding={'8px'}
            onClick={onStake}
            disabled={!isValid || approval !== ApprovalState.APPROVED}
            error={!isValid && !!parsedAmount}
          >
            <Text fontWeight={400} fontSize={16}>
              {error ?? 'Stake My Tokens'}
            </Text>
          </ButtonError>
        </RowBetween>
      )
    } else if (action === TxAction.UNSTAKE) {
      return (
        <RowBetween
          style={{
            columnGap: '1rem'
          }}
        >
          <ButtonError
            padding={'8px'}
            onClick={onUnstake}
            disabled={!isValid || approval !== ApprovalState.APPROVED}
            error={!isValid && !!parsedAmount}
          >
            <Text fontWeight={400} fontSize={16}>
              {error ?? 'Unstake'}
            </Text>
          </ButtonError>
        </RowBetween>
      )
    } else if (action === TxAction.HARVEST) {
      return (
        <ButtonPrimary padding={'8px'} onClick={onHarvest}>
          <Text fontWeight={400} fontSize={16}>
            Harvest My Rewards
          </Text>
        </ButtonPrimary>
      )
    } else if (action === TxAction.EXIT) {
      return (
        <ButtonPrimary padding={'8px'} onClick={onExit}>
          <Text fontWeight={400} fontSize={16}>
            Unstake All and Harvest My Rewards
          </Text>
        </ButtonPrimary>
      )
    }

    return null
  }

  const { width: windowWidth } = useWindowSize()

  return (
    <StyledFarms>
      <StakeTransactionModal
        isOpen={txModalShown}
        onDismiss={handleDismissTxModal}
        attemptingTxn={attemptingTx}
        hash={txHash}
        pendingText={pendingTxMessage}
        overlayColor={theme.farms.modalBG}
        content={() =>
          errorTxMessage && errorTxMessage.length > 0 ? (
            <TransactionErrorContent
              message={errorTxMessage}
              onDismiss={handleDismissTxModalWithError}
            />
          ) : (
            <ConfirmationModalContent
              title={
                txAction === TxAction.STAKE
                  ? 'Stake your tokens'
                  : txAction === TxAction.UNSTAKE
                  ? 'Unstake your tokens'
                  : 'Harvest your tokens?'
              }
              topContent={() => txAction !== undefined && txModalTopContent(txAction)}
              bottomContent={() => txAction !== undefined && txModalBottomContent(txAction)}
              backgroundColor={theme.farms.modalTopContentBackground}
              onDismiss={handleDismissTxModal}
            />
          )
        }
      />

      <RowBetween className="toolsRow">
        <Search
          row={1}
          column={1}
          columnSpan={windowWidth && windowWidth > 720 ? 3 : 4}
          onQueryChange={setSearchQuery}
        />

        <DropdownSelect<FarmStatus>
          row={windowWidth && windowWidth > 720 ? 1 : 2}
          column={windowWidth && windowWidth > 720 ? 4 : 1}
          columnSpan={windowWidth && windowWidth > 720 ? 1 : 2}
          label="Farm Status"
          items={Object.values(FARM_STATUS_ITEMS_LABELS)}
          activeItem={selectedFarmStatusOption}
          onSelectedItemChange={handleFarmStatusChange}
        />

        <DropdownSelect<SortBy>
          row={windowWidth && windowWidth > 720 ? 1 : 2}
          column={windowWidth && windowWidth > 720 ? 5 : 3}
          columnSpan={windowWidth && windowWidth > 720 ? 1 : 2}
          label="Sort By"
          items={Object.values(SORT_BY_ITEMS_LABELS)}
          activeItem={selectedSortByOption}
          onSelectedItemChange={handleSortByChange}
        />
      </RowBetween>

      <div className="hLine" />

      <RewardPools handleActionClick={handleActionClick} />
    </StyledFarms>
  )
}

const StyledFarms = styled.div`
  width: 100%;
  max-width: 1152px;
  padding: 0 1.5rem;

  ${({ theme }) => theme.mediaWidth.upToLarge``};
  ${({ theme }) => theme.mediaWidth.upToMedium``};
  ${({ theme }) => theme.mediaWidth.upToSmall``};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall``};

  .toolsRow {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      row-gap: 1.5rem;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    `}
  }

  .hLine {
    height: 1px;
    width: 100%;
    margin: 26px 0;
    background-color: ${({ theme }) => theme.farms.border1};
  }
`

export default Farms
